/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { BoardSettingsResDto } from '../models/board-settings-res-dto';
import { IntersectionBoardSettingsReqDtoPartialTypeClass } from '../models/intersection-board-settings-req-dto-partial-type-class';

@Injectable({
  providedIn: 'root',
})
export class BoardsSettingsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation boardSettingsGet
   */
  static readonly BoardSettingsGetPath = '/boards/settings';

  /**
   * get boardsSettings
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `boardSettingsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  boardSettingsGet$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    object: 'spaces' | 'projects';
    objectId: string;
  }): Observable<StrictHttpResponse<BoardSettingsResDto>> {
    const rb = new RequestBuilder(this.rootUrl, BoardsSettingsService.BoardSettingsGetPath, 'get');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.query('object', params.object, {});
      rb.query('objectId', params.objectId, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<BoardSettingsResDto>;
        }),
      );
  }

  /**
   * get boardsSettings
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `boardSettingsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  boardSettingsGet(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    object: 'spaces' | 'projects';
    objectId: string;
  }): Observable<BoardSettingsResDto> {
    return this.boardSettingsGet$Response(params).pipe(
      map((r: StrictHttpResponse<BoardSettingsResDto>) => r.body as BoardSettingsResDto),
    );
  }

  /**
   * Path part for operation boardSettingsSet
   */
  static readonly BoardSettingsSetPath = '/boards/settings';

  /**
   * set boardsSettings
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `boardSettingsSet()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  boardSettingsSet$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    body: IntersectionBoardSettingsReqDtoPartialTypeClass;
  }): Observable<StrictHttpResponse<BoardSettingsResDto>> {
    const rb = new RequestBuilder(this.rootUrl, BoardsSettingsService.BoardSettingsSetPath, 'post');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<BoardSettingsResDto>;
        }),
      );
  }

  /**
   * set boardsSettings
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `boardSettingsSet$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  boardSettingsSet(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    body: IntersectionBoardSettingsReqDtoPartialTypeClass;
  }): Observable<BoardSettingsResDto> {
    return this.boardSettingsSet$Response(params).pipe(
      map((r: StrictHttpResponse<BoardSettingsResDto>) => r.body as BoardSettingsResDto),
    );
  }

  /**
   * Path part for operation boardSettingsDelete
   */
  static readonly BoardSettingsDeletePath = '/boards/settings';

  /**
   * delete boardsSettings
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `boardSettingsDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  boardSettingsDelete$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    object: 'spaces' | 'projects';
    objectId: string;
  }): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, BoardsSettingsService.BoardSettingsDeletePath, 'delete');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.query('object', params.object, {});
      rb.query('objectId', params.objectId, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: '*/*',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
        }),
      );
  }

  /**
   * delete boardsSettings
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `boardSettingsDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  boardSettingsDelete(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    object: 'spaces' | 'projects';
    objectId: string;
  }): Observable<void> {
    return this.boardSettingsDelete$Response(params).pipe(map((r: StrictHttpResponse<void>) => r.body as void));
  }

  /**
   * Path part for operation boardApplyTicketSettings
   */
  static readonly BoardApplyTicketSettingsPath = '/boards/settings/{id}';

  /**
   * apply ticketSettings
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `boardApplyTicketSettings()` instead.
   *
   * This method doesn't expect any request body.
   */
  boardApplyTicketSettings$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;

    /**
     * ticketId
     */
    id: string;
  }): Observable<StrictHttpResponse<BoardSettingsResDto>> {
    const rb = new RequestBuilder(this.rootUrl, BoardsSettingsService.BoardApplyTicketSettingsPath, 'post');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.path('id', params.id, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<BoardSettingsResDto>;
        }),
      );
  }

  /**
   * apply ticketSettings
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `boardApplyTicketSettings$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  boardApplyTicketSettings(params: {
    /**
     * Localization
     */
    'x-lang'?: string;

    /**
     * ticketId
     */
    id: string;
  }): Observable<BoardSettingsResDto> {
    return this.boardApplyTicketSettings$Response(params).pipe(
      map((r: StrictHttpResponse<BoardSettingsResDto>) => r.body as BoardSettingsResDto),
    );
  }
}
