<div class="d-flex align-items-center" [class.avatar-record]="isRecording">
  <div class="position-relative" t-id="avatar-member-list">
    <img
      *ngIf="avatarUrl"
      t-id="avatar-of-members"
      class="position-relative avatar"
      src="{{ avatarUrl ? avatarUrl : defaultAvatar }}"
      height="{{ height }}"
      width="{{ width }}"
      [class.selected-user-border]="border"
      [class.board-action-bar-border]="hasBorder"
      (click)="openUserProfile()"
    />

    <div
      *ngIf="!avatarUrl"
      class="default-avatar"
      [class.selected-user-border]="border"
      [class.board-action-bar-border]="hasBorder"
      [class.isAssistant-avatar]="isAssistant"
      [style.width.px]="width"
      [style.height.px]="height"
      (click)="openUserProfile()"
    >
      {{ prefix }}
    </div>

    <div
      *ngIf="showStatus && status"
      class="status-badge users-status-badge"
      [ngStyle]="{ background: status }"
      [style.width.px]="statusSize"
      [style.height.px]="statusSize"
    ></div>
    <div
      *ngIf="showStatus && !status"
      class="status-badge external-users-badge"
      [style.width.px]="statusSize * 1 + 3"
      [style.height.px]="statusSize * 1 + 3"
    >
      <app-svg name="link" [height]="statusSize" [width]="statusSize"></app-svg>
    </div>
  </div>

  <div *ngIf="showUserName && userName && !isDropdownItem" class="ml-1">
    {{ userName }}
  </div>
</div>

<div *ngIf="showUserName && userName && isDropdownItem">{{ userName }}</div>
