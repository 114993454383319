<div class="payment">
  <div class="payment__header">
    <svg-icon [src]="closeSvg" (click)="handleCloseModal()"></svg-icon>
  </div>
  <div class="payment__body" [class.isFrame]="isFrameStep">
    <div class="payment__stepper">
      <!--CHOICE STEP-->
      <div *ngIf="isChoiceStep" class="payment__choice">
        <app-payment-choice-step />
      </div>

      <!--ACCEPT STEP-->
      <div *ngIf="isAcceptStep" class="payment__accept">
        <app-payment-accept-step />
      </div>

      <!--DETAIL STEP-->
      <div *ngIf="isDetailStep" class="payment__detail">
        <app-payment-detail-step />
      </div>

      <!--FRAME STEP-->
      <div *ngIf="isFrameStep" class="payment__frame">
        <app-payment-frame />
      </div>
    </div>
  </div>
</div>
