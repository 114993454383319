/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AuthLoginResDto } from '../models/auth-login-res-dto';
import { AuthPasswordReqDto } from '../models/auth-password-req-dto';
import { CommonConfirmInviteReqDto } from '../models/common-confirm-invite-req-dto';
import { CommonInviteResDto } from '../models/common-invite-res-dto';
import { CommonSuccessResDto } from '../models/common-success-res-dto';
import { ProjectInviteBodyReqDto } from '../models/project-invite-body-req-dto';
import { ProjectsCreateReqDto } from '../models/projects-create-req-dto';
import { ProjectsDbDto } from '../models/projects-db-dto';
import { ProjectsGetListByUserIdDbDtoType } from '../models/projects-get-list-by-user-id-db-dto-type';
import { ProjectsListResDto } from '../models/projects-list-res-dto';
import { ProjectsMembersUpdateOrderDto } from '../models/projects-members-update-order-dto';
import { ProjectsOrderUpdateReqDto } from '../models/projects-order-update-req-dto';
import { ProjectsUpdateReqDto } from '../models/projects-update-req-dto';
import { ProjectsValidateTokenResDto } from '../models/projects-validate-token-res-dto';
import { UsersDbDto } from '../models/users-db-dto';

@Injectable({
  providedIn: 'root',
})
export class ProjectsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation projectGetList
   */
  static readonly ProjectGetListPath = '/projects';

  /**
   * returns all projects
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `projectGetList()` instead.
   *
   * This method doesn't expect any request body.
   */
  projectGetList$Response(params?: {
    /**
     * Localization
     */
    'x-lang'?: string;

    /**
     * Search string
     */
    search?: string;

    /**
     * Search key | keys
     */
    searchBy?: string;

    /**
     * Sorting direction
     */
    sort?: string;

    /**
     * Sorting by field
     */
    sortBy?: string;

    /**
     * Message id
     */
    messageId?: string;

    /**
     * Limit resulting elements(default *10*).
     */
    perPage?: number;

    /**
     * Number of current page.(default *1*).
     */
    page?: number;
  }): Observable<StrictHttpResponse<ProjectsListResDto>> {
    const rb = new RequestBuilder(this.rootUrl, ProjectsService.ProjectGetListPath, 'get');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.query('search', params.search, {});
      rb.query('searchBy', params.searchBy, {});
      rb.query('sort', params.sort, {});
      rb.query('sortBy', params.sortBy, {});
      rb.query('messageId', params.messageId, {});
      rb.query('perPage', params.perPage, {});
      rb.query('page', params.page, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<ProjectsListResDto>;
        }),
      );
  }

  /**
   * returns all projects
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `projectGetList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  projectGetList(params?: {
    /**
     * Localization
     */
    'x-lang'?: string;

    /**
     * Search string
     */
    search?: string;

    /**
     * Search key | keys
     */
    searchBy?: string;

    /**
     * Sorting direction
     */
    sort?: string;

    /**
     * Sorting by field
     */
    sortBy?: string;

    /**
     * Message id
     */
    messageId?: string;

    /**
     * Limit resulting elements(default *10*).
     */
    perPage?: number;

    /**
     * Number of current page.(default *1*).
     */
    page?: number;
  }): Observable<ProjectsListResDto> {
    return this.projectGetList$Response(params).pipe(
      map((r: StrictHttpResponse<ProjectsListResDto>) => r.body as ProjectsListResDto),
    );
  }

  /**
   * Path part for operation projectCreate
   */
  static readonly ProjectCreatePath = '/projects';

  /**
   * create a projects
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `projectCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  projectCreate$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    body: ProjectsCreateReqDto;
  }): Observable<StrictHttpResponse<ProjectsDbDto>> {
    const rb = new RequestBuilder(this.rootUrl, ProjectsService.ProjectCreatePath, 'post');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<ProjectsDbDto>;
        }),
      );
  }

  /**
   * create a projects
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `projectCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  projectCreate(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    body: ProjectsCreateReqDto;
  }): Observable<ProjectsDbDto> {
    return this.projectCreate$Response(params).pipe(
      map((r: StrictHttpResponse<ProjectsDbDto>) => r.body as ProjectsDbDto),
    );
  }

  /**
   * Path part for operation projectMembersUpdateOrder
   */
  static readonly ProjectMembersUpdateOrderPath = '/projects/update-order/{id}';

  /**
   * projects members update order
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `projectMembersUpdateOrder()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  projectMembersUpdateOrder$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    id: string;
    body: ProjectsMembersUpdateOrderDto;
  }): Observable<StrictHttpResponse<ProjectsDbDto>> {
    const rb = new RequestBuilder(this.rootUrl, ProjectsService.ProjectMembersUpdateOrderPath, 'patch');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<ProjectsDbDto>;
        }),
      );
  }

  /**
   * projects members update order
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `projectMembersUpdateOrder$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  projectMembersUpdateOrder(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    id: string;
    body: ProjectsMembersUpdateOrderDto;
  }): Observable<ProjectsDbDto> {
    return this.projectMembersUpdateOrder$Response(params).pipe(
      map((r: StrictHttpResponse<ProjectsDbDto>) => r.body as ProjectsDbDto),
    );
  }

  /**
   * Path part for operation projectsOrderUpdate
   */
  static readonly ProjectsOrderUpdatePath = '/projects/order';

  /**
   * update projects order
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `projectsOrderUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  projectsOrderUpdate$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    body: ProjectsOrderUpdateReqDto;
  }): Observable<StrictHttpResponse<CommonSuccessResDto>> {
    const rb = new RequestBuilder(this.rootUrl, ProjectsService.ProjectsOrderUpdatePath, 'patch');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<CommonSuccessResDto>;
        }),
      );
  }

  /**
   * update projects order
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `projectsOrderUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  projectsOrderUpdate(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    body: ProjectsOrderUpdateReqDto;
  }): Observable<CommonSuccessResDto> {
    return this.projectsOrderUpdate$Response(params).pipe(
      map((r: StrictHttpResponse<CommonSuccessResDto>) => r.body as CommonSuccessResDto),
    );
  }

  /**
   * Path part for operation projectDelete
   */
  static readonly ProjectDeletePath = '/projects/{id}';

  /**
   * delete the projects
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `projectDelete()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  projectDelete$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    id: string;
    body: AuthPasswordReqDto;
  }): Observable<StrictHttpResponse<CommonSuccessResDto>> {
    const rb = new RequestBuilder(this.rootUrl, ProjectsService.ProjectDeletePath, 'delete');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<CommonSuccessResDto>;
        }),
      );
  }

  /**
   * delete the projects
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `projectDelete$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  projectDelete(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    id: string;
    body: AuthPasswordReqDto;
  }): Observable<CommonSuccessResDto> {
    return this.projectDelete$Response(params).pipe(
      map((r: StrictHttpResponse<CommonSuccessResDto>) => r.body as CommonSuccessResDto),
    );
  }

  /**
   * Path part for operation projectUpdate
   */
  static readonly ProjectUpdatePath = '/projects/{id}';

  /**
   * updates the projects
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `projectUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  projectUpdate$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    id: string;

    /**
     * projects data
     */
    body: ProjectsUpdateReqDto;
  }): Observable<StrictHttpResponse<CommonSuccessResDto>> {
    const rb = new RequestBuilder(this.rootUrl, ProjectsService.ProjectUpdatePath, 'patch');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<CommonSuccessResDto>;
        }),
      );
  }

  /**
   * updates the projects
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `projectUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  projectUpdate(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    id: string;

    /**
     * projects data
     */
    body: ProjectsUpdateReqDto;
  }): Observable<CommonSuccessResDto> {
    return this.projectUpdate$Response(params).pipe(
      map((r: StrictHttpResponse<CommonSuccessResDto>) => r.body as CommonSuccessResDto),
    );
  }

  /**
   * Path part for operation projectInvite
   */
  static readonly ProjectInvitePath = '/projects/project-invite';

  /**
   * invite user to a projects
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `projectInvite()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  projectInvite$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    body: ProjectInviteBodyReqDto;
  }): Observable<StrictHttpResponse<CommonInviteResDto>> {
    const rb = new RequestBuilder(this.rootUrl, ProjectsService.ProjectInvitePath, 'post');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<CommonInviteResDto>;
        }),
      );
  }

  /**
   * invite user to a projects
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `projectInvite$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  projectInvite(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    body: ProjectInviteBodyReqDto;
  }): Observable<CommonInviteResDto> {
    return this.projectInvite$Response(params).pipe(
      map((r: StrictHttpResponse<CommonInviteResDto>) => r.body as CommonInviteResDto),
    );
  }

  /**
   * Path part for operation projectConfirmNew
   */
  static readonly ProjectConfirmNewPath = '/projects/project-confirm-new';

  /**
   * confirm new user to project
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `projectConfirmNew()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  projectConfirmNew$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    body: CommonConfirmInviteReqDto;
  }): Observable<StrictHttpResponse<AuthLoginResDto>> {
    const rb = new RequestBuilder(this.rootUrl, ProjectsService.ProjectConfirmNewPath, 'post');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<AuthLoginResDto>;
        }),
      );
  }

  /**
   * confirm new user to project
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `projectConfirmNew$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  projectConfirmNew(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    body: CommonConfirmInviteReqDto;
  }): Observable<AuthLoginResDto> {
    return this.projectConfirmNew$Response(params).pipe(
      map((r: StrictHttpResponse<AuthLoginResDto>) => r.body as AuthLoginResDto),
    );
  }

  /**
   * Path part for operation projectInviteTokenCheck
   */
  static readonly ProjectInviteTokenCheckPath = '/projects/project-invite-token-check/{token}';

  /**
   * validates new user's token
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `projectInviteTokenCheck()` instead.
   *
   * This method doesn't expect any request body.
   */
  projectInviteTokenCheck$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    token: string;
  }): Observable<StrictHttpResponse<ProjectsValidateTokenResDto>> {
    const rb = new RequestBuilder(this.rootUrl, ProjectsService.ProjectInviteTokenCheckPath, 'get');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.path('token', params.token, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<ProjectsValidateTokenResDto>;
        }),
      );
  }

  /**
   * validates new user's token
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `projectInviteTokenCheck$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  projectInviteTokenCheck(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    token: string;
  }): Observable<ProjectsValidateTokenResDto> {
    return this.projectInviteTokenCheck$Response(params).pipe(
      map((r: StrictHttpResponse<ProjectsValidateTokenResDto>) => r.body as ProjectsValidateTokenResDto),
    );
  }

  /**
   * Path part for operation projectConfirmExist
   */
  static readonly ProjectConfirmExistPath = '/projects/project-confirm-exist/{token}';

  /**
   * confirm existed user to project
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `projectConfirmExist()` instead.
   *
   * This method doesn't expect any request body.
   */
  projectConfirmExist$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    token: string;
  }): Observable<StrictHttpResponse<AuthLoginResDto>> {
    const rb = new RequestBuilder(this.rootUrl, ProjectsService.ProjectConfirmExistPath, 'get');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.path('token', params.token, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<AuthLoginResDto>;
        }),
      );
  }

  /**
   * confirm existed user to project
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `projectConfirmExist$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  projectConfirmExist(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    token: string;
  }): Observable<AuthLoginResDto> {
    return this.projectConfirmExist$Response(params).pipe(
      map((r: StrictHttpResponse<AuthLoginResDto>) => r.body as AuthLoginResDto),
    );
  }

  /**
   * Path part for operation projectUnassign
   */
  static readonly ProjectUnassignPath = '/projects/project-unassign/user';

  /**
   * unassign the user to a projects
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `projectUnassign()` instead.
   *
   * This method doesn't expect any request body.
   */
  projectUnassign$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;

    /**
     * userId
     */
    userId: string;

    /**
     * projectId
     */
    projectId: string;
    query: any;
  }): Observable<StrictHttpResponse<CommonSuccessResDto>> {
    const rb = new RequestBuilder(this.rootUrl, ProjectsService.ProjectUnassignPath, 'delete');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.query('userId', params.userId, {});
      rb.query('projectId', params.projectId, {});
      rb.query('query', params.query, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<CommonSuccessResDto>;
        }),
      );
  }

  /**
   * unassign the user to a projects
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `projectUnassign$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  projectUnassign(params: {
    /**
     * Localization
     */
    'x-lang'?: string;

    /**
     * userId
     */
    userId: string;

    /**
     * projectId
     */
    projectId: string;
    query: any;
  }): Observable<CommonSuccessResDto> {
    return this.projectUnassign$Response(params).pipe(
      map((r: StrictHttpResponse<CommonSuccessResDto>) => r.body as CommonSuccessResDto),
    );
  }

  /**
   * Path part for operation projectLeave
   */
  static readonly ProjectLeavePath = '/projects/project-leave/{id}';

  /**
   * Leave project
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `projectLeave()` instead.
   *
   * This method doesn't expect any request body.
   */
  projectLeave$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    id: string;
  }): Observable<StrictHttpResponse<CommonSuccessResDto>> {
    const rb = new RequestBuilder(this.rootUrl, ProjectsService.ProjectLeavePath, 'delete');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.path('id', params.id, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<CommonSuccessResDto>;
        }),
      );
  }

  /**
   * Leave project
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `projectLeave$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  projectLeave(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    id: string;
  }): Observable<CommonSuccessResDto> {
    return this.projectLeave$Response(params).pipe(
      map((r: StrictHttpResponse<CommonSuccessResDto>) => r.body as CommonSuccessResDto),
    );
  }

  /**
   * Path part for operation projectRevokeUserInvite
   */
  static readonly ProjectRevokeUserInvitePath = '/projects/revoke-invite/user';

  /**
   * revoke user invite from the projects
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `projectRevokeUserInvite()` instead.
   *
   * This method doesn't expect any request body.
   */
  projectRevokeUserInvite$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;

    /**
     * Email
     */
    email?: string;

    /**
     * userId
     */
    userId?: string;

    /**
     * projectId
     */
    projectId: string;
    query: any;
  }): Observable<StrictHttpResponse<CommonSuccessResDto>> {
    const rb = new RequestBuilder(this.rootUrl, ProjectsService.ProjectRevokeUserInvitePath, 'delete');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.query('email', params.email, {});
      rb.query('userId', params.userId, {});
      rb.query('projectId', params.projectId, {});
      rb.query('query', params.query, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<CommonSuccessResDto>;
        }),
      );
  }

  /**
   * revoke user invite from the projects
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `projectRevokeUserInvite$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  projectRevokeUserInvite(params: {
    /**
     * Localization
     */
    'x-lang'?: string;

    /**
     * Email
     */
    email?: string;

    /**
     * userId
     */
    userId?: string;

    /**
     * projectId
     */
    projectId: string;
    query: any;
  }): Observable<CommonSuccessResDto> {
    return this.projectRevokeUserInvite$Response(params).pipe(
      map((r: StrictHttpResponse<CommonSuccessResDto>) => r.body as CommonSuccessResDto),
    );
  }

  /**
   * Path part for operation projectGetUserListByProjectId
   */
  static readonly ProjectGetUserListByProjectIdPath = '/projects/get-users-list/{id}';

  /**
   * get users list by project id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `projectGetUserListByProjectId()` instead.
   *
   * This method doesn't expect any request body.
   */
  projectGetUserListByProjectId$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    id: string;

    /**
     * exists
     */
    exists: boolean;
  }): Observable<StrictHttpResponse<Array<UsersDbDto>>> {
    const rb = new RequestBuilder(this.rootUrl, ProjectsService.ProjectGetUserListByProjectIdPath, 'get');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.path('id', params.id, {});
      rb.query('exists', params.exists, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<UsersDbDto>>;
        }),
      );
  }

  /**
   * get users list by project id
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `projectGetUserListByProjectId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  projectGetUserListByProjectId(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    id: string;

    /**
     * exists
     */
    exists: boolean;
  }): Observable<Array<UsersDbDto>> {
    return this.projectGetUserListByProjectId$Response(params).pipe(
      map((r: StrictHttpResponse<Array<UsersDbDto>>) => r.body as Array<UsersDbDto>),
    );
  }

  /**
   * Path part for operation projectGetProjectsListByUserId
   */
  static readonly ProjectGetProjectsListByUserIdPath = '/projects/{userId}';

  /**
   * get projects list by user id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `projectGetProjectsListByUserId()` instead.
   *
   * This method doesn't expect any request body.
   */
  projectGetProjectsListByUserId$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;

    /**
     * userId
     */
    userId: string;

    /**
     * Search string
     */
    search?: string;

    /**
     * Search key | keys
     */
    searchBy?: string;

    /**
     * Sorting direction
     */
    sort?: string;

    /**
     * Sorting by field
     */
    sortBy?: string;

    /**
     * Message id
     */
    messageId?: string;

    /**
     * Limit resulting elements(default *10*).
     */
    perPage?: number;

    /**
     * Number of current page.(default *1*).
     */
    page?: number;
  }): Observable<StrictHttpResponse<ProjectsGetListByUserIdDbDtoType>> {
    const rb = new RequestBuilder(this.rootUrl, ProjectsService.ProjectGetProjectsListByUserIdPath, 'get');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.path('userId', params.userId, {});
      rb.query('search', params.search, {});
      rb.query('searchBy', params.searchBy, {});
      rb.query('sort', params.sort, {});
      rb.query('sortBy', params.sortBy, {});
      rb.query('messageId', params.messageId, {});
      rb.query('perPage', params.perPage, {});
      rb.query('page', params.page, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<ProjectsGetListByUserIdDbDtoType>;
        }),
      );
  }

  /**
   * get projects list by user id
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `projectGetProjectsListByUserId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  projectGetProjectsListByUserId(params: {
    /**
     * Localization
     */
    'x-lang'?: string;

    /**
     * userId
     */
    userId: string;

    /**
     * Search string
     */
    search?: string;

    /**
     * Search key | keys
     */
    searchBy?: string;

    /**
     * Sorting direction
     */
    sort?: string;

    /**
     * Sorting by field
     */
    sortBy?: string;

    /**
     * Message id
     */
    messageId?: string;

    /**
     * Limit resulting elements(default *10*).
     */
    perPage?: number;

    /**
     * Number of current page.(default *1*).
     */
    page?: number;
  }): Observable<ProjectsGetListByUserIdDbDtoType> {
    return this.projectGetProjectsListByUserId$Response(params).pipe(
      map((r: StrictHttpResponse<ProjectsGetListByUserIdDbDtoType>) => r.body as ProjectsGetListByUserIdDbDtoType),
    );
  }

  /**
   * Path part for operation projectToggleArchiveStatus
   */
  static readonly ProjectToggleArchiveStatusPath = '/projects/{id}/toggleArchiveStatus';

  /**
   * move the projects to archive and back
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `projectToggleArchiveStatus()` instead.
   *
   * This method doesn't expect any request body.
   */
  projectToggleArchiveStatus$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    id: string;
  }): Observable<StrictHttpResponse<CommonSuccessResDto>> {
    const rb = new RequestBuilder(this.rootUrl, ProjectsService.ProjectToggleArchiveStatusPath, 'patch');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.path('id', params.id, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<CommonSuccessResDto>;
        }),
      );
  }

  /**
   * move the projects to archive and back
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `projectToggleArchiveStatus$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  projectToggleArchiveStatus(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    id: string;
  }): Observable<CommonSuccessResDto> {
    return this.projectToggleArchiveStatus$Response(params).pipe(
      map((r: StrictHttpResponse<CommonSuccessResDto>) => r.body as CommonSuccessResDto),
    );
  }

  /**
   * Path part for operation projectRestore
   */
  static readonly ProjectRestorePath = '/projects/{id}/restore';

  /**
   * restore the projects
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `projectRestore()` instead.
   *
   * This method doesn't expect any request body.
   */
  projectRestore$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    id: string;
  }): Observable<StrictHttpResponse<ProjectsDbDto>> {
    const rb = new RequestBuilder(this.rootUrl, ProjectsService.ProjectRestorePath, 'patch');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.path('id', params.id, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<ProjectsDbDto>;
        }),
      );
  }

  /**
   * restore the projects
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `projectRestore$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  projectRestore(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    id: string;
  }): Observable<ProjectsDbDto> {
    return this.projectRestore$Response(params).pipe(
      map((r: StrictHttpResponse<ProjectsDbDto>) => r.body as ProjectsDbDto),
    );
  }
}
