import { Injectable } from '@angular/core';
import { IPlan } from './payment.const';
import { PaymentModalComponent } from './payment-modal.component';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ContactUsComponent } from '../contact-us/contact-us.component';
import { BillingEditComponent } from '../billing-edit/billing-edit.component';
import { TDate } from './components/payment-choice-step/payment-choice-step.component';

export enum StepEnum {
  Choice = 'Choice',
  Accept = 'Accept',
  DetailForm = 'Detail Form',
  Frame = 'Frame',
}

@Injectable({ providedIn: 'root' })
export class PaymentService {
  public activeStep: StepEnum = StepEnum.Choice;
  public paymentModal: NgbModalRef;
  public typeOfPayment: TDate = 'monthly';
  public selectedPlan: IPlan = null;
  public previousPlan: IPlan = null;

  constructor(private modalService: NgbModal) {}

  public handleSelectPlan({ step, plan }: { step: StepEnum; plan: IPlan }): void {
    this.activeStep = step;
    this.selectedPlan = plan;
  }

  public setPreviousPlan(plan: IPlan): void {
    this.previousPlan = plan;
  }

  public typePayment(type): void {
    this.typeOfPayment = type;
  }

  public openPaymentModal(): void {
    this.paymentModal = this.modalService.open(PaymentModalComponent, {
      centered: true,
      size: 'xl',
      windowClass: 'payment-modal',
    });
  }

  public openEditModal(billingDetail): void {
    const modal = this.modalService.open(BillingEditComponent, {
      centered: true,
      size: 'lg',
      windowClass: 'billing-edit',
    });

    modal.componentInstance.billingDetail = billingDetail;
  }

  public contactUs(): void {
    this.modalService.open(ContactUsComponent, {
      size: 'md',
      centered: true,
    });
  }

  public handleClose(): void {
    this.paymentModal.close();
  }
}
