/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CommonDeleteResDto } from '../models/common-delete-res-dto';
import { CommonNameDto } from '../models/common-name-dto';
import { RolesCreateReqDto } from '../models/roles-create-req-dto';
import { RolesCreateResDto } from '../models/roles-create-res-dto';
import { RolesDbDto } from '../models/roles-db-dto';
import { RolesGetListResDto } from '../models/roles-get-list-res-dto';
import { RolesMemberCreateReqDto } from '../models/roles-member-create-req-dto';
import { RolesMemberUpdateReqDto } from '../models/roles-member-update-req-dto';
import { RolesMembersDbDto } from '../models/roles-members-db-dto';

@Injectable({
  providedIn: 'root',
})
export class RolesService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation roleGetList
   */
  static readonly RoleGetListPath = '/roles';

  /**
   * get roles list
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `roleGetList()` instead.
   *
   * This method doesn't expect any request body.
   */
  roleGetList$Response(params?: {
    /**
     * Localization
     */
    'x-lang'?: string;
  }): Observable<StrictHttpResponse<Array<RolesGetListResDto>>> {
    const rb = new RequestBuilder(this.rootUrl, RolesService.RoleGetListPath, 'get');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<RolesGetListResDto>>;
        }),
      );
  }

  /**
   * get roles list
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `roleGetList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  roleGetList(params?: {
    /**
     * Localization
     */
    'x-lang'?: string;
  }): Observable<Array<RolesGetListResDto>> {
    return this.roleGetList$Response(params).pipe(
      map((r: StrictHttpResponse<Array<RolesGetListResDto>>) => r.body as Array<RolesGetListResDto>),
    );
  }

  /**
   * Path part for operation roleCreate
   */
  static readonly RoleCreatePath = '/roles';

  /**
   * create role
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `roleCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  roleCreate$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    body: RolesCreateReqDto;
  }): Observable<StrictHttpResponse<RolesCreateResDto>> {
    const rb = new RequestBuilder(this.rootUrl, RolesService.RoleCreatePath, 'post');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<RolesCreateResDto>;
        }),
      );
  }

  /**
   * create role
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `roleCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  roleCreate(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    body: RolesCreateReqDto;
  }): Observable<RolesCreateResDto> {
    return this.roleCreate$Response(params).pipe(
      map((r: StrictHttpResponse<RolesCreateResDto>) => r.body as RolesCreateResDto),
    );
  }

  /**
   * Path part for operation roleDelete
   */
  static readonly RoleDeletePath = '/roles/{name}';

  /**
   * delete roles
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `roleDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  roleDelete$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    name: string;
  }): Observable<StrictHttpResponse<CommonDeleteResDto>> {
    const rb = new RequestBuilder(this.rootUrl, RolesService.RoleDeletePath, 'delete');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.path('name', params.name, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<CommonDeleteResDto>;
        }),
      );
  }

  /**
   * delete roles
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `roleDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  roleDelete(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    name: string;
  }): Observable<CommonDeleteResDto> {
    return this.roleDelete$Response(params).pipe(
      map((r: StrictHttpResponse<CommonDeleteResDto>) => r.body as CommonDeleteResDto),
    );
  }

  /**
   * Path part for operation roleUpdate
   */
  static readonly RoleUpdatePath = '/roles/{name}';

  /**
   * update roles
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `roleUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  roleUpdate$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    name: string;
    body: CommonNameDto;
  }): Observable<StrictHttpResponse<RolesDbDto>> {
    const rb = new RequestBuilder(this.rootUrl, RolesService.RoleUpdatePath, 'patch');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.path('name', params.name, {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<RolesDbDto>;
        }),
      );
  }

  /**
   * update roles
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `roleUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  roleUpdate(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    name: string;
    body: CommonNameDto;
  }): Observable<RolesDbDto> {
    return this.roleUpdate$Response(params).pipe(map((r: StrictHttpResponse<RolesDbDto>) => r.body as RolesDbDto));
  }

  /**
   * Path part for operation roleMemberGetList
   */
  static readonly RoleMemberGetListPath = '/roles/role-members';

  /**
   * get role-members list
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `roleMemberGetList()` instead.
   *
   * This method doesn't expect any request body.
   */
  roleMemberGetList$Response(params?: {
    /**
     * Localization
     */
    'x-lang'?: string;

    /**
     * tenantName
     */
    tenantName?: string;
  }): Observable<StrictHttpResponse<Array<RolesMembersDbDto>>> {
    const rb = new RequestBuilder(this.rootUrl, RolesService.RoleMemberGetListPath, 'get');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.query('tenantName', params.tenantName, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<RolesMembersDbDto>>;
        }),
      );
  }

  /**
   * get role-members list
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `roleMemberGetList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  roleMemberGetList(params?: {
    /**
     * Localization
     */
    'x-lang'?: string;

    /**
     * tenantName
     */
    tenantName?: string;
  }): Observable<Array<RolesMembersDbDto>> {
    return this.roleMemberGetList$Response(params).pipe(
      map((r: StrictHttpResponse<Array<RolesMembersDbDto>>) => r.body as Array<RolesMembersDbDto>),
    );
  }

  /**
   * Path part for operation roleMemberCreate
   */
  static readonly RoleMemberCreatePath = '/roles/role-members';

  /**
   * create role-member
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `roleMemberCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  roleMemberCreate$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    body: RolesMemberCreateReqDto;
  }): Observable<StrictHttpResponse<RolesMembersDbDto>> {
    const rb = new RequestBuilder(this.rootUrl, RolesService.RoleMemberCreatePath, 'post');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<RolesMembersDbDto>;
        }),
      );
  }

  /**
   * create role-member
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `roleMemberCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  roleMemberCreate(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    body: RolesMemberCreateReqDto;
  }): Observable<RolesMembersDbDto> {
    return this.roleMemberCreate$Response(params).pipe(
      map((r: StrictHttpResponse<RolesMembersDbDto>) => r.body as RolesMembersDbDto),
    );
  }

  /**
   * Path part for operation roleMemberUpdate
   */
  static readonly RoleMemberUpdatePath = '/roles/role-members/{userId}';

  /**
   * update role-members
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `roleMemberUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  roleMemberUpdate$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;

    /**
     * userId
     */
    userId: string;
    body: RolesMemberUpdateReqDto;
  }): Observable<StrictHttpResponse<RolesMembersDbDto>> {
    const rb = new RequestBuilder(this.rootUrl, RolesService.RoleMemberUpdatePath, 'patch');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.path('userId', params.userId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<RolesMembersDbDto>;
        }),
      );
  }

  /**
   * update role-members
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `roleMemberUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  roleMemberUpdate(params: {
    /**
     * Localization
     */
    'x-lang'?: string;

    /**
     * userId
     */
    userId: string;
    body: RolesMemberUpdateReqDto;
  }): Observable<RolesMembersDbDto> {
    return this.roleMemberUpdate$Response(params).pipe(
      map((r: StrictHttpResponse<RolesMembersDbDto>) => r.body as RolesMembersDbDto),
    );
  }
}
