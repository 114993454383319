import { Component, OnInit } from '@angular/core';
import { features, IPlan } from '../../payment.const';
import { PaymentService, StepEnum } from '../../payment.service';
import { Store } from '@ngxs/store';
import { DeleteSubscribe } from '../../../../shared/store/actions/payment.action';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { AuthState } from '../../../../shared/store/states/auth.state';
import { RouterTenantPipe } from '../../../../shared/pipes/router-tenant.pipe';
import { ButtonComponent } from '../../../../shared/components/ui-elements/button/button.component';
import { PaymentFeatureCardComponent } from '../payment-feature-card/payment-feature-card.component';
import { NgFor } from '@angular/common';
import { TranslocoDirective } from '@ngneat/transloco';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-payment-accept-step',
  templateUrl: './payment-accept-step.component.html',
  styleUrls: ['./payment-accept-step.component.scss'],
  standalone: true,
  imports: [TranslocoDirective, NgFor, PaymentFeatureCardComponent, ButtonComponent],
})
export class PaymentAcceptStepComponent implements OnInit {
  public isLoading = false;
  public currentPlan: IPlan;
  public readonly features = features;

  constructor(
    private paymentService: PaymentService,
    private store: Store,
    private toast: ToastrService,
    private router: Router,
    private routerTenantPipe: RouterTenantPipe,
  ) {}

  ngOnInit(): void {
    this.currentPlan = this.paymentService.selectedPlan;
  }

  public handleBack(): void {
    this.paymentService.handleSelectPlan({ step: StepEnum.Choice, plan: null });
  }

  public handleAccept(): void {
    // TODO: Change delete subscribe to cancel

    this.isLoading = true;

    this.store
      .dispatch(new DeleteSubscribe())
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.toast.success('Subscribe has been deleted', 'Success');
        this.paymentService.handleClose();

        const user = this.store.selectSnapshot(AuthState.getUser);
        this.router.navigate([this.routerTenantPipe.transform('dash', user.tenantName)]);
      });
  }
}
