/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { SprintsCreateReqDto } from '../models/sprints-create-req-dto';
import { SprintsDbDto } from '../models/sprints-db-dto';
import { SprintsUpdateReqDto } from '../models/sprints-update-req-dto';

@Injectable({
  providedIn: 'root',
})
export class BoardsSprintsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation sprintsGetList
   */
  static readonly SprintsGetListPath = '/boards/sprints';

  /**
   * get sprints list
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sprintsGetList()` instead.
   *
   * This method doesn't expect any request body.
   */
  sprintsGetList$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;

    /**
     * spaces | projects
     */
    object: string;

    /**
     * objectId
     */
    objectId: string;
  }): Observable<StrictHttpResponse<Array<SprintsDbDto>>> {
    const rb = new RequestBuilder(this.rootUrl, BoardsSprintsService.SprintsGetListPath, 'get');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.query('object', params.object, {});
      rb.query('objectId', params.objectId, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<SprintsDbDto>>;
        }),
      );
  }

  /**
   * get sprints list
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `sprintsGetList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  sprintsGetList(params: {
    /**
     * Localization
     */
    'x-lang'?: string;

    /**
     * spaces | projects
     */
    object: string;

    /**
     * objectId
     */
    objectId: string;
  }): Observable<Array<SprintsDbDto>> {
    return this.sprintsGetList$Response(params).pipe(
      map((r: StrictHttpResponse<Array<SprintsDbDto>>) => r.body as Array<SprintsDbDto>),
    );
  }

  /**
   * Path part for operation sprintsCreate
   */
  static readonly SprintsCreatePath = '/boards/sprints';

  /**
   * create sprint
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sprintsCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  sprintsCreate$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    body: SprintsCreateReqDto;
  }): Observable<StrictHttpResponse<SprintsDbDto>> {
    const rb = new RequestBuilder(this.rootUrl, BoardsSprintsService.SprintsCreatePath, 'post');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<SprintsDbDto>;
        }),
      );
  }

  /**
   * create sprint
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `sprintsCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  sprintsCreate(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    body: SprintsCreateReqDto;
  }): Observable<SprintsDbDto> {
    return this.sprintsCreate$Response(params).pipe(
      map((r: StrictHttpResponse<SprintsDbDto>) => r.body as SprintsDbDto),
    );
  }

  /**
   * Path part for operation sprintsDelete
   */
  static readonly SprintsDeletePath = '/boards/sprints/{id}';

  /**
   * soft delete sprints
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sprintsDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  sprintsDelete$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    id: string;
  }): Observable<StrictHttpResponse<SprintsDbDto>> {
    const rb = new RequestBuilder(this.rootUrl, BoardsSprintsService.SprintsDeletePath, 'delete');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.path('id', params.id, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<SprintsDbDto>;
        }),
      );
  }

  /**
   * soft delete sprints
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `sprintsDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  sprintsDelete(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    id: string;
  }): Observable<SprintsDbDto> {
    return this.sprintsDelete$Response(params).pipe(
      map((r: StrictHttpResponse<SprintsDbDto>) => r.body as SprintsDbDto),
    );
  }

  /**
   * Path part for operation sprintsUpdate
   */
  static readonly SprintsUpdatePath = '/boards/sprints/{id}';

  /**
   * update sprints
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sprintsUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  sprintsUpdate$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    id: string;
    body: SprintsUpdateReqDto;
  }): Observable<StrictHttpResponse<SprintsDbDto>> {
    const rb = new RequestBuilder(this.rootUrl, BoardsSprintsService.SprintsUpdatePath, 'patch');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<SprintsDbDto>;
        }),
      );
  }

  /**
   * update sprints
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `sprintsUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  sprintsUpdate(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    id: string;
    body: SprintsUpdateReqDto;
  }): Observable<SprintsDbDto> {
    return this.sprintsUpdate$Response(params).pipe(
      map((r: StrictHttpResponse<SprintsDbDto>) => r.body as SprintsDbDto),
    );
  }
}
