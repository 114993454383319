import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class HtmlHelper {
  // constructor( private store: Store) {}

  copyValueToBuffer(value) {
    // const platform = this.store.selectSnapshot(AuthState.getPlatform);
    // if (platform === 'web') {
    const el = document.createElement('textarea');
    el.value = value;
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    // } else {
    //   this.copyText(value);
    // }
  }
}
