/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CommonHealthResDto } from '../models/common-health-res-dto';

@Injectable({
  providedIn: 'root',
})
export class HealthService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation getHealth
   */
  static readonly GetHealthPath = '/health';

  /**
   * check if service is healthy and able to respond requests
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getHealth()` instead.
   *
   * This method doesn't expect any request body.
   */
  getHealth$Response(params?: {
    /**
     * Localization
     */
    'x-lang'?: string;
  }): Observable<StrictHttpResponse<CommonHealthResDto>> {
    const rb = new RequestBuilder(this.rootUrl, HealthService.GetHealthPath, 'get');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<CommonHealthResDto>;
        }),
      );
  }

  /**
   * check if service is healthy and able to respond requests
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getHealth$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getHealth(params?: {
    /**
     * Localization
     */
    'x-lang'?: string;
  }): Observable<CommonHealthResDto> {
    return this.getHealth$Response(params).pipe(
      map((r: StrictHttpResponse<CommonHealthResDto>) => r.body as CommonHealthResDto),
    );
  }
}
