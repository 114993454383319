<div class="custom-select-input" [class.w-full]="widthFull" [class.dark]="isDark">
  <p *ngIf="legend">{{ legend }}</p>
  <ng-select
    [items]="items"
    [placeholder]="placeHolder"
    [bindLabel]="bindLabel"
    [bindValue]="bindValue"
    [(ngModel)]="_value"
    [searchable]="false"
    [clearable]="false"
    (change)="onChange($event)"
  ></ng-select>
</div>
