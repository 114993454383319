/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CommonSuccessResDto } from '../models/common-success-res-dto';
import { GetBrandingResDto } from '../models/get-branding-res-dto';
import { VideoCallsGetResDto } from '../models/video-calls-get-res-dto';
import { VideoCallsGetTokenResDto } from '../models/video-calls-get-token-res-dto';
import { VideoCallsSendActionReqDto } from '../models/video-calls-send-action-req-dto';
import { VideoCallsWebhooksEventsReqDto } from '../models/video-calls-webhooks-events-req-dto';

@Injectable({
  providedIn: 'root',
})
export class VideoCallsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation videoCallsGetActiveRooms
   */
  static readonly VideoCallsGetActiveRoomsPath = '/video-calls';

  /**
   * get active rooms array
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `videoCallsGetActiveRooms()` instead.
   *
   * This method doesn't expect any request body.
   */
  videoCallsGetActiveRooms$Response(params?: {
    /**
     * Localization
     */
    'x-lang'?: string;
  }): Observable<StrictHttpResponse<Array<VideoCallsGetResDto>>> {
    const rb = new RequestBuilder(this.rootUrl, VideoCallsService.VideoCallsGetActiveRoomsPath, 'get');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<VideoCallsGetResDto>>;
        }),
      );
  }

  /**
   * get active rooms array
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `videoCallsGetActiveRooms$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  videoCallsGetActiveRooms(params?: {
    /**
     * Localization
     */
    'x-lang'?: string;
  }): Observable<Array<VideoCallsGetResDto>> {
    return this.videoCallsGetActiveRooms$Response(params).pipe(
      map((r: StrictHttpResponse<Array<VideoCallsGetResDto>>) => r.body as Array<VideoCallsGetResDto>),
    );
  }

  /**
   * Path part for operation videoCallsGetBrandingData
   */
  static readonly VideoCallsGetBrandingDataPath = '/video-calls/branding';

  /**
   * get branding data
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `videoCallsGetBrandingData()` instead.
   *
   * This method doesn't expect any request body.
   */
  videoCallsGetBrandingData$Response(params?: {
    /**
     * Localization
     */
    'x-lang'?: string;
  }): Observable<StrictHttpResponse<GetBrandingResDto>> {
    const rb = new RequestBuilder(this.rootUrl, VideoCallsService.VideoCallsGetBrandingDataPath, 'get');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<GetBrandingResDto>;
        }),
      );
  }

  /**
   * get branding data
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `videoCallsGetBrandingData$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  videoCallsGetBrandingData(params?: {
    /**
     * Localization
     */
    'x-lang'?: string;
  }): Observable<GetBrandingResDto> {
    return this.videoCallsGetBrandingData$Response(params).pipe(
      map((r: StrictHttpResponse<GetBrandingResDto>) => r.body as GetBrandingResDto),
    );
  }

  /**
   * Path part for operation videoCallsWebhooksEvents
   */
  static readonly VideoCallsWebhooksEventsPath = '/video-calls/webhooks-events';

  /**
   * webhooks events
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `videoCallsWebhooksEvents()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  videoCallsWebhooksEvents$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    body: VideoCallsWebhooksEventsReqDto;
  }): Observable<StrictHttpResponse<CommonSuccessResDto>> {
    const rb = new RequestBuilder(this.rootUrl, VideoCallsService.VideoCallsWebhooksEventsPath, 'post');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<CommonSuccessResDto>;
        }),
      );
  }

  /**
   * webhooks events
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `videoCallsWebhooksEvents$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  videoCallsWebhooksEvents(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    body: VideoCallsWebhooksEventsReqDto;
  }): Observable<CommonSuccessResDto> {
    return this.videoCallsWebhooksEvents$Response(params).pipe(
      map((r: StrictHttpResponse<CommonSuccessResDto>) => r.body as CommonSuccessResDto),
    );
  }

  /**
   * Path part for operation videoCallsGetToken
   */
  static readonly VideoCallsGetTokenPath = '/video-calls/token/{id}';

  /**
   * get token and subject
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `videoCallsGetToken()` instead.
   *
   * This method doesn't expect any request body.
   */
  videoCallsGetToken$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    id: string;
  }): Observable<StrictHttpResponse<VideoCallsGetTokenResDto>> {
    const rb = new RequestBuilder(this.rootUrl, VideoCallsService.VideoCallsGetTokenPath, 'get');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.path('id', params.id, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<VideoCallsGetTokenResDto>;
        }),
      );
  }

  /**
   * get token and subject
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `videoCallsGetToken$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  videoCallsGetToken(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    id: string;
  }): Observable<VideoCallsGetTokenResDto> {
    return this.videoCallsGetToken$Response(params).pipe(
      map((r: StrictHttpResponse<VideoCallsGetTokenResDto>) => r.body as VideoCallsGetTokenResDto),
    );
  }

  /**
   * Path part for operation videoCallsGetExternalToken
   */
  static readonly VideoCallsGetExternalTokenPath = '/video-calls/external-token/{id}';

  /**
   * get external jaas token and subject
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `videoCallsGetExternalToken()` instead.
   *
   * This method doesn't expect any request body.
   */
  videoCallsGetExternalToken$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    id: string;

    /**
     * key
     */
    key: string;
  }): Observable<StrictHttpResponse<VideoCallsGetTokenResDto>> {
    const rb = new RequestBuilder(this.rootUrl, VideoCallsService.VideoCallsGetExternalTokenPath, 'get');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.path('id', params.id, {});
      rb.query('key', params.key, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<VideoCallsGetTokenResDto>;
        }),
      );
  }

  /**
   * get external jaas token and subject
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `videoCallsGetExternalToken$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  videoCallsGetExternalToken(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    id: string;

    /**
     * key
     */
    key: string;
  }): Observable<VideoCallsGetTokenResDto> {
    return this.videoCallsGetExternalToken$Response(params).pipe(
      map((r: StrictHttpResponse<VideoCallsGetTokenResDto>) => r.body as VideoCallsGetTokenResDto),
    );
  }

  /**
   * Path part for operation videoCallsGetInviteLink
   */
  static readonly VideoCallsGetInviteLinkPath = '/video-calls/invite-link/{id}';

  /**
   * get temporary invite link
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `videoCallsGetInviteLink()` instead.
   *
   * This method doesn't expect any request body.
   */
  videoCallsGetInviteLink$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    id: string;
  }): Observable<StrictHttpResponse<string>> {
    const rb = new RequestBuilder(this.rootUrl, VideoCallsService.VideoCallsGetInviteLinkPath, 'get');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.path('id', params.id, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: 'text/plain',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<string>;
        }),
      );
  }

  /**
   * get temporary invite link
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `videoCallsGetInviteLink$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  videoCallsGetInviteLink(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    id: string;
  }): Observable<string> {
    return this.videoCallsGetInviteLink$Response(params).pipe(map((r: StrictHttpResponse<string>) => r.body as string));
  }

  /**
   * Path part for operation videoCallsSendAction
   */
  static readonly VideoCallsSendActionPath = '/video-calls/send-action';

  /**
   * send action to incoming call
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `videoCallsSendAction()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  videoCallsSendAction$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    body: VideoCallsSendActionReqDto;
  }): Observable<StrictHttpResponse<CommonSuccessResDto>> {
    const rb = new RequestBuilder(this.rootUrl, VideoCallsService.VideoCallsSendActionPath, 'post');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<CommonSuccessResDto>;
        }),
      );
  }

  /**
   * send action to incoming call
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `videoCallsSendAction$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  videoCallsSendAction(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    body: VideoCallsSendActionReqDto;
  }): Observable<CommonSuccessResDto> {
    return this.videoCallsSendAction$Response(params).pipe(
      map((r: StrictHttpResponse<CommonSuccessResDto>) => r.body as CommonSuccessResDto),
    );
  }
}
