/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { BillingDetailsCountryResDto } from '../models/billing-details-country-res-dto';
import { BillingDetailsDbDto } from '../models/billing-details-db-dto';
import { BillingDetailsResDto } from '../models/billing-details-res-dto';
import { BillingDetailsWithCountriesResDto } from '../models/billing-details-with-countries-res-dto';
import { CreateBillingDetailsReqDto } from '../models/create-billing-details-req-dto';
import { UpdateBillingDetailsReqDto } from '../models/update-billing-details-req-dto';

@Injectable({
  providedIn: 'root',
})
export class BillingDetailsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation billingDetailsGet
   */
  static readonly BillingDetailsGetPath = '/billing-details';

  /**
   * get billing details
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `billingDetailsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  billingDetailsGet$Response(params?: {}): Observable<StrictHttpResponse<BillingDetailsResDto>> {
    const rb = new RequestBuilder(this.rootUrl, BillingDetailsService.BillingDetailsGetPath, 'get');
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<BillingDetailsResDto>;
        }),
      );
  }

  /**
   * get billing details
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `billingDetailsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  billingDetailsGet(params?: {}): Observable<BillingDetailsResDto> {
    return this.billingDetailsGet$Response(params).pipe(
      map((r: StrictHttpResponse<BillingDetailsResDto>) => r.body as BillingDetailsResDto),
    );
  }

  /**
   * Path part for operation billingDetailsCreate
   */
  static readonly BillingDetailsCreatePath = '/billing-details';

  /**
   * create billing details
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `billingDetailsCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  billingDetailsCreate$Response(params: {
    body: CreateBillingDetailsReqDto;
  }): Observable<StrictHttpResponse<BillingDetailsDbDto>> {
    const rb = new RequestBuilder(this.rootUrl, BillingDetailsService.BillingDetailsCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<BillingDetailsDbDto>;
        }),
      );
  }

  /**
   * create billing details
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `billingDetailsCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  billingDetailsCreate(params: { body: CreateBillingDetailsReqDto }): Observable<BillingDetailsDbDto> {
    return this.billingDetailsCreate$Response(params).pipe(
      map((r: StrictHttpResponse<BillingDetailsDbDto>) => r.body as BillingDetailsDbDto),
    );
  }

  /**
   * Path part for operation billingDetailsDelete
   */
  static readonly BillingDetailsDeletePath = '/billing-details/{id}';

  /**
   * delete billing details
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `billingDetailsDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  billingDetailsDelete$Response(params: { id: string }): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, BillingDetailsService.BillingDetailsDeletePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: '*/*',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
        }),
      );
  }

  /**
   * delete billing details
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `billingDetailsDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  billingDetailsDelete(params: { id: string }): Observable<void> {
    return this.billingDetailsDelete$Response(params).pipe(map((r: StrictHttpResponse<void>) => r.body as void));
  }

  /**
   * Path part for operation billingDetailsUpdate
   */
  static readonly BillingDetailsUpdatePath = '/billing-details/{id}';

  /**
   * update billing details
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `billingDetailsUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  billingDetailsUpdate$Response(params: {
    id: string;
    body: UpdateBillingDetailsReqDto;
  }): Observable<StrictHttpResponse<BillingDetailsDbDto>> {
    const rb = new RequestBuilder(this.rootUrl, BillingDetailsService.BillingDetailsUpdatePath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<BillingDetailsDbDto>;
        }),
      );
  }

  /**
   * update billing details
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `billingDetailsUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  billingDetailsUpdate(params: { id: string; body: UpdateBillingDetailsReqDto }): Observable<BillingDetailsDbDto> {
    return this.billingDetailsUpdate$Response(params).pipe(
      map((r: StrictHttpResponse<BillingDetailsDbDto>) => r.body as BillingDetailsDbDto),
    );
  }

  /**
   * Path part for operation billingDetailsGetCountries
   */
  static readonly BillingDetailsGetCountriesPath = '/billing-details/countries';

  /**
   * get countries
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `billingDetailsGetCountries()` instead.
   *
   * This method doesn't expect any request body.
   */
  billingDetailsGetCountries$Response(params?: {}): Observable<StrictHttpResponse<Array<BillingDetailsCountryResDto>>> {
    const rb = new RequestBuilder(this.rootUrl, BillingDetailsService.BillingDetailsGetCountriesPath, 'get');
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<BillingDetailsCountryResDto>>;
        }),
      );
  }

  /**
   * get countries
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `billingDetailsGetCountries$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  billingDetailsGetCountries(params?: {}): Observable<Array<BillingDetailsCountryResDto>> {
    return this.billingDetailsGetCountries$Response(params).pipe(
      map((r: StrictHttpResponse<Array<BillingDetailsCountryResDto>>) => r.body as Array<BillingDetailsCountryResDto>),
    );
  }

  /**
   * Path part for operation billingDetailsGetWithCountries
   */
  static readonly BillingDetailsGetWithCountriesPath = '/billing-details/with-countries';

  /**
   * get billing details
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `billingDetailsGetWithCountries()` instead.
   *
   * This method doesn't expect any request body.
   */
  billingDetailsGetWithCountries$Response(params?: {}): Observable<
    StrictHttpResponse<BillingDetailsWithCountriesResDto>
  > {
    const rb = new RequestBuilder(this.rootUrl, BillingDetailsService.BillingDetailsGetWithCountriesPath, 'get');
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<BillingDetailsWithCountriesResDto>;
        }),
      );
  }

  /**
   * get billing details
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `billingDetailsGetWithCountries$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  billingDetailsGetWithCountries(params?: {}): Observable<BillingDetailsWithCountriesResDto> {
    return this.billingDetailsGetWithCountries$Response(params).pipe(
      map((r: StrictHttpResponse<BillingDetailsWithCountriesResDto>) => r.body as BillingDetailsWithCountriesResDto),
    );
  }
}
