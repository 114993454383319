/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CallBackPaymentsStatusReqDto } from '../models/call-back-payments-status-req-dto';
import { CreatePaymentsReqDto } from '../models/create-payments-req-dto';
import { PaymentsAmountResDto } from '../models/payments-amount-res-dto';
import { PaymentsResDto } from '../models/payments-res-dto';

@Injectable({
  providedIn: 'root',
})
export class PaymentsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation paymentsGetList
   */
  static readonly PaymentsGetListPath = '/payments';

  /**
   * get list of payments
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `paymentsGetList()` instead.
   *
   * This method doesn't expect any request body.
   */
  paymentsGetList$Response(params?: {
    /**
     * Localization
     */
    'x-lang'?: string;
  }): Observable<StrictHttpResponse<Array<PaymentsResDto>>> {
    const rb = new RequestBuilder(this.rootUrl, PaymentsService.PaymentsGetListPath, 'get');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<PaymentsResDto>>;
        }),
      );
  }

  /**
   * get list of payments
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `paymentsGetList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  paymentsGetList(params?: {
    /**
     * Localization
     */
    'x-lang'?: string;
  }): Observable<Array<PaymentsResDto>> {
    return this.paymentsGetList$Response(params).pipe(
      map((r: StrictHttpResponse<Array<PaymentsResDto>>) => r.body as Array<PaymentsResDto>),
    );
  }

  /**
   * Path part for operation paymentsCreate
   */
  static readonly PaymentsCreatePath = '/payments';

  /**
   * creates link for pay
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `paymentsCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  paymentsCreate$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    body: CreatePaymentsReqDto;
  }): Observable<StrictHttpResponse<PaymentsResDto>> {
    const rb = new RequestBuilder(this.rootUrl, PaymentsService.PaymentsCreatePath, 'post');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<PaymentsResDto>;
        }),
      );
  }

  /**
   * creates link for pay
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `paymentsCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  paymentsCreate(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    body: CreatePaymentsReqDto;
  }): Observable<PaymentsResDto> {
    return this.paymentsCreate$Response(params).pipe(
      map((r: StrictHttpResponse<PaymentsResDto>) => r.body as PaymentsResDto),
    );
  }

  /**
   * Path part for operation paymentsAmount
   */
  static readonly PaymentsAmountPath = '/payments/amount';

  /**
   * get payment amount
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `paymentsAmount()` instead.
   *
   * This method doesn't expect any request body.
   */
  paymentsAmount$Response(params?: {
    /**
     * Localization
     */
    'x-lang'?: string;
    type?: 'monthly' | 'yearly';
  }): Observable<StrictHttpResponse<PaymentsAmountResDto>> {
    const rb = new RequestBuilder(this.rootUrl, PaymentsService.PaymentsAmountPath, 'get');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.query('type', params.type, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<PaymentsAmountResDto>;
        }),
      );
  }

  /**
   * get payment amount
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `paymentsAmount$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  paymentsAmount(params?: {
    /**
     * Localization
     */
    'x-lang'?: string;
    type?: 'monthly' | 'yearly';
  }): Observable<PaymentsAmountResDto> {
    return this.paymentsAmount$Response(params).pipe(
      map((r: StrictHttpResponse<PaymentsAmountResDto>) => r.body as PaymentsAmountResDto),
    );
  }

  /**
   * Path part for operation paymentsGetTransactionStatus
   */
  static readonly PaymentsGetTransactionStatusPath = '/payments/{id}/transaction-status';

  /**
   * get transaction status from api
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `paymentsGetTransactionStatus()` instead.
   *
   * This method doesn't expect any request body.
   */
  paymentsGetTransactionStatus$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    id: string;
  }): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, PaymentsService.PaymentsGetTransactionStatusPath, 'get');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.path('id', params.id, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: '*/*',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
        }),
      );
  }

  /**
   * get transaction status from api
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `paymentsGetTransactionStatus$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  paymentsGetTransactionStatus(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    id: string;
  }): Observable<void> {
    return this.paymentsGetTransactionStatus$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void),
    );
  }

  /**
   * Path part for operation paymentsDelete
   */
  static readonly PaymentsDeletePath = '/payments/{id}';

  /**
   * delete payment
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `paymentsDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  paymentsDelete$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    id: string;
  }): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, PaymentsService.PaymentsDeletePath, 'delete');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.path('id', params.id, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: '*/*',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
        }),
      );
  }

  /**
   * delete payment
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `paymentsDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  paymentsDelete(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    id: string;
  }): Observable<void> {
    return this.paymentsDelete$Response(params).pipe(map((r: StrictHttpResponse<void>) => r.body as void));
  }

  /**
   * Path part for operation paymentsStatus
   */
  static readonly PaymentsStatusPath = '/payments/status';

  /**
   * status call back
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `paymentsStatus()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  paymentsStatus$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    body: CallBackPaymentsStatusReqDto;
  }): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, PaymentsService.PaymentsStatusPath, 'post');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: '*/*',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
        }),
      );
  }

  /**
   * status call back
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `paymentsStatus$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  paymentsStatus(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    body: CallBackPaymentsStatusReqDto;
  }): Observable<void> {
    return this.paymentsStatus$Response(params).pipe(map((r: StrictHttpResponse<void>) => r.body as void));
  }

  /**
   * Path part for operation paymentsControllerAutoPayTest
   */
  static readonly PaymentsControllerAutoPayTestPath = '/payments/auto-payment-test';

  /**
   * auto payment test
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `paymentsControllerAutoPayTest()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  paymentsControllerAutoPayTest$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    body: CreatePaymentsReqDto;
  }): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, PaymentsService.PaymentsControllerAutoPayTestPath, 'post');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: '*/*',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
        }),
      );
  }

  /**
   * auto payment test
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `paymentsControllerAutoPayTest$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  paymentsControllerAutoPayTest(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    body: CreatePaymentsReqDto;
  }): Observable<void> {
    return this.paymentsControllerAutoPayTest$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void),
    );
  }

  /**
   * Path part for operation paymentsControllerInvoiceTest
   */
  static readonly PaymentsControllerInvoiceTestPath = '/payments/invoice-test';

  /**
   * invoice test
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `paymentsControllerInvoiceTest()` instead.
   *
   * This method doesn't expect any request body.
   */
  paymentsControllerInvoiceTest$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    transactionId: string;
  }): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, PaymentsService.PaymentsControllerInvoiceTestPath, 'post');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.query('transactionId', params.transactionId, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: '*/*',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
        }),
      );
  }

  /**
   * invoice test
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `paymentsControllerInvoiceTest$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  paymentsControllerInvoiceTest(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    transactionId: string;
  }): Observable<void> {
    return this.paymentsControllerInvoiceTest$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void),
    );
  }
}
