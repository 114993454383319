<button
  [type]="typeButton"
  class="button"
  [ngClass]="[customClasses, variantButton, widthFull ? 'w-full' : '', isDark ? 'dark' : '']"
  (click)="onClick()"
  [disabled]="disabled || isLoading"
>
  <i *ngIf="isLoading" class="fa fa-spinner fa-spin connection_spinner" aria-hidden="true"></i>
  <svg-icon *ngIf="!isLoading && svgPath" [src]="svgPath" [class]="svgClass"></svg-icon>
  {{ isLoading ? '' : label }}
</button>
