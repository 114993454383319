import { Pipe, PipeTransform } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable, of } from 'rxjs';
import { switchMap, map } from 'rxjs/operators';
import { UsersState } from '../../shared/store/states/users.state';

@Pipe({
  name: 'userNameAlone',
  standalone: true,
})
export class UserNameAlonePipe implements PipeTransform {
  constructor(private store: Store) {}

  transform(userId: any): Observable<{
    userName: string;
    loading: boolean;
    state: string;
    state_end: string;
    state_icon: string;
  }> {
    const loading$ = this.store.select(UsersState.isLoading);
    return loading$.pipe(
      switchMap((loading) => {
        if (!loading) {
          return this.getUserByIdObservable(userId).pipe(
            map((userData) => {
              return {
                userName: userData.userName,
                state: userData.state,
                state_end: userData.state_end,
                state_icon: userData.state_icon,
                loading,
              };
            }),
          );
        }
        return of({ userName: '', loading, state: '', state_end: '', state_icon: '' });
      }),
    );
  }

  private getUserByIdObservable(
    userId: any,
  ): Observable<{ userName: string; state: string; state_end: string; state_icon: string }> {
    return this.store
      .select(UsersState.getUserNameAndStateById)
      .pipe(map((selectorFn) => selectorFn(userId)));
  }
}
