import { TDate } from '../../../modals/payment-modal/components/payment-choice-step/payment-choice-step.component';
import { CreatePaymentsReqDto } from '../../../api/models/create-payments-req-dto';
import { CreateBillingDetailsReqDto } from '../../../api/models/create-billing-details-req-dto';
import { UpdateBillingDetailsReqDto } from '../../../api/models/update-billing-details-req-dto';

/** Subscription get **/
export class SubscriptionGet {
  static readonly type = '[Payment] Subscription get';
}

export class SubscriptionTypesGet {
  static readonly type = '[Payment] Subscription types get';
}

export class PaymentAmount {
  static readonly type = '[Payment] Payment amount';
  constructor(public payload: TDate) {}
}

export class BillingDetailsWithCountries {
  static readonly type = '[Payment] Billing details with countries';
}

export class BillingDetailsGet {
  static readonly type = '[Payment] Billing details get';
}

export class DeleteSubscribe {
  static readonly type = '[Payment] Delete subscribe';
}

export class GetCountries {
  static readonly type = '[Payment] Get Countries';
}

export class SubscribeSet {
  static readonly type = '[Payment] Subscribe Set';
  constructor(public payload: CreatePaymentsReqDto) {}
}

export class BillingCreate {
  static readonly type = '[Payment] Billing create';
  constructor(public payload: CreateBillingDetailsReqDto) {}
}

export class BillingUpdate {
  static readonly type = '[Payment] Billing update';
  constructor(public payload: { id: string; body: UpdateBillingDetailsReqDto }) {}
}
