<div class="payment" [class.dark]="isDark" *transloco="let t; read: 'modals.plan'">
  <!--Current plan-->
  <div class="payment__wrapper">
    <div class="payment__item">
      <h3 class="payment__subtitle">{{ t('current-subscription') }}</h3>
      <h4>{{ subscriptionTitle }} {{ t('subscription') }}</h4>
    </div>

    <!--For Basic-->
    <div *ngIf="!isFreeSubscription" class="payment__item payment__item-second">
      <p class="payment__paragraph">{{ t('next-payment') }} {{ data }}</p>
      <app-button
        [label]="t('btn-edit-plan')"
        (handleClick)="showModal()"
        variantButton="none"
        customClasses="padding-none"
      />
    </div>

    <!--For Free-->
    <div *ngIf="isFreeSubscription" class="payment__item payment__item-second">
      <div class="payment__box">
        <p class="payment__paragraph">{{ t('time-upgrade') }}</p>
        <p class="payment__paragraph">{{ t('description-subscription') }}</p>
      </div>
      <app-button [label]="'Upgrade now'" customClasses="padding-12" (handleClick)="showModal()" />
    </div>
  </div>

  <!--Seat-->
  <div class="payment__seat">
    <h3 class="payment__subtitle">{{ t('seat-title') }}</h3>
    <div class="payment__seat-list">
      <p class="payment__paragraph">
        {{ t('spaces') }}: {{ isFreeSubscription ? '2' : t('ultimated') }}
      </p>
      <!-- Spaces -->
      <p class="payment__paragraph">
        {{ t('projects') }}: {{ isFreeSubscription ? '2' : t('ultimated') }}
      </p>
      <!-- Projects -->
      <p class="payment__paragraph">
        {{ t('users') }}: {{ isFreeSubscription ? '2' : t('ultimated') }}
      </p>
      <!-- Users -->
      <p *ngIf="!isFreeSubscription" class="payment__paragraph">
        {{ t('billing-cycle') }}: {{ subscription.subscription.type }}
      </p>
      <!-- Billing -->
    </div>
  </div>

  <!--Info-->
  <div *ngIf="!isFreeSubscription && !isLoading" class="payment__info">
    <div class="payment__info-header">
      <h3 class="payment__subtitle">{{ t('billing-info') }}</h3>
      <app-button
        [label]="t('edit-billing')"
        (handleClick)="showEditModal()"
        variantButton="none"
        customClasses="padding-none"
      />
    </div>
    <p class="payment__paragraph">{{ billingDetail?.firstName }} {{ billingDetail?.lastName }}</p>
    <p class="payment__paragraph">{{ billingDetail?.companyName }}</p>
    <p class="payment__paragraph">{{ billingDetail?.countryName }}</p>
    <p class="payment__paragraph">{{ billingDetail?.postalCode }}</p>
    <p class="payment__paragraph">{{ billingDetail?.address }}</p>
    <p class="payment__paragraph">{{ billingDetail?.city }}</p>

    <p class="payment__paragraph payment__info-card">{{ t('card-end') }} *2345</p>
  </div>
  <div *ngIf="!isFreeSubscription && isLoading" class="payment__loading">
    <i class="fa fa-spinner fa-spin connection_spinner" aria-hidden="true"></i>
  </div>
</div>
