import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { Subscription } from 'rxjs';
import { SpacesState } from '../../../store/states/spaces.state';
import { AuthState } from '../../../store/states/auth.state';
import { UsersDbDto } from '../../../../api/models/users-db-dto';
import { NgClass, NgIf } from '@angular/common';

@Component({
  selector: 'app-space-avatar',
  templateUrl: './space-avatar.component.html',
  styleUrls: ['./space-avatar.component.scss'],
  standalone: true,
  imports: [NgClass, NgIf],
})
export class SpaceAvatarComponent implements OnInit, OnDestroy {
  @Input() space: any;
  @Input() overlay = false;
  @Input() isBreadcrumb = false;
  @Input() containerWidth = 35;
  @Input() containerHeight = 35;
  @Input() mr = true;

  avatar$: Subscription;
  currentUser: UsersDbDto;

  constructor(private store: Store) {}

  ngOnInit() {
    this.currentUser = this.store.selectSnapshot(AuthState.getUser);

    if (this.isBreadcrumb) {
      this.containerWidth = 25;
      this.containerHeight = 25;
      this.mr = false;
    }

    this.avatar$ = this.store.select(SpacesState.getLoadedSpaces).subscribe((spaces) => {
      const initSpace = spaces.find(
        (item) =>
          item._id === this.space?._id ||
          item._id === this.space?.objectId ||
          item._id === this.space?.spaceId,
      );

      if (initSpace) {
        this.space = {
          ...initSpace,
          ...this.space,
          spaceAvatarUrl: initSpace.avatarUrl,
        };
      }
    });
  }

  ngOnDestroy() {
    this.avatar$?.unsubscribe();
  }

  get avatarText(): string {
    const spaceName = this.space?.isPersonal
      ? this.currentUser.userName
      : this.space?.spaceName || this.space?.prefix;
    return spaceName?.charAt(0).toUpperCase();
  }

  get avatarImage() {
    return this.space?.isPersonal && !this.space?.avatarUrl
      ? this.currentUser.avatarUrl
      : this.space?.avatarUrl || this.space?.spaceAvatarUrl;
  }

  get checkAvatarImage() {
    return this.space?.isPersonal
      ? !this.currentUser.avatarUrl && !this.space?.avatarUrl && !this.space?.spaceAvatarUrl
      : !this.space?.avatarUrl && !this.space?.spaceAvatarUrl;
  }
}
