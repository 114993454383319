<div
  class="relative flex items-center justify-center mr-2"
  [ngClass]="{ 'avatar-breadcrumb': isBreadcrumb }"
  [style.width.px]="containerWidth"
  [style.height.px]="containerHeight"
>
  <img *ngIf="avatarImage" [src]="avatarImage" alt="Space avatar image" class="rounded-md" />

  <div
    *ngIf="checkAvatarImage"
    class="bg-primary-600 rounded-md text-white flex justify-center items-center font-semibold text-xs w-full h-full"
  >
    {{ avatarText }}
  </div>

  <div
    *ngIf="overlay"
    class="absolute top-0 flex items-center justify-center w-full h-full bg-black bg-opacity-30 text-white rounded-md opacity-0 invisible transition-opacity duration-500"
  >
    <i class="fas fa-edit fa-2x"></i>
  </div>
</div>
