/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CommonObjectObjectIdReqDto } from '../models/common-object-object-id-req-dto';
import { CommonPaginationResDto } from '../models/common-pagination-res-dto';
import { CommonSuccessResDto } from '../models/common-success-res-dto';
import { CommonUseridsAndRolesListsDto } from '../models/common-userids-and-roles-lists-dto';
import { ContentsDbDto } from '../models/contents-db-dto';
import { DocumentsCreateReqDto } from '../models/documents-create-req-dto';
import { DocumentsDbDto } from '../models/documents-db-dto';
import { DocumentsUpdateReqDto } from '../models/documents-update-req-dto';
import { FilesDbDto } from '../models/files-db-dto';
import { FilesGetBase64ResDto } from '../models/files-get-base-64-res-dto';
import { FilesGetUrlResDto } from '../models/files-get-url-res-dto';
import { FilesSearchResDto } from '../models/files-search-res-dto';
import { FilesUpdateReqDto } from '../models/files-update-req-dto';
import { FoldersDbDto } from '../models/folders-db-dto';
import { FoldersGetAllResDto } from '../models/folders-get-all-res-dto';
import { FoldersGetResDto } from '../models/folders-get-res-dto';
import { FoldersManualCreateReqDto } from '../models/folders-manual-create-req-dto';
import { FoldersUpdateReqDto } from '../models/folders-update-req-dto';
import { IntersectionFilesCreatesDtoCommonFileReqDto } from '../models/intersection-files-creates-dto-common-file-req-dto';
import { IntersectionFilesSaveFileToDataRoomReqDtoCommonOptionalFileReqDto } from '../models/intersection-files-save-file-to-data-room-req-dto-common-optional-file-req-dto';
import { LinksCreateReqDto } from '../models/links-create-req-dto';
import { LinksDbDto } from '../models/links-db-dto';
import { LinksUpdateReqDto } from '../models/links-update-req-dto';

@Injectable({
  providedIn: 'root',
})
export class FilesService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation filesSearch
   */
  static readonly FilesSearchPath = '/files/search';

  /**
   * search for all kind of files
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `filesSearch()` instead.
   *
   * This method doesn't expect any request body.
   */
  filesSearch$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;

    /**
     * Search string
     */
    search?: string;

    /**
     * Search key | keys
     */
    searchBy?: string;

    /**
     * Sorting direction
     */
    sort?: string;

    /**
     * Sorting by field
     */
    sortBy?: string;

    /**
     * Message id
     */
    messageId?: string;

    /**
     * Limit resulting elements(default *10*).
     */
    perPage?: number;

    /**
     * Number of current page.(default *1*).
     */
    page?: number;

    /**
     * tenants|spaces|projects
     */
    object: string;

    /**
     * tenants|spaces|projects id
     */
    objectId?: string;
  }): Observable<StrictHttpResponse<FilesSearchResDto>> {
    const rb = new RequestBuilder(this.rootUrl, FilesService.FilesSearchPath, 'get');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.query('search', params.search, {});
      rb.query('searchBy', params.searchBy, {});
      rb.query('sort', params.sort, {});
      rb.query('sortBy', params.sortBy, {});
      rb.query('messageId', params.messageId, {});
      rb.query('perPage', params.perPage, {});
      rb.query('page', params.page, {});
      rb.query('object', params.object, {});
      rb.query('objectId', params.objectId, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<FilesSearchResDto>;
        }),
      );
  }

  /**
   * search for all kind of files
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `filesSearch$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  filesSearch(params: {
    /**
     * Localization
     */
    'x-lang'?: string;

    /**
     * Search string
     */
    search?: string;

    /**
     * Search key | keys
     */
    searchBy?: string;

    /**
     * Sorting direction
     */
    sort?: string;

    /**
     * Sorting by field
     */
    sortBy?: string;

    /**
     * Message id
     */
    messageId?: string;

    /**
     * Limit resulting elements(default *10*).
     */
    perPage?: number;

    /**
     * Number of current page.(default *1*).
     */
    page?: number;

    /**
     * tenants|spaces|projects
     */
    object: string;

    /**
     * tenants|spaces|projects id
     */
    objectId?: string;
  }): Observable<FilesSearchResDto> {
    return this.filesSearch$Response(params).pipe(
      map((r: StrictHttpResponse<FilesSearchResDto>) => r.body as FilesSearchResDto),
    );
  }

  /**
   * Path part for operation filesGet
   */
  static readonly FilesGetPath = '/files';

  /**
   * gets files from files
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `filesGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  filesGet$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    _id: string;

    /**
     * tenants|spaces|projects
     */
    object: string;

    /**
     * tenants|spaces|projects id
     */
    objectId: string;

    /**
     * folderId
     */
    folderId?: string;
  }): Observable<StrictHttpResponse<FilesDbDto>> {
    const rb = new RequestBuilder(this.rootUrl, FilesService.FilesGetPath, 'get');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.query('_id', params['_id'], {});
      rb.query('object', params.object, {});
      rb.query('objectId', params.objectId, {});
      rb.query('folderId', params.folderId, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<FilesDbDto>;
        }),
      );
  }

  /**
   * gets files from files
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `filesGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  filesGet(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    _id: string;

    /**
     * tenants|spaces|projects
     */
    object: string;

    /**
     * tenants|spaces|projects id
     */
    objectId: string;

    /**
     * folderId
     */
    folderId?: string;
  }): Observable<FilesDbDto> {
    return this.filesGet$Response(params).pipe(map((r: StrictHttpResponse<FilesDbDto>) => r.body as FilesDbDto));
  }

  /**
   * Path part for operation filesUpload
   */
  static readonly FilesUploadPath = '/files';

  /**
   * uploads files to files
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `filesUpload()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  filesUpload$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    body: IntersectionFilesCreatesDtoCommonFileReqDto;
  }): Observable<StrictHttpResponse<FilesDbDto>> {
    const rb = new RequestBuilder(this.rootUrl, FilesService.FilesUploadPath, 'post');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<FilesDbDto>;
        }),
      );
  }

  /**
   * uploads files to files
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `filesUpload$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  filesUpload(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    body: IntersectionFilesCreatesDtoCommonFileReqDto;
  }): Observable<FilesDbDto> {
    return this.filesUpload$Response(params).pipe(map((r: StrictHttpResponse<FilesDbDto>) => r.body as FilesDbDto));
  }

  /**
   * Path part for operation filesGetBase64
   */
  static readonly FilesGetBase64Path = '/files/{id}/base64';

  /**
   * get stream with file
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `filesGetBase64()` instead.
   *
   * This method doesn't expect any request body.
   */
  filesGetBase64$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    id: string;
    fromDataRoom?: boolean;
  }): Observable<StrictHttpResponse<FilesGetBase64ResDto>> {
    const rb = new RequestBuilder(this.rootUrl, FilesService.FilesGetBase64Path, 'get');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.path('id', params.id, {});
      rb.query('fromDataRoom', params.fromDataRoom, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<FilesGetBase64ResDto>;
        }),
      );
  }

  /**
   * get stream with file
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `filesGetBase64$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  filesGetBase64(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    id: string;
    fromDataRoom?: boolean;
  }): Observable<FilesGetBase64ResDto> {
    return this.filesGetBase64$Response(params).pipe(
      map((r: StrictHttpResponse<FilesGetBase64ResDto>) => r.body as FilesGetBase64ResDto),
    );
  }

  /**
   * Path part for operation filesGetUrl
   */
  static readonly FilesGetUrlPath = '/files/get-url/{id}';

  /**
   * get pre-signed URL
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `filesGetUrl()` instead.
   *
   * This method doesn't expect any request body.
   */
  filesGetUrl$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    id: string;
  }): Observable<StrictHttpResponse<FilesGetUrlResDto>> {
    const rb = new RequestBuilder(this.rootUrl, FilesService.FilesGetUrlPath, 'get');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.path('id', params.id, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<FilesGetUrlResDto>;
        }),
      );
  }

  /**
   * get pre-signed URL
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `filesGetUrl$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  filesGetUrl(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    id: string;
  }): Observable<FilesGetUrlResDto> {
    return this.filesGetUrl$Response(params).pipe(
      map((r: StrictHttpResponse<FilesGetUrlResDto>) => r.body as FilesGetUrlResDto),
    );
  }

  /**
   * Path part for operation filesGetUrLs
   */
  static readonly FilesGetUrLsPath = '/files/get-urls/{id}';

  /**
   * get pre-signed URLs list by folder id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `filesGetUrLs()` instead.
   *
   * This method doesn't expect any request body.
   */
  filesGetUrLs$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;

    /**
     * folderId
     */
    id: string;
  }): Observable<StrictHttpResponse<Array<FilesGetUrlResDto>>> {
    const rb = new RequestBuilder(this.rootUrl, FilesService.FilesGetUrLsPath, 'post');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.path('id', params.id, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<FilesGetUrlResDto>>;
        }),
      );
  }

  /**
   * get pre-signed URLs list by folder id
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `filesGetUrLs$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  filesGetUrLs(params: {
    /**
     * Localization
     */
    'x-lang'?: string;

    /**
     * folderId
     */
    id: string;
  }): Observable<Array<FilesGetUrlResDto>> {
    return this.filesGetUrLs$Response(params).pipe(
      map((r: StrictHttpResponse<Array<FilesGetUrlResDto>>) => r.body as Array<FilesGetUrlResDto>),
    );
  }

  /**
   * Path part for operation filesUpdate
   */
  static readonly FilesUpdatePath = '/files/{id}';

  /**
   * updates files and permissions
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `filesUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  filesUpdate$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    id: string;

    /**
     * files data
     */
    body: FilesUpdateReqDto;
  }): Observable<StrictHttpResponse<FilesDbDto>> {
    const rb = new RequestBuilder(this.rootUrl, FilesService.FilesUpdatePath, 'patch');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<FilesDbDto>;
        }),
      );
  }

  /**
   * updates files and permissions
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `filesUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  filesUpdate(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    id: string;

    /**
     * files data
     */
    body: FilesUpdateReqDto;
  }): Observable<FilesDbDto> {
    return this.filesUpdate$Response(params).pipe(map((r: StrictHttpResponse<FilesDbDto>) => r.body as FilesDbDto));
  }

  /**
   * Path part for operation filesDelete
   */
  static readonly FilesDeletePath = '/files/delete-file/{id}';

  /**
   * delete file
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `filesDelete()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  filesDelete$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    id: string;

    /**
     * object data
     */
    body: CommonObjectObjectIdReqDto;
  }): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, FilesService.FilesDeletePath, 'delete');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: '*/*',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
        }),
      );
  }

  /**
   * delete file
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `filesDelete$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  filesDelete(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    id: string;

    /**
     * object data
     */
    body: CommonObjectObjectIdReqDto;
  }): Observable<void> {
    return this.filesDelete$Response(params).pipe(map((r: StrictHttpResponse<void>) => r.body as void));
  }

  /**
   * Path part for operation filesCreateFolder
   */
  static readonly FilesCreateFolderPath = '/files/folder';

  /**
   * uploads files to files
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `filesCreateFolder()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  filesCreateFolder$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    body: FoldersManualCreateReqDto;
  }): Observable<StrictHttpResponse<FilesDbDto>> {
    const rb = new RequestBuilder(this.rootUrl, FilesService.FilesCreateFolderPath, 'post');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<FilesDbDto>;
        }),
      );
  }

  /**
   * uploads files to files
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `filesCreateFolder$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  filesCreateFolder(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    body: FoldersManualCreateReqDto;
  }): Observable<FilesDbDto> {
    return this.filesCreateFolder$Response(params).pipe(
      map((r: StrictHttpResponse<FilesDbDto>) => r.body as FilesDbDto),
    );
  }

  /**
   * Path part for operation filesGetFolder
   */
  static readonly FilesGetFolderPath = '/files/get-folder';

  /**
   * gets folder from files
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `filesGetFolder()` instead.
   *
   * This method doesn't expect any request body.
   */
  filesGetFolder$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    _id?: string;

    /**
     * tenants | spaces | projects
     */
    object: string;

    /**
     * tenants | spaces | projects id
     */
    objectId?: string;

    /**
     * folderId
     */
    parentId?: string;
  }): Observable<StrictHttpResponse<FoldersGetResDto>> {
    const rb = new RequestBuilder(this.rootUrl, FilesService.FilesGetFolderPath, 'get');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.query('_id', params['_id'], {});
      rb.query('object', params.object, {});
      rb.query('objectId', params.objectId, {});
      rb.query('parentId', params.parentId, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<FoldersGetResDto>;
        }),
      );
  }

  /**
   * gets folder from files
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `filesGetFolder$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  filesGetFolder(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    _id?: string;

    /**
     * tenants | spaces | projects
     */
    object: string;

    /**
     * tenants | spaces | projects id
     */
    objectId?: string;

    /**
     * folderId
     */
    parentId?: string;
  }): Observable<FoldersGetResDto> {
    return this.filesGetFolder$Response(params).pipe(
      map((r: StrictHttpResponse<FoldersGetResDto>) => r.body as FoldersGetResDto),
    );
  }

  /**
   * Path part for operation filesGetFolder_1
   */
  static readonly FilesGetFolder_1Path = '/files/get-all-folders';

  /**
   * gets folder from files
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `filesGetFolder_1()` instead.
   *
   * This method doesn't expect any request body.
   */
  filesGetFolder_1$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    object: string;
    objectId?: string;
  }): Observable<StrictHttpResponse<Array<FoldersGetAllResDto>>> {
    const rb = new RequestBuilder(this.rootUrl, FilesService.FilesGetFolder_1Path, 'get');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.query('object', params.object, {});
      rb.query('objectId', params.objectId, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<FoldersGetAllResDto>>;
        }),
      );
  }

  /**
   * gets folder from files
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `filesGetFolder_1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  filesGetFolder_1(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    object: string;
    objectId?: string;
  }): Observable<Array<FoldersGetAllResDto>> {
    return this.filesGetFolder_1$Response(params).pipe(
      map((r: StrictHttpResponse<Array<FoldersGetAllResDto>>) => r.body as Array<FoldersGetAllResDto>),
    );
  }

  /**
   * Path part for operation filesDeleteFolder
   */
  static readonly FilesDeleteFolderPath = '/files/folder/{id}';

  /**
   * delete folder
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `filesDeleteFolder()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  filesDeleteFolder$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    id: string;

    /**
     * object data
     */
    body: CommonObjectObjectIdReqDto;
  }): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, FilesService.FilesDeleteFolderPath, 'delete');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: '*/*',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
        }),
      );
  }

  /**
   * delete folder
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `filesDeleteFolder$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  filesDeleteFolder(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    id: string;

    /**
     * object data
     */
    body: CommonObjectObjectIdReqDto;
  }): Observable<void> {
    return this.filesDeleteFolder$Response(params).pipe(map((r: StrictHttpResponse<void>) => r.body as void));
  }

  /**
   * Path part for operation filesUpdateFolder
   */
  static readonly FilesUpdateFolderPath = '/files/folder/{id}';

  /**
   * updates folder and permissions
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `filesUpdateFolder()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  filesUpdateFolder$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    id: string;

    /**
     * files data
     */
    body: FoldersUpdateReqDto;
  }): Observable<StrictHttpResponse<FoldersDbDto>> {
    const rb = new RequestBuilder(this.rootUrl, FilesService.FilesUpdateFolderPath, 'patch');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<FoldersDbDto>;
        }),
      );
  }

  /**
   * updates folder and permissions
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `filesUpdateFolder$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  filesUpdateFolder(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    id: string;

    /**
     * files data
     */
    body: FoldersUpdateReqDto;
  }): Observable<FoldersDbDto> {
    return this.filesUpdateFolder$Response(params).pipe(
      map((r: StrictHttpResponse<FoldersDbDto>) => r.body as FoldersDbDto),
    );
  }

  /**
   * Path part for operation contentsUpload
   */
  static readonly ContentsUploadPath = '/files/upload-contents';

  /**
   * contents upload
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `contentsUpload()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  contentsUpload$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;

    /**
     * avatar | heroImage
     */
    type: 'avatar' | 'heroImage';

    /**
     * users | tenants | spaces | projects | webhooks
     */
    object: 'tenants' | 'spaces' | 'projects' | 'users' | 'webhooks';

    /**
     * objectId
     */
    objectId?: string;

    /**
     * caption
     */
    caption?: string;
    body: {
      file?: Blob;
    };
  }): Observable<StrictHttpResponse<ContentsDbDto>> {
    const rb = new RequestBuilder(this.rootUrl, FilesService.ContentsUploadPath, 'post');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.query('type', params.type, {});
      rb.query('object', params.object, {});
      rb.query('objectId', params.objectId, {});
      rb.query('caption', params.caption, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<ContentsDbDto>;
        }),
      );
  }

  /**
   * contents upload
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `contentsUpload$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  contentsUpload(params: {
    /**
     * Localization
     */
    'x-lang'?: string;

    /**
     * avatar | heroImage
     */
    type: 'avatar' | 'heroImage';

    /**
     * users | tenants | spaces | projects | webhooks
     */
    object: 'tenants' | 'spaces' | 'projects' | 'users' | 'webhooks';

    /**
     * objectId
     */
    objectId?: string;

    /**
     * caption
     */
    caption?: string;
    body: {
      file?: Blob;
    };
  }): Observable<ContentsDbDto> {
    return this.contentsUpload$Response(params).pipe(
      map((r: StrictHttpResponse<ContentsDbDto>) => r.body as ContentsDbDto),
    );
  }

  /**
   * Path part for operation contentsGet
   */
  static readonly ContentsGetPath = '/files/get-content';

  /**
   * gets content
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `contentsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  contentsGet$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;

    /**
     * type
     */
    type: string;

    /**
     * object
     */
    object: string;

    /**
     * objectId
     */
    objectId?: string;
  }): Observable<StrictHttpResponse<ContentsDbDto>> {
    const rb = new RequestBuilder(this.rootUrl, FilesService.ContentsGetPath, 'get');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.query('type', params.type, {});
      rb.query('object', params.object, {});
      rb.query('objectId', params.objectId, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<ContentsDbDto>;
        }),
      );
  }

  /**
   * gets content
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `contentsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  contentsGet(params: {
    /**
     * Localization
     */
    'x-lang'?: string;

    /**
     * type
     */
    type: string;

    /**
     * object
     */
    object: string;

    /**
     * objectId
     */
    objectId?: string;
  }): Observable<ContentsDbDto> {
    return this.contentsGet$Response(params).pipe(
      map((r: StrictHttpResponse<ContentsDbDto>) => r.body as ContentsDbDto),
    );
  }

  /**
   * Path part for operation contentsGetList
   */
  static readonly ContentsGetListPath = '/files/get-content-list';

  /**
   * gets content list
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `contentsGetList()` instead.
   *
   * This method doesn't expect any request body.
   */
  contentsGetList$Response(params?: {
    /**
     * Localization
     */
    'x-lang'?: string;

    /**
     * type
     */
    type?: string;

    /**
     * object
     */
    object?: string;

    /**
     * objectId
     */
    objectId?: string;
  }): Observable<StrictHttpResponse<CommonPaginationResDto>> {
    const rb = new RequestBuilder(this.rootUrl, FilesService.ContentsGetListPath, 'get');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.query('type', params.type, {});
      rb.query('object', params.object, {});
      rb.query('objectId', params.objectId, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<CommonPaginationResDto>;
        }),
      );
  }

  /**
   * gets content list
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `contentsGetList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  contentsGetList(params?: {
    /**
     * Localization
     */
    'x-lang'?: string;

    /**
     * type
     */
    type?: string;

    /**
     * object
     */
    object?: string;

    /**
     * objectId
     */
    objectId?: string;
  }): Observable<CommonPaginationResDto> {
    return this.contentsGetList$Response(params).pipe(
      map((r: StrictHttpResponse<CommonPaginationResDto>) => r.body as CommonPaginationResDto),
    );
  }

  /**
   * Path part for operation contentsDelete
   */
  static readonly ContentsDeletePath = '/files/delete-content';

  /**
   * delete content
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `contentsDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  contentsDelete$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;

    /**
     * type
     */
    type: string;

    /**
     * object
     */
    object: string;

    /**
     * objectId
     */
    objectId?: string;
  }): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, FilesService.ContentsDeletePath, 'delete');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.query('type', params.type, {});
      rb.query('object', params.object, {});
      rb.query('objectId', params.objectId, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: '*/*',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
        }),
      );
  }

  /**
   * delete content
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `contentsDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  contentsDelete(params: {
    /**
     * Localization
     */
    'x-lang'?: string;

    /**
     * type
     */
    type: string;

    /**
     * object
     */
    object: string;

    /**
     * objectId
     */
    objectId?: string;
  }): Observable<void> {
    return this.contentsDelete$Response(params).pipe(map((r: StrictHttpResponse<void>) => r.body as void));
  }

  /**
   * Path part for operation filesSaveFileToDataRoom
   */
  static readonly FilesSaveFileToDataRoomPath = '/files/save-file';

  /**
   * uploads files to files
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `filesSaveFileToDataRoom()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  filesSaveFileToDataRoom$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    body: IntersectionFilesSaveFileToDataRoomReqDtoCommonOptionalFileReqDto;
  }): Observable<StrictHttpResponse<FilesDbDto>> {
    const rb = new RequestBuilder(this.rootUrl, FilesService.FilesSaveFileToDataRoomPath, 'post');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<FilesDbDto>;
        }),
      );
  }

  /**
   * uploads files to files
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `filesSaveFileToDataRoom$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  filesSaveFileToDataRoom(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    body: IntersectionFilesSaveFileToDataRoomReqDtoCommonOptionalFileReqDto;
  }): Observable<FilesDbDto> {
    return this.filesSaveFileToDataRoom$Response(params).pipe(
      map((r: StrictHttpResponse<FilesDbDto>) => r.body as FilesDbDto),
    );
  }

  /**
   * Path part for operation documentPermissionsGet
   */
  static readonly DocumentPermissionsGetPath = '/files/document-permissions';

  /**
   * get document permissions
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentPermissionsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  documentPermissionsGet$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;

    /**
     * folderId | fileId
     */
    documentId: string;

    /**
     * file | folder
     */
    type: string;
  }): Observable<StrictHttpResponse<CommonUseridsAndRolesListsDto>> {
    const rb = new RequestBuilder(this.rootUrl, FilesService.DocumentPermissionsGetPath, 'get');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.query('documentId', params.documentId, {});
      rb.query('type', params.type, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<CommonUseridsAndRolesListsDto>;
        }),
      );
  }

  /**
   * get document permissions
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `documentPermissionsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  documentPermissionsGet(params: {
    /**
     * Localization
     */
    'x-lang'?: string;

    /**
     * folderId | fileId
     */
    documentId: string;

    /**
     * file | folder
     */
    type: string;
  }): Observable<CommonUseridsAndRolesListsDto> {
    return this.documentPermissionsGet$Response(params).pipe(
      map((r: StrictHttpResponse<CommonUseridsAndRolesListsDto>) => r.body as CommonUseridsAndRolesListsDto),
    );
  }

  /**
   * Path part for operation filesCreateLink
   */
  static readonly FilesCreateLinkPath = '/files/link';

  /**
   * create link
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `filesCreateLink()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  filesCreateLink$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    body: LinksCreateReqDto;
  }): Observable<StrictHttpResponse<LinksDbDto>> {
    const rb = new RequestBuilder(this.rootUrl, FilesService.FilesCreateLinkPath, 'post');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<LinksDbDto>;
        }),
      );
  }

  /**
   * create link
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `filesCreateLink$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  filesCreateLink(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    body: LinksCreateReqDto;
  }): Observable<LinksDbDto> {
    return this.filesCreateLink$Response(params).pipe(map((r: StrictHttpResponse<LinksDbDto>) => r.body as LinksDbDto));
  }

  /**
   * Path part for operation filesGetLinkById
   */
  static readonly FilesGetLinkByIdPath = '/files/link/{id}';

  /**
   * create link
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `filesGetLinkById()` instead.
   *
   * This method doesn't expect any request body.
   */
  filesGetLinkById$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    id: string;
  }): Observable<StrictHttpResponse<LinksDbDto>> {
    const rb = new RequestBuilder(this.rootUrl, FilesService.FilesGetLinkByIdPath, 'get');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.path('id', params.id, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<LinksDbDto>;
        }),
      );
  }

  /**
   * create link
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `filesGetLinkById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  filesGetLinkById(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    id: string;
  }): Observable<LinksDbDto> {
    return this.filesGetLinkById$Response(params).pipe(
      map((r: StrictHttpResponse<LinksDbDto>) => r.body as LinksDbDto),
    );
  }

  /**
   * Path part for operation filesDeleteLink
   */
  static readonly FilesDeleteLinkPath = '/files/link/{id}';

  /**
   * create link
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `filesDeleteLink()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  filesDeleteLink$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    id: string;

    /**
     * object data
     */
    body: CommonObjectObjectIdReqDto;
  }): Observable<StrictHttpResponse<CommonSuccessResDto>> {
    const rb = new RequestBuilder(this.rootUrl, FilesService.FilesDeleteLinkPath, 'delete');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<CommonSuccessResDto>;
        }),
      );
  }

  /**
   * create link
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `filesDeleteLink$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  filesDeleteLink(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    id: string;

    /**
     * object data
     */
    body: CommonObjectObjectIdReqDto;
  }): Observable<CommonSuccessResDto> {
    return this.filesDeleteLink$Response(params).pipe(
      map((r: StrictHttpResponse<CommonSuccessResDto>) => r.body as CommonSuccessResDto),
    );
  }

  /**
   * Path part for operation filesUpdateLink
   */
  static readonly FilesUpdateLinkPath = '/files/link/{id}';

  /**
   * create link
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `filesUpdateLink()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  filesUpdateLink$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    id: string;
    body: LinksUpdateReqDto;
  }): Observable<StrictHttpResponse<LinksDbDto>> {
    const rb = new RequestBuilder(this.rootUrl, FilesService.FilesUpdateLinkPath, 'patch');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<LinksDbDto>;
        }),
      );
  }

  /**
   * create link
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `filesUpdateLink$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  filesUpdateLink(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    id: string;
    body: LinksUpdateReqDto;
  }): Observable<LinksDbDto> {
    return this.filesUpdateLink$Response(params).pipe(map((r: StrictHttpResponse<LinksDbDto>) => r.body as LinksDbDto));
  }

  /**
   * Path part for operation filesCreateDocument
   */
  static readonly FilesCreateDocumentPath = '/files/document';

  /**
   * create document
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `filesCreateDocument()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  filesCreateDocument$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    body: DocumentsCreateReqDto;
  }): Observable<StrictHttpResponse<DocumentsDbDto>> {
    const rb = new RequestBuilder(this.rootUrl, FilesService.FilesCreateDocumentPath, 'post');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<DocumentsDbDto>;
        }),
      );
  }

  /**
   * create document
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `filesCreateDocument$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  filesCreateDocument(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    body: DocumentsCreateReqDto;
  }): Observable<DocumentsDbDto> {
    return this.filesCreateDocument$Response(params).pipe(
      map((r: StrictHttpResponse<DocumentsDbDto>) => r.body as DocumentsDbDto),
    );
  }

  /**
   * Path part for operation filesGetDocumentById
   */
  static readonly FilesGetDocumentByIdPath = '/files/document/{id}';

  /**
   * create document
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `filesGetDocumentById()` instead.
   *
   * This method doesn't expect any request body.
   */
  filesGetDocumentById$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    id: string;
  }): Observable<StrictHttpResponse<LinksDbDto>> {
    const rb = new RequestBuilder(this.rootUrl, FilesService.FilesGetDocumentByIdPath, 'get');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.path('id', params.id, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<LinksDbDto>;
        }),
      );
  }

  /**
   * create document
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `filesGetDocumentById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  filesGetDocumentById(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    id: string;
  }): Observable<LinksDbDto> {
    return this.filesGetDocumentById$Response(params).pipe(
      map((r: StrictHttpResponse<LinksDbDto>) => r.body as LinksDbDto),
    );
  }

  /**
   * Path part for operation filesDeleteDocument
   */
  static readonly FilesDeleteDocumentPath = '/files/document/{id}';

  /**
   * create document
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `filesDeleteDocument()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  filesDeleteDocument$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    id: string;

    /**
     * object data
     */
    body: CommonObjectObjectIdReqDto;
  }): Observable<StrictHttpResponse<CommonSuccessResDto>> {
    const rb = new RequestBuilder(this.rootUrl, FilesService.FilesDeleteDocumentPath, 'delete');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<CommonSuccessResDto>;
        }),
      );
  }

  /**
   * create document
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `filesDeleteDocument$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  filesDeleteDocument(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    id: string;

    /**
     * object data
     */
    body: CommonObjectObjectIdReqDto;
  }): Observable<CommonSuccessResDto> {
    return this.filesDeleteDocument$Response(params).pipe(
      map((r: StrictHttpResponse<CommonSuccessResDto>) => r.body as CommonSuccessResDto),
    );
  }

  /**
   * Path part for operation filesUpdateDocument
   */
  static readonly FilesUpdateDocumentPath = '/files/document/{id}';

  /**
   * create document
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `filesUpdateDocument()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  filesUpdateDocument$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    id: string;
    body: DocumentsUpdateReqDto;
  }): Observable<StrictHttpResponse<DocumentsDbDto>> {
    const rb = new RequestBuilder(this.rootUrl, FilesService.FilesUpdateDocumentPath, 'patch');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<DocumentsDbDto>;
        }),
      );
  }

  /**
   * create document
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `filesUpdateDocument$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  filesUpdateDocument(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    id: string;
    body: DocumentsUpdateReqDto;
  }): Observable<DocumentsDbDto> {
    return this.filesUpdateDocument$Response(params).pipe(
      map((r: StrictHttpResponse<DocumentsDbDto>) => r.body as DocumentsDbDto),
    );
  }
}
