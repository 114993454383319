export const separateFormatPicker = (format) => {
  return format.split(' ').reduce((acc, time, index, array) => {
    if (index === array.length - 1) {
      return time === 'A'
        ? [acc, `${array[array.length - 2]} ${time}`]
        : time.toLowerCase() === 'hh:mm'
          ? [acc, time]
          : [`${acc} ${time}`];
    } else if (time !== 'hh:mm') {
      return !acc ? time : `${acc} ${time}`;
    } else {
      return acc;
    }
  }, '');
};
