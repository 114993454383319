/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CommonSuccessResDto } from '../models/common-success-res-dto';
import { WikiPageCreateReqDto } from '../models/wiki-page-create-req-dto';
import { WikiPageDbDto } from '../models/wiki-page-db-dto';
import { WikiPageUpdateReqDto } from '../models/wiki-page-update-req-dto';
import { WikiPagesOrderUpdateReqDto } from '../models/wiki-pages-order-update-req-dto';

@Injectable({
  providedIn: 'root',
})
export class WikiPagesService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation wikiPagesGetList
   */
  static readonly WikiPagesGetListPath = '/wiki-pages';

  /**
   * get all wiki pages
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `wikiPagesGetList()` instead.
   *
   * This method doesn't expect any request body.
   */
  wikiPagesGetList$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    object: string;
    objectId?: string;
  }): Observable<StrictHttpResponse<Array<WikiPageDbDto>>> {
    const rb = new RequestBuilder(this.rootUrl, WikiPagesService.WikiPagesGetListPath, 'get');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.query('object', params.object, {});
      rb.query('objectId', params.objectId, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<WikiPageDbDto>>;
        }),
      );
  }

  /**
   * get all wiki pages
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `wikiPagesGetList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  wikiPagesGetList(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    object: string;
    objectId?: string;
  }): Observable<Array<WikiPageDbDto>> {
    return this.wikiPagesGetList$Response(params).pipe(
      map((r: StrictHttpResponse<Array<WikiPageDbDto>>) => r.body as Array<WikiPageDbDto>),
    );
  }

  /**
   * Path part for operation wikiPagesCreate
   */
  static readonly WikiPagesCreatePath = '/wiki-pages';

  /**
   * creates a wiki page
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `wikiPagesCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  wikiPagesCreate$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    body: WikiPageCreateReqDto;
  }): Observable<StrictHttpResponse<WikiPageDbDto>> {
    const rb = new RequestBuilder(this.rootUrl, WikiPagesService.WikiPagesCreatePath, 'post');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<WikiPageDbDto>;
        }),
      );
  }

  /**
   * creates a wiki page
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `wikiPagesCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  wikiPagesCreate(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    body: WikiPageCreateReqDto;
  }): Observable<WikiPageDbDto> {
    return this.wikiPagesCreate$Response(params).pipe(
      map((r: StrictHttpResponse<WikiPageDbDto>) => r.body as WikiPageDbDto),
    );
  }

  /**
   * Path part for operation wikiPagesOrderUpdate
   */
  static readonly WikiPagesOrderUpdatePath = '/wiki-pages/order';

  /**
   * update wiki pages order
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `wikiPagesOrderUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  wikiPagesOrderUpdate$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    body: WikiPagesOrderUpdateReqDto;
  }): Observable<StrictHttpResponse<CommonSuccessResDto>> {
    const rb = new RequestBuilder(this.rootUrl, WikiPagesService.WikiPagesOrderUpdatePath, 'patch');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<CommonSuccessResDto>;
        }),
      );
  }

  /**
   * update wiki pages order
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `wikiPagesOrderUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  wikiPagesOrderUpdate(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    body: WikiPagesOrderUpdateReqDto;
  }): Observable<CommonSuccessResDto> {
    return this.wikiPagesOrderUpdate$Response(params).pipe(
      map((r: StrictHttpResponse<CommonSuccessResDto>) => r.body as CommonSuccessResDto),
    );
  }

  /**
   * Path part for operation wikiPagesGetOne
   */
  static readonly WikiPagesGetOnePath = '/wiki-pages/{id}';

  /**
   * get a wiki page
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `wikiPagesGetOne()` instead.
   *
   * This method doesn't expect any request body.
   */
  wikiPagesGetOne$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    id: string;
    object: string;
    objectId?: string;
  }): Observable<StrictHttpResponse<WikiPageDbDto>> {
    const rb = new RequestBuilder(this.rootUrl, WikiPagesService.WikiPagesGetOnePath, 'get');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.path('id', params.id, {});
      rb.query('object', params.object, {});
      rb.query('objectId', params.objectId, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<WikiPageDbDto>;
        }),
      );
  }

  /**
   * get a wiki page
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `wikiPagesGetOne$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  wikiPagesGetOne(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    id: string;
    object: string;
    objectId?: string;
  }): Observable<WikiPageDbDto> {
    return this.wikiPagesGetOne$Response(params).pipe(
      map((r: StrictHttpResponse<WikiPageDbDto>) => r.body as WikiPageDbDto),
    );
  }

  /**
   * Path part for operation wikiPagesDelete
   */
  static readonly WikiPagesDeletePath = '/wiki-pages/{id}';

  /**
   * delete a wiki page
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `wikiPagesDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  wikiPagesDelete$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    id: string;
    object: string;
    objectId?: string;
  }): Observable<StrictHttpResponse<CommonSuccessResDto>> {
    const rb = new RequestBuilder(this.rootUrl, WikiPagesService.WikiPagesDeletePath, 'delete');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.path('id', params.id, {});
      rb.query('object', params.object, {});
      rb.query('objectId', params.objectId, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<CommonSuccessResDto>;
        }),
      );
  }

  /**
   * delete a wiki page
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `wikiPagesDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  wikiPagesDelete(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    id: string;
    object: string;
    objectId?: string;
  }): Observable<CommonSuccessResDto> {
    return this.wikiPagesDelete$Response(params).pipe(
      map((r: StrictHttpResponse<CommonSuccessResDto>) => r.body as CommonSuccessResDto),
    );
  }

  /**
   * Path part for operation wikiPagesUpdate
   */
  static readonly WikiPagesUpdatePath = '/wiki-pages/{id}';

  /**
   * update a wiki page
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `wikiPagesUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  wikiPagesUpdate$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    id: string;
    body: WikiPageUpdateReqDto;
  }): Observable<StrictHttpResponse<WikiPageDbDto>> {
    const rb = new RequestBuilder(this.rootUrl, WikiPagesService.WikiPagesUpdatePath, 'patch');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<WikiPageDbDto>;
        }),
      );
  }

  /**
   * update a wiki page
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `wikiPagesUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  wikiPagesUpdate(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    id: string;
    body: WikiPageUpdateReqDto;
  }): Observable<WikiPageDbDto> {
    return this.wikiPagesUpdate$Response(params).pipe(
      map((r: StrictHttpResponse<WikiPageDbDto>) => r.body as WikiPageDbDto),
    );
  }
}
