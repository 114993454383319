<div
  [ngClass]="{
    'lds-spinner-small': smallSize,
    'lds-spinner': !smallSize,
    'full-container': fullContainer
  }"
>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
</div>
