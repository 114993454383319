import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ConfigService, ITemplateConfig } from '../../../../shared/services/config.service';
import { AttributeEnum } from '../../../payment-modal/payment.const';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PaymentService } from '../../../payment-modal/payment.service';
import { Store } from '@ngxs/store';
import { SubscriptionsResDto } from '../../../../api/models/subscriptions-res-dto';
import { PaymentState } from '../../../../shared/store/states/payment.state';
import { BillingDetailsGet } from '../../../../shared/store/actions/payment.action';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { finalize } from 'rxjs/operators';
import { BillingDetailsDbDto } from '../../../../api/models/billing-details-db-dto';
import moment from 'moment';

import { BillingDetailsResDto } from '../../../../api/models/billing-details-res-dto';
import { ButtonComponent } from '../../../../shared/components/ui-elements/button/button.component';
import { NgIf } from '@angular/common';
import { TranslocoDirective } from '@ngneat/transloco';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-user-plan',
  templateUrl: './user-plan.component.html',
  styleUrls: ['./user-plan.component.scss'],
  standalone: true,
  imports: [TranslocoDirective, NgIf, ButtonComponent],
})
export class UserPlanComponent implements OnInit {
  public subscription: { plan: string; subscription: SubscriptionsResDto };
  public currentSubscription: string = AttributeEnum.Free;
  public isLoading = false;
  public billingDetail: BillingDetailsResDto;
  public data: string;
  private config: ITemplateConfig;

  constructor(
    private readonly configService: ConfigService,
    private readonly activeModal: NgbActiveModal,
    private readonly paymentService: PaymentService,
    private readonly store: Store,
    private readonly cdr: ChangeDetectorRef,
  ) {
    this.config = this.configService.templateConf;
  }

  ngOnInit() {
    this.initData();
  }

  public get isBasicSubscription(): boolean {
    return this.currentSubscription === AttributeEnum.Basic;
  }

  public get isEnterpriseSubscription(): boolean {
    return this.currentSubscription === AttributeEnum.Enterprise;
  }

  public get isFreeSubscription(): boolean {
    return this.currentSubscription === AttributeEnum.Free;
  }

  public get subscriptionTitle(): string {
    return this.isBasicSubscription
      ? 'Basic'
      : this.isEnterpriseSubscription
        ? 'Enterprise'
        : 'Free';
  }

  public get isDark(): boolean {
    return this.config?.layout.variant === 'Dark';
  }

  public showModal(): void {
    this.activeModal.close();
    this.paymentService.openPaymentModal();
  }

  public setData(): string {
    if (this.subscription && this.subscription.subscription !== null) {
      return moment(this.subscription.subscription.expiredAt).format('DD.MM.YYYY');
    }
    return 'No subscription';
  }

  public showEditModal(): void {
    this.paymentService.openEditModal(this.billingDetail);
  }

  private initData(): void {
    this.subscription = this.store.selectSnapshot(PaymentState.getSubscription);
    this.currentSubscription = this.subscription.plan;
    this.data = this.setData();

    if (!this.isFreeSubscription) {
      this.store
        .select(PaymentState.getBillingDetails)
        .pipe(untilDestroyed(this))
        .subscribe((value) => {
          this.billingDetail = {
            _id: value._id,
            address: value.address,
            billingEmail: value.billingEmail,
            city: value.city,
            companyName: value.companyName,
            contactId: value.contactId,
            countryId: value.countryId,
            countryName: 'countryName' in value ? value.countryName : value.country,
            firstName: value.firstName,
            lastName: value.lastName,
            postalCode: value.postalCode,
            vatId: value.vatId,
          };
          this.cdr.detectChanges();
        });

      if (this.billingDetail) return;
      this.isLoading = true;
      this.store
        .dispatch(new BillingDetailsGet())
        .pipe(
          untilDestroyed(this),
          finalize(() => (this.isLoading = false)),
        )
        .subscribe();
    }
  }
}
