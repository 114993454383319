import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { AuthState } from '../../shared/store/states/auth.state';
import { Store } from '@ngxs/store';
import { AsyncPipe } from '@angular/common';
import { ButtonComponent } from '../../shared/components/ui-elements/button/button.component';
import { InputTextareaComponent } from '../../shared/components/ui-elements/Fields/input-textarea/input-textarea.component';
import { InputSelectComponent } from '../../shared/components/ui-elements/Fields/input-select/input-select.component';
import { InputTextComponent } from '../../shared/components/ui-elements/Fields/input-text/input-text.component';
import { SvgIconComponent } from 'angular-svg-icon';
import { TranslocoDirective } from '@ngneat/transloco';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss'],
  standalone: true,
  imports: [
    TranslocoDirective,
    SvgIconComponent,
    FormsModule,
    ReactiveFormsModule,
    InputTextComponent,
    InputSelectComponent,
    InputTextareaComponent,
    ButtonComponent,
    AsyncPipe,
  ],
})
export class ContactUsComponent {
  public departmentItems = [];
  public numberItems = [];
  public platform = this.store.select(AuthState.getPlatform);
  public contactUsForm: FormGroup = this.fb.group({
    first_name: ['', Validators.required],
    last_name: ['', Validators.required],
    email: ['', Validators.required],
    phone: ['', Validators.required],
    company: ['', Validators.required],
    department: ['', Validators.required],
    number: ['', Validators.required],
    more: [''],
  });

  // SVG PATH
  public readonly closeSvg = 'assets/icons/common/close.svg';

  constructor(
    private activeModal: NgbActiveModal,
    private readonly fb: FormBuilder,
    private readonly store: Store,
  ) {}

  public handleCloseModal(): void {
    this.activeModal.close();
  }

  public handleSubmit(): void {
    // TODO: Integrate with BE
  }
}
