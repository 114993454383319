/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ActionsLogsPaginationRes } from '../models/actions-logs-pagination-res';

@Injectable({
  providedIn: 'root',
})
export class ActionsLogsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation actionsLogGetList
   */
  static readonly ActionsLogGetListPath = '/actions-logs';

  /**
   * get actions-logs list
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `actionsLogGetList()` instead.
   *
   * This method doesn't expect any request body.
   */
  actionsLogGetList$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    object: string;
    objectId?: string;

    /**
     * Search string
     */
    search?: string;

    /**
     * Search key | keys
     */
    searchBy?: string;

    /**
     * Sorting direction
     */
    sort?: string;

    /**
     * Sorting by field
     */
    sortBy?: string;

    /**
     * Message id
     */
    messageId?: string;

    /**
     * Limit resulting elements(default *10*).
     */
    perPage?: number;

    /**
     * Number of current page.(default *1*).
     */
    page?: number;
  }): Observable<StrictHttpResponse<ActionsLogsPaginationRes>> {
    const rb = new RequestBuilder(this.rootUrl, ActionsLogsService.ActionsLogGetListPath, 'get');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.query('object', params.object, {});
      rb.query('objectId', params.objectId, {});
      rb.query('search', params.search, {});
      rb.query('searchBy', params.searchBy, {});
      rb.query('sort', params.sort, {});
      rb.query('sortBy', params.sortBy, {});
      rb.query('messageId', params.messageId, {});
      rb.query('perPage', params.perPage, {});
      rb.query('page', params.page, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<ActionsLogsPaginationRes>;
        }),
      );
  }

  /**
   * get actions-logs list
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `actionsLogGetList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  actionsLogGetList(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    object: string;
    objectId?: string;

    /**
     * Search string
     */
    search?: string;

    /**
     * Search key | keys
     */
    searchBy?: string;

    /**
     * Sorting direction
     */
    sort?: string;

    /**
     * Sorting by field
     */
    sortBy?: string;

    /**
     * Message id
     */
    messageId?: string;

    /**
     * Limit resulting elements(default *10*).
     */
    perPage?: number;

    /**
     * Number of current page.(default *1*).
     */
    page?: number;
  }): Observable<ActionsLogsPaginationRes> {
    return this.actionsLogGetList$Response(params).pipe(
      map((r: StrictHttpResponse<ActionsLogsPaginationRes>) => r.body as ActionsLogsPaginationRes),
    );
  }
}
