import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { Router } from '@angular/router';

import { environment } from '../../../environments/environment';
import { AuthState } from '../store/states/auth.state';
import { TenantsState } from '../store/states/tenants.state';

@Injectable({
  providedIn: 'root',
})
export class UrlHelper {
  constructor(
    private readonly store: Store,
    private router: Router,
  ) {}

  setHttp(link) {
    if (link.search(/^http[s]?\:\/\//) === -1) {
      link = 'http://' + link;
    }
    return link;
  }

  getCorrectOrigin() {
    return `${window.location.origin}/${environment.subdomains ? '' : 'root/'}`;
  }

  getHost() {
    const env = this.store.selectSnapshot(AuthState.getEnv);
    const tenantName = this.store.selectSnapshot(TenantsState.getTenantName);
    return (
      `http${env?.ssl ? 's' : ''}://` +
      (env?.main_host || env?.base_host !== 'localhost:4200'
        ? `${tenantName}.${env?.main_host}`
        : env?.base_host)
    );
  }

  getFullCurrentUrl() {
    const host = this.getHost();
    return host + this.router.url;
  }

  getIdsFromTextMessage(text: string, separator: string): string[] {
    const currentUser = this.store.selectSnapshot(AuthState.getUser);
    const isValidObjectId = (id) => /^[0-9a-fA-F]{24}$/.test(id);
    const condition =
      text?.includes('http') &&
      text?.includes(separator) &&
      text?.includes(currentUser?.tenantName);
    if (condition) {
      const partsOfUrl = text.split(separator);
      const ids = partsOfUrl.reduce((acc, partOfUrl) => {
        const id = partOfUrl.slice(0, 24);
        if (isValidObjectId(id)) {
          return [...acc, id];
        }
        return acc;
      }, []);

      return [...new Set(ids)];
    }
  }
}
