import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, FormsModule } from '@angular/forms';
import { ConfigService, ITemplateConfig } from '../../../../services/config.service';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgIf } from '@angular/common';

@Component({
  selector: 'app-input-selector',
  templateUrl: './input-select.component.html',
  styleUrls: ['./input-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputSelectComponent),
      multi: true,
    },
  ],
  standalone: true,
  imports: [NgIf, NgSelectModule, FormsModule],
})
export class InputSelectComponent implements ControlValueAccessor {
  @Input() items;
  @Input() bindValue: string;
  @Input() bindLabel: string;
  @Input() placeHolder: string = 'Select item';
  @Input() legend: string;
  @Input() customClasses: string;
  @Input() widthFull: boolean = false;

  public _value: any;
  private config: ITemplateConfig;

  constructor(private readonly configService: ConfigService) {
    this.config = this.configService.templateConf;
  }

  public get isDark(): boolean {
    return this.config?.layout.variant === 'Dark';
  }

  // ControlValueAccessor
  public onChange: (value: any) => void;
  public onTouched: () => void;

  writeValue(value: any): void {
    this._value = value;
  }

  registerOnChange(fn: (value: any) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void) {
    this.onTouched = fn;
  }
}
