import { Component, Input } from '@angular/core';
import { NgClass } from '@angular/common';

@Component({
  selector: 'app-custom-spinner',
  templateUrl: './custom-spinner.component.html',
  styleUrls: ['./custom-spinner.component.scss'],
  standalone: true,
  imports: [NgClass],
})
export class CustomSpinnerComponent {
  @Input() fullContainer: boolean;
  @Input() smallSize: boolean;
}
