/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CheckListItemsDbDto } from '../models/check-list-items-db-dto';
import { CheckListItemsReqDto } from '../models/check-list-items-req-dto';
import { CheckListItemsUpdateOrderReqDto } from '../models/check-list-items-update-order-req-dto';
import { CheckListItemsUpdateReqDto } from '../models/check-list-items-update-req-dto';
import { IntersectionTicketsCreateReqDtoCommonOptionalFilesArrReqDto } from '../models/intersection-tickets-create-req-dto-common-optional-files-arr-req-dto';
import { IntersectionTicketsUpdateReqDtoCommonOptionalFilesArrReqDto } from '../models/intersection-tickets-update-req-dto-common-optional-files-arr-req-dto';
import { TicketsDbDto } from '../models/tickets-db-dto';
import { TicketsGetListResDto } from '../models/tickets-get-list-res-dto';
import { TicketsGetOneResDto } from '../models/tickets-get-one-res-dto';
import { TicketsMoveReqDto } from '../models/tickets-move-req-dto';
import { TicketsOrderUpdateReqDto } from '../models/tickets-order-update-req-dto';

@Injectable({
  providedIn: 'root',
})
export class BoardsTicketsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation checkListItemsCreate
   */
  static readonly CheckListItemsCreatePath = '/boards/tickets/check-list-items';

  /**
   * create checkListItem
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `checkListItemsCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  checkListItemsCreate$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    body: CheckListItemsReqDto;
  }): Observable<StrictHttpResponse<CheckListItemsDbDto>> {
    const rb = new RequestBuilder(this.rootUrl, BoardsTicketsService.CheckListItemsCreatePath, 'post');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<CheckListItemsDbDto>;
        }),
      );
  }

  /**
   * create checkListItem
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `checkListItemsCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  checkListItemsCreate(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    body: CheckListItemsReqDto;
  }): Observable<CheckListItemsDbDto> {
    return this.checkListItemsCreate$Response(params).pipe(
      map((r: StrictHttpResponse<CheckListItemsDbDto>) => r.body as CheckListItemsDbDto),
    );
  }

  /**
   * Path part for operation checkListItemsGetList
   */
  static readonly CheckListItemsGetListPath = '/boards/tickets/{id}/check-list-items';

  /**
   * get checkListItems for ticket
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `checkListItemsGetList()` instead.
   *
   * This method doesn't expect any request body.
   */
  checkListItemsGetList$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;

    /**
     * ticketId
     */
    id: string;
  }): Observable<StrictHttpResponse<Array<CheckListItemsDbDto>>> {
    const rb = new RequestBuilder(this.rootUrl, BoardsTicketsService.CheckListItemsGetListPath, 'get');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.path('id', params.id, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<CheckListItemsDbDto>>;
        }),
      );
  }

  /**
   * get checkListItems for ticket
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `checkListItemsGetList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  checkListItemsGetList(params: {
    /**
     * Localization
     */
    'x-lang'?: string;

    /**
     * ticketId
     */
    id: string;
  }): Observable<Array<CheckListItemsDbDto>> {
    return this.checkListItemsGetList$Response(params).pipe(
      map((r: StrictHttpResponse<Array<CheckListItemsDbDto>>) => r.body as Array<CheckListItemsDbDto>),
    );
  }

  /**
   * Path part for operation checkListItemsDeleteList
   */
  static readonly CheckListItemsDeleteListPath = '/boards/tickets/{id}/check-list-items';

  /**
   * delete deleteTicketCheckListItems
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `checkListItemsDeleteList()` instead.
   *
   * This method doesn't expect any request body.
   */
  checkListItemsDeleteList$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;

    /**
     * ticketId
     */
    id: string;
  }): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, BoardsTicketsService.CheckListItemsDeleteListPath, 'delete');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.path('id', params.id, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: '*/*',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
        }),
      );
  }

  /**
   * delete deleteTicketCheckListItems
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `checkListItemsDeleteList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  checkListItemsDeleteList(params: {
    /**
     * Localization
     */
    'x-lang'?: string;

    /**
     * ticketId
     */
    id: string;
  }): Observable<void> {
    return this.checkListItemsDeleteList$Response(params).pipe(map((r: StrictHttpResponse<void>) => r.body as void));
  }

  /**
   * Path part for operation checkListItemsGetOne
   */
  static readonly CheckListItemsGetOnePath = '/boards/tickets/check-list-items/{id}';

  /**
   * get checkListItem by id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `checkListItemsGetOne()` instead.
   *
   * This method doesn't expect any request body.
   */
  checkListItemsGetOne$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    id: string;
  }): Observable<StrictHttpResponse<CheckListItemsDbDto>> {
    const rb = new RequestBuilder(this.rootUrl, BoardsTicketsService.CheckListItemsGetOnePath, 'get');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.path('id', params.id, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<CheckListItemsDbDto>;
        }),
      );
  }

  /**
   * get checkListItem by id
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `checkListItemsGetOne$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  checkListItemsGetOne(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    id: string;
  }): Observable<CheckListItemsDbDto> {
    return this.checkListItemsGetOne$Response(params).pipe(
      map((r: StrictHttpResponse<CheckListItemsDbDto>) => r.body as CheckListItemsDbDto),
    );
  }

  /**
   * Path part for operation checkListItemsDeleteOne
   */
  static readonly CheckListItemsDeleteOnePath = '/boards/tickets/check-list-items/{id}';

  /**
   * delete checkListItem
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `checkListItemsDeleteOne()` instead.
   *
   * This method doesn't expect any request body.
   */
  checkListItemsDeleteOne$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    id: string;
  }): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, BoardsTicketsService.CheckListItemsDeleteOnePath, 'delete');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.path('id', params.id, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: '*/*',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
        }),
      );
  }

  /**
   * delete checkListItem
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `checkListItemsDeleteOne$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  checkListItemsDeleteOne(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    id: string;
  }): Observable<void> {
    return this.checkListItemsDeleteOne$Response(params).pipe(map((r: StrictHttpResponse<void>) => r.body as void));
  }

  /**
   * Path part for operation checkListItemsUpdate
   */
  static readonly CheckListItemsUpdatePath = '/boards/tickets/check-list-items/{id}';

  /**
   * update checkListItem
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `checkListItemsUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  checkListItemsUpdate$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    id: string;
    body: CheckListItemsUpdateReqDto;
  }): Observable<StrictHttpResponse<CheckListItemsDbDto>> {
    const rb = new RequestBuilder(this.rootUrl, BoardsTicketsService.CheckListItemsUpdatePath, 'patch');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<CheckListItemsDbDto>;
        }),
      );
  }

  /**
   * update checkListItem
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `checkListItemsUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  checkListItemsUpdate(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    id: string;
    body: CheckListItemsUpdateReqDto;
  }): Observable<CheckListItemsDbDto> {
    return this.checkListItemsUpdate$Response(params).pipe(
      map((r: StrictHttpResponse<CheckListItemsDbDto>) => r.body as CheckListItemsDbDto),
    );
  }

  /**
   * Path part for operation checkListItemsUpdateOrder
   */
  static readonly CheckListItemsUpdateOrderPath = '/boards/tickets/check-list-items/{id}/update-order';

  /**
   * checkListItem update order
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `checkListItemsUpdateOrder()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  checkListItemsUpdateOrder$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    id: string;
    body: CheckListItemsUpdateOrderReqDto;
  }): Observable<StrictHttpResponse<CheckListItemsDbDto>> {
    const rb = new RequestBuilder(this.rootUrl, BoardsTicketsService.CheckListItemsUpdateOrderPath, 'patch');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<CheckListItemsDbDto>;
        }),
      );
  }

  /**
   * checkListItem update order
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `checkListItemsUpdateOrder$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  checkListItemsControllerCheckListItemUpdateOrder(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    id: string;
    body: CheckListItemsUpdateOrderReqDto;
  }): Observable<CheckListItemsDbDto> {
    return this.checkListItemsUpdateOrder$Response(params).pipe(
      map((r: StrictHttpResponse<CheckListItemsDbDto>) => r.body as CheckListItemsDbDto),
    );
  }

  /**
   * Path part for operation ticketsGetList
   */
  static readonly TicketsGetListPath = '/boards/tickets';

  /**
   * get tickets list
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ticketsGetList()` instead.
   *
   * This method doesn't expect any request body.
   */
  ticketsGetList$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;

    /**
     * Search string
     */
    search?: string;

    /**
     * Search key | keys
     */
    searchBy?: string;

    /**
     * Sorting direction
     */
    sort?: string;

    /**
     * Sorting by field
     */
    sortBy?: string;

    /**
     * Message id
     */
    messageId?: string;

    /**
     * Limit resulting elements(default *10*).
     */
    perPage?: number;

    /**
     * Number of current page.(default *1*).
     */
    page?: number;

    /**
     * spaces | projects
     */
    object: string;

    /**
     * objectId
     */
    objectId: string;

    /**
     * chat | dataRoom
     */
    short?: string;

    /**
     * label id list for ticket
     */
    labels?: Array<string>;
    isArchive?: boolean;
  }): Observable<StrictHttpResponse<TicketsGetListResDto>> {
    const rb = new RequestBuilder(this.rootUrl, BoardsTicketsService.TicketsGetListPath, 'get');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.query('search', params.search, {});
      rb.query('searchBy', params.searchBy, {});
      rb.query('sort', params.sort, {});
      rb.query('sortBy', params.sortBy, {});
      rb.query('messageId', params.messageId, {});
      rb.query('perPage', params.perPage, {});
      rb.query('page', params.page, {});
      rb.query('object', params.object, {});
      rb.query('objectId', params.objectId, {});
      rb.query('short', params.short, {});
      rb.query('labels', params.labels, {});
      rb.query('isArchive', params.isArchive, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<TicketsGetListResDto>;
        }),
      );
  }

  /**
   * get tickets list
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `ticketsGetList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  ticketsGetList(params: {
    /**
     * Localization
     */
    'x-lang'?: string;

    /**
     * Search string
     */
    search?: string;

    /**
     * Search key | keys
     */
    searchBy?: string;

    /**
     * Sorting direction
     */
    sort?: string;

    /**
     * Sorting by field
     */
    sortBy?: string;

    /**
     * Message id
     */
    messageId?: string;

    /**
     * Limit resulting elements(default *10*).
     */
    perPage?: number;

    /**
     * Number of current page.(default *1*).
     */
    page?: number;

    /**
     * spaces | projects
     */
    object: string;

    /**
     * objectId
     */
    objectId: string;

    /**
     * chat | dataRoom
     */
    short?: string;

    /**
     * label id list for ticket
     */
    labels?: Array<string>;
    isArchive?: boolean;
  }): Observable<TicketsGetListResDto> {
    return this.ticketsGetList$Response(params).pipe(
      map((r: StrictHttpResponse<TicketsGetListResDto>) => r.body as TicketsGetListResDto),
    );
  }

  /**
   * Path part for operation ticketsCreate
   */
  static readonly TicketsCreatePath = '/boards/tickets';

  /**
   * create ticket
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ticketsCreate()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  ticketsCreate$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    body: IntersectionTicketsCreateReqDtoCommonOptionalFilesArrReqDto;
  }): Observable<StrictHttpResponse<TicketsDbDto>> {
    const rb = new RequestBuilder(this.rootUrl, BoardsTicketsService.TicketsCreatePath, 'post');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<TicketsDbDto>;
        }),
      );
  }

  /**
   * create ticket
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `ticketsCreate$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  ticketsCreate(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    body: IntersectionTicketsCreateReqDtoCommonOptionalFilesArrReqDto;
  }): Observable<TicketsDbDto> {
    return this.ticketsCreate$Response(params).pipe(
      map((r: StrictHttpResponse<TicketsDbDto>) => r.body as TicketsDbDto),
    );
  }

  /**
   * Path part for operation ticketsControllerTicketsOrderUpdate
   */
  static readonly TicketsControllerTicketsOrderUpdatePath = '/boards/tickets/order';

  /**
   * update tickets order
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ticketsControllerTicketsOrderUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ticketsControllerTicketsOrderUpdate$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    body: TicketsOrderUpdateReqDto;
  }): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, BoardsTicketsService.TicketsControllerTicketsOrderUpdatePath, 'patch');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: '*/*',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
        }),
      );
  }

  /**
   * update tickets order
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `ticketsControllerTicketsOrderUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ticketsControllerTicketsOrderUpdate(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    body: TicketsOrderUpdateReqDto;
  }): Observable<void> {
    return this.ticketsControllerTicketsOrderUpdate$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void),
    );
  }

  /**
   * Path part for operation ticketsGetOne
   */
  static readonly TicketsGetOnePath = '/boards/tickets/{id}';

  /**
   * get ticket object
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ticketsGetOne()` instead.
   *
   * This method doesn't expect any request body.
   */
  ticketsGetOne$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    id: string;
  }): Observable<StrictHttpResponse<TicketsGetOneResDto>> {
    const rb = new RequestBuilder(this.rootUrl, BoardsTicketsService.TicketsGetOnePath, 'get');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.path('id', params.id, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<TicketsGetOneResDto>;
        }),
      );
  }

  /**
   * get ticket object
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `ticketsGetOne$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  ticketsGetOne(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    id: string;
  }): Observable<TicketsGetOneResDto> {
    return this.ticketsGetOne$Response(params).pipe(
      map((r: StrictHttpResponse<TicketsGetOneResDto>) => r.body as TicketsGetOneResDto),
    );
  }

  /**
   * Path part for operation ticketsDelete
   */
  static readonly TicketsDeletePath = '/boards/tickets/{id}';

  /**
   * soft delete tickets
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ticketsDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  ticketsDelete$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    id: string;
  }): Observable<StrictHttpResponse<TicketsDbDto>> {
    const rb = new RequestBuilder(this.rootUrl, BoardsTicketsService.TicketsDeletePath, 'delete');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.path('id', params.id, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<TicketsDbDto>;
        }),
      );
  }

  /**
   * soft delete tickets
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `ticketsDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  ticketsDelete(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    id: string;
  }): Observable<TicketsDbDto> {
    return this.ticketsDelete$Response(params).pipe(
      map((r: StrictHttpResponse<TicketsDbDto>) => r.body as TicketsDbDto),
    );
  }

  /**
   * Path part for operation ticketsUpdate
   */
  static readonly TicketsUpdatePath = '/boards/tickets/{id}';

  /**
   * update tickets
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ticketsUpdate()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  ticketsUpdate$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    id: string;
    body: IntersectionTicketsUpdateReqDtoCommonOptionalFilesArrReqDto;
  }): Observable<StrictHttpResponse<TicketsDbDto>> {
    const rb = new RequestBuilder(this.rootUrl, BoardsTicketsService.TicketsUpdatePath, 'patch');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.path('id', params.id, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<TicketsDbDto>;
        }),
      );
  }

  /**
   * update tickets
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `ticketsUpdate$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  ticketsUpdate(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    id: string;
    body: IntersectionTicketsUpdateReqDtoCommonOptionalFilesArrReqDto;
  }): Observable<TicketsDbDto> {
    return this.ticketsUpdate$Response(params).pipe(
      map((r: StrictHttpResponse<TicketsDbDto>) => r.body as TicketsDbDto),
    );
  }

  /**
   * Path part for operation ticketsControllerTicketsMove
   */
  static readonly TicketsControllerTicketsMovePath = '/boards/tickets/move';

  /**
   * move tickets
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ticketsControllerTicketsMove()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ticketsControllerTicketsMove$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    body: TicketsMoveReqDto;
  }): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, BoardsTicketsService.TicketsControllerTicketsMovePath, 'post');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: '*/*',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
        }),
      );
  }

  /**
   * move tickets
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `ticketsControllerTicketsMove$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ticketsControllerTicketsMove(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    body: TicketsMoveReqDto;
  }): Observable<void> {
    return this.ticketsControllerTicketsMove$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void),
    );
  }
}
