<div class="custom-textarea" [class.w-full]="widthFull" [class.dark]="isDark">
  <p *ngIf="legend">{{ legend }}</p>
  <textarea
    #textareaRef
    [value]="value"
    (input)="onChange(textareaRef.value)"
    [rows]="rows"
    [placeholder]="placeHolder"
    [ngClass]="{
      customClasses: true,
      'w-full': widthFull,
      'resize-none': !isResize
    }"
  ></textarea>
</div>
