/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AuthLoginResDto } from '../models/auth-login-res-dto';
import { AuthPasswordReqDto } from '../models/auth-password-req-dto';
import { CommonConfirmInviteReqDto } from '../models/common-confirm-invite-req-dto';
import { CommonGetListByObjectIdResDto } from '../models/common-get-list-by-object-id-res-dto';
import { CommonInviteResDto } from '../models/common-invite-res-dto';
import { CommonSuccessResDto } from '../models/common-success-res-dto';
import { CommonUpdateResDto } from '../models/common-update-res-dto';
import { SpacesCreateReqDto } from '../models/spaces-create-req-dto';
import { SpacesDbDto } from '../models/spaces-db-dto';
import { SpacesGetListByUserIdDbDtoType } from '../models/spaces-get-list-by-user-id-db-dto-type';
import { SpacesGetListResDto } from '../models/spaces-get-list-res-dto';
import { SpacesMembersUpdateOrderDto } from '../models/spaces-members-update-order-dto';
import { SpacesOrderUpdateReqDto } from '../models/spaces-order-update-req-dto';
import { SpacesSpaceInviteReqDto } from '../models/spaces-space-invite-req-dto';
import { SpacesUpdateReqDto } from '../models/spaces-update-req-dto';
import { SpacesValidateInviteTokenResDto } from '../models/spaces-validate-invite-token-res-dto';
import { UsersDbDto } from '../models/users-db-dto';

@Injectable({
  providedIn: 'root',
})
export class SpacesService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation spacesGetList
   */
  static readonly SpacesGetListPath = '/spaces';

  /**
   * get spaces list
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `spacesGetList()` instead.
   *
   * This method doesn't expect any request body.
   */
  spacesGetList$Response(params?: {
    /**
     * Localization
     */
    'x-lang'?: string;

    /**
     * Search string
     */
    search?: string;

    /**
     * Search key | keys
     */
    searchBy?: string;

    /**
     * Sorting direction
     */
    sort?: string;

    /**
     * Sorting by field
     */
    sortBy?: string;

    /**
     * Message id
     */
    messageId?: string;

    /**
     * Limit resulting elements(default *10*).
     */
    perPage?: number;

    /**
     * Number of current page.(default *1*).
     */
    page?: number;
  }): Observable<StrictHttpResponse<SpacesGetListResDto>> {
    const rb = new RequestBuilder(this.rootUrl, SpacesService.SpacesGetListPath, 'get');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.query('search', params.search, {});
      rb.query('searchBy', params.searchBy, {});
      rb.query('sort', params.sort, {});
      rb.query('sortBy', params.sortBy, {});
      rb.query('messageId', params.messageId, {});
      rb.query('perPage', params.perPage, {});
      rb.query('page', params.page, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<SpacesGetListResDto>;
        }),
      );
  }

  /**
   * get spaces list
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `spacesGetList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  spacesGetList(params?: {
    /**
     * Localization
     */
    'x-lang'?: string;

    /**
     * Search string
     */
    search?: string;

    /**
     * Search key | keys
     */
    searchBy?: string;

    /**
     * Sorting direction
     */
    sort?: string;

    /**
     * Sorting by field
     */
    sortBy?: string;

    /**
     * Message id
     */
    messageId?: string;

    /**
     * Limit resulting elements(default *10*).
     */
    perPage?: number;

    /**
     * Number of current page.(default *1*).
     */
    page?: number;
  }): Observable<SpacesGetListResDto> {
    return this.spacesGetList$Response(params).pipe(
      map((r: StrictHttpResponse<SpacesGetListResDto>) => r.body as SpacesGetListResDto),
    );
  }

  /**
   * Path part for operation spacesCreate
   */
  static readonly SpacesCreatePath = '/spaces';

  /**
   * create spaces
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `spacesCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  spacesCreate$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    body: SpacesCreateReqDto;
  }): Observable<StrictHttpResponse<SpacesDbDto>> {
    const rb = new RequestBuilder(this.rootUrl, SpacesService.SpacesCreatePath, 'post');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<SpacesDbDto>;
        }),
      );
  }

  /**
   * create spaces
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `spacesCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  spacesCreate(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    body: SpacesCreateReqDto;
  }): Observable<SpacesDbDto> {
    return this.spacesCreate$Response(params).pipe(map((r: StrictHttpResponse<SpacesDbDto>) => r.body as SpacesDbDto));
  }

  /**
   * Path part for operation spacesOrderUpdate
   */
  static readonly SpacesOrderUpdatePath = '/spaces/order';

  /**
   * update spaces order
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `spacesOrderUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  spacesOrderUpdate$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    body: SpacesOrderUpdateReqDto;
  }): Observable<StrictHttpResponse<CommonSuccessResDto>> {
    const rb = new RequestBuilder(this.rootUrl, SpacesService.SpacesOrderUpdatePath, 'patch');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<CommonSuccessResDto>;
        }),
      );
  }

  /**
   * update spaces order
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `spacesOrderUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  spacesOrderUpdate(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    body: SpacesOrderUpdateReqDto;
  }): Observable<CommonSuccessResDto> {
    return this.spacesOrderUpdate$Response(params).pipe(
      map((r: StrictHttpResponse<CommonSuccessResDto>) => r.body as CommonSuccessResDto),
    );
  }

  /**
   * Path part for operation spacesDelete
   */
  static readonly SpacesDeletePath = '/spaces/{id}';

  /**
   * delete spaces
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `spacesDelete()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  spacesDelete$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    id: string;
    body: AuthPasswordReqDto;
  }): Observable<StrictHttpResponse<CommonUpdateResDto>> {
    const rb = new RequestBuilder(this.rootUrl, SpacesService.SpacesDeletePath, 'delete');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<CommonUpdateResDto>;
        }),
      );
  }

  /**
   * delete spaces
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `spacesDelete$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  spacesDelete(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    id: string;
    body: AuthPasswordReqDto;
  }): Observable<CommonUpdateResDto> {
    return this.spacesDelete$Response(params).pipe(
      map((r: StrictHttpResponse<CommonUpdateResDto>) => r.body as CommonUpdateResDto),
    );
  }

  /**
   * Path part for operation spacesUpdate
   */
  static readonly SpacesUpdatePath = '/spaces/{id}';

  /**
   * update spaces
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `spacesUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  spacesUpdate$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    id: string;
    body: SpacesUpdateReqDto;
  }): Observable<StrictHttpResponse<CommonSuccessResDto>> {
    const rb = new RequestBuilder(this.rootUrl, SpacesService.SpacesUpdatePath, 'patch');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<CommonSuccessResDto>;
        }),
      );
  }

  /**
   * update spaces
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `spacesUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  spacesUpdate(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    id: string;
    body: SpacesUpdateReqDto;
  }): Observable<CommonSuccessResDto> {
    return this.spacesUpdate$Response(params).pipe(
      map((r: StrictHttpResponse<CommonSuccessResDto>) => r.body as CommonSuccessResDto),
    );
  }

  /**
   * Path part for operation spacesControllerPersonalSpacesUpdate
   */
  static readonly SpacesControllerPersonalSpacesUpdatePath = '/spaces/{id}/personal';

  /**
   * update personal spaces
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `spacesControllerPersonalSpacesUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  spacesControllerPersonalSpacesUpdate$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    id: string;
    body: SpacesUpdateReqDto;
  }): Observable<StrictHttpResponse<CommonSuccessResDto>> {
    const rb = new RequestBuilder(this.rootUrl, SpacesService.SpacesControllerPersonalSpacesUpdatePath, 'patch');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<CommonSuccessResDto>;
        }),
      );
  }

  /**
   * update personal spaces
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `spacesControllerPersonalSpacesUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  spacesControllerPersonalSpacesUpdate(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    id: string;
    body: SpacesUpdateReqDto;
  }): Observable<CommonSuccessResDto> {
    return this.spacesControllerPersonalSpacesUpdate$Response(params).pipe(
      map((r: StrictHttpResponse<CommonSuccessResDto>) => r.body as CommonSuccessResDto),
    );
  }

  /**
   * Path part for operation spacesMembersUpdateOrder
   */
  static readonly SpacesMembersUpdateOrderPath = '/spaces/update-order/{id}';

  /**
   * spaces members update order
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `spacesMembersUpdateOrder()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  spacesMembersUpdateOrder$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    id: string;
    body: SpacesMembersUpdateOrderDto;
  }): Observable<StrictHttpResponse<SpacesDbDto>> {
    const rb = new RequestBuilder(this.rootUrl, SpacesService.SpacesMembersUpdateOrderPath, 'patch');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<SpacesDbDto>;
        }),
      );
  }

  /**
   * spaces members update order
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `spacesMembersUpdateOrder$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  spacesMembersUpdateOrder(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    id: string;
    body: SpacesMembersUpdateOrderDto;
  }): Observable<SpacesDbDto> {
    return this.spacesMembersUpdateOrder$Response(params).pipe(
      map((r: StrictHttpResponse<SpacesDbDto>) => r.body as SpacesDbDto),
    );
  }

  /**
   * Path part for operation spacesInvite
   */
  static readonly SpacesInvitePath = '/spaces/space-invite';

  /**
   * invite users to space
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `spacesInvite()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  spacesInvite$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    body: SpacesSpaceInviteReqDto;
  }): Observable<StrictHttpResponse<CommonInviteResDto>> {
    const rb = new RequestBuilder(this.rootUrl, SpacesService.SpacesInvitePath, 'post');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<CommonInviteResDto>;
        }),
      );
  }

  /**
   * invite users to space
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `spacesInvite$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  spacesInvite(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    body: SpacesSpaceInviteReqDto;
  }): Observable<CommonInviteResDto> {
    return this.spacesInvite$Response(params).pipe(
      map((r: StrictHttpResponse<CommonInviteResDto>) => r.body as CommonInviteResDto),
    );
  }

  /**
   * Path part for operation spacesGetUserListBySpaceId
   */
  static readonly SpacesGetUserListBySpaceIdPath = '/spaces/get-users-list/{id}';

  /**
   * get user list by space id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `spacesGetUserListBySpaceId()` instead.
   *
   * This method doesn't expect any request body.
   */
  spacesGetUserListBySpaceId$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    id: string;

    /**
     * exists
     */
    exists: boolean;
  }): Observable<StrictHttpResponse<Array<CommonGetListByObjectIdResDto>>> {
    const rb = new RequestBuilder(this.rootUrl, SpacesService.SpacesGetUserListBySpaceIdPath, 'get');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.path('id', params.id, {});
      rb.query('exists', params.exists, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<CommonGetListByObjectIdResDto>>;
        }),
      );
  }

  /**
   * get user list by space id
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `spacesGetUserListBySpaceId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  spacesGetUserListBySpaceId(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    id: string;

    /**
     * exists
     */
    exists: boolean;
  }): Observable<Array<CommonGetListByObjectIdResDto>> {
    return this.spacesGetUserListBySpaceId$Response(params).pipe(
      map(
        (r: StrictHttpResponse<Array<CommonGetListByObjectIdResDto>>) => r.body as Array<CommonGetListByObjectIdResDto>,
      ),
    );
  }

  /**
   * Path part for operation spacesGetUserListForCreateChatGroup
   */
  static readonly SpacesGetUserListForCreateChatGroupPath = '/spaces/get-users-list-for-chat-group/{id}';

  /**
   * get user list by space id for create chat group
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `spacesGetUserListForCreateChatGroup()` instead.
   *
   * This method doesn't expect any request body.
   */
  spacesGetUserListForCreateChatGroup$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    id: string;
  }): Observable<StrictHttpResponse<Array<UsersDbDto>>> {
    const rb = new RequestBuilder(this.rootUrl, SpacesService.SpacesGetUserListForCreateChatGroupPath, 'get');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.path('id', params.id, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<UsersDbDto>>;
        }),
      );
  }

  /**
   * get user list by space id for create chat group
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `spacesGetUserListForCreateChatGroup$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  spacesGetUserListForCreateChatGroup(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    id: string;
  }): Observable<Array<UsersDbDto>> {
    return this.spacesGetUserListForCreateChatGroup$Response(params).pipe(
      map((r: StrictHttpResponse<Array<UsersDbDto>>) => r.body as Array<UsersDbDto>),
    );
  }

  /**
   * Path part for operation spacesConfirmExist
   */
  static readonly SpacesConfirmExistPath = '/spaces/space-confirm-exist/{token}';

  /**
   * confirm existed user to space
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `spacesConfirmExist()` instead.
   *
   * This method doesn't expect any request body.
   */
  spacesConfirmExist$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    token: string;
  }): Observable<StrictHttpResponse<AuthLoginResDto>> {
    const rb = new RequestBuilder(this.rootUrl, SpacesService.SpacesConfirmExistPath, 'get');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.path('token', params.token, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<AuthLoginResDto>;
        }),
      );
  }

  /**
   * confirm existed user to space
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `spacesConfirmExist$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  spacesConfirmExist(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    token: string;
  }): Observable<AuthLoginResDto> {
    return this.spacesConfirmExist$Response(params).pipe(
      map((r: StrictHttpResponse<AuthLoginResDto>) => r.body as AuthLoginResDto),
    );
  }

  /**
   * Path part for operation spacesConfirmNew
   */
  static readonly SpacesConfirmNewPath = '/spaces/space-confirm-new';

  /**
   * confirm new user to space
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `spacesConfirmNew()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  spacesConfirmNew$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    body: CommonConfirmInviteReqDto;
  }): Observable<StrictHttpResponse<AuthLoginResDto>> {
    const rb = new RequestBuilder(this.rootUrl, SpacesService.SpacesConfirmNewPath, 'post');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<AuthLoginResDto>;
        }),
      );
  }

  /**
   * confirm new user to space
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `spacesConfirmNew$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  spacesConfirmNew(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    body: CommonConfirmInviteReqDto;
  }): Observable<AuthLoginResDto> {
    return this.spacesConfirmNew$Response(params).pipe(
      map((r: StrictHttpResponse<AuthLoginResDto>) => r.body as AuthLoginResDto),
    );
  }

  /**
   * Path part for operation spacesInviteTokenCheck
   */
  static readonly SpacesInviteTokenCheckPath = '/spaces/space-invite-token-check/{token}';

  /**
   * space invite token check
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `spacesInviteTokenCheck()` instead.
   *
   * This method doesn't expect any request body.
   */
  spacesInviteTokenCheck$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    token: string;
  }): Observable<StrictHttpResponse<SpacesValidateInviteTokenResDto>> {
    const rb = new RequestBuilder(this.rootUrl, SpacesService.SpacesInviteTokenCheckPath, 'get');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.path('token', params.token, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<SpacesValidateInviteTokenResDto>;
        }),
      );
  }

  /**
   * space invite token check
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `spacesInviteTokenCheck$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  spacesInviteTokenCheck(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    token: string;
  }): Observable<SpacesValidateInviteTokenResDto> {
    return this.spacesInviteTokenCheck$Response(params).pipe(
      map((r: StrictHttpResponse<SpacesValidateInviteTokenResDto>) => r.body as SpacesValidateInviteTokenResDto),
    );
  }

  /**
   * Path part for operation spacesGetSpacesListByUserId
   */
  static readonly SpacesGetSpacesListByUserIdPath = '/spaces/{userId}';

  /**
   * get spaces list by user id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `spacesGetSpacesListByUserId()` instead.
   *
   * This method doesn't expect any request body.
   */
  spacesGetSpacesListByUserId$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;

    /**
     * userId
     */
    userId: string;

    /**
     * Search string
     */
    search?: string;

    /**
     * Search key | keys
     */
    searchBy?: string;

    /**
     * Sorting direction
     */
    sort?: string;

    /**
     * Sorting by field
     */
    sortBy?: string;

    /**
     * Message id
     */
    messageId?: string;

    /**
     * Limit resulting elements(default *10*).
     */
    perPage?: number;

    /**
     * Number of current page.(default *1*).
     */
    page?: number;
  }): Observable<StrictHttpResponse<SpacesGetListByUserIdDbDtoType>> {
    const rb = new RequestBuilder(this.rootUrl, SpacesService.SpacesGetSpacesListByUserIdPath, 'get');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.path('userId', params.userId, {});
      rb.query('search', params.search, {});
      rb.query('searchBy', params.searchBy, {});
      rb.query('sort', params.sort, {});
      rb.query('sortBy', params.sortBy, {});
      rb.query('messageId', params.messageId, {});
      rb.query('perPage', params.perPage, {});
      rb.query('page', params.page, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<SpacesGetListByUserIdDbDtoType>;
        }),
      );
  }

  /**
   * get spaces list by user id
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `spacesGetSpacesListByUserId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  spacesGetSpacesListByUserId(params: {
    /**
     * Localization
     */
    'x-lang'?: string;

    /**
     * userId
     */
    userId: string;

    /**
     * Search string
     */
    search?: string;

    /**
     * Search key | keys
     */
    searchBy?: string;

    /**
     * Sorting direction
     */
    sort?: string;

    /**
     * Sorting by field
     */
    sortBy?: string;

    /**
     * Message id
     */
    messageId?: string;

    /**
     * Limit resulting elements(default *10*).
     */
    perPage?: number;

    /**
     * Number of current page.(default *1*).
     */
    page?: number;
  }): Observable<SpacesGetListByUserIdDbDtoType> {
    return this.spacesGetSpacesListByUserId$Response(params).pipe(
      map((r: StrictHttpResponse<SpacesGetListByUserIdDbDtoType>) => r.body as SpacesGetListByUserIdDbDtoType),
    );
  }

  /**
   * Path part for operation spacesUnassignUser
   */
  static readonly SpacesUnassignUserPath = '/spaces/space-unassign/user';

  /**
   * Unassign the user to a space
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `spacesUnassignUser()` instead.
   *
   * This method doesn't expect any request body.
   */
  spacesUnassignUser$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;

    /**
     * userId
     */
    userId: string;

    /**
     * spaceId
     */
    spaceId: string;
  }): Observable<StrictHttpResponse<CommonSuccessResDto>> {
    const rb = new RequestBuilder(this.rootUrl, SpacesService.SpacesUnassignUserPath, 'delete');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.query('userId', params.userId, {});
      rb.query('spaceId', params.spaceId, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<CommonSuccessResDto>;
        }),
      );
  }

  /**
   * Unassign the user to a space
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `spacesUnassignUser$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  spacesUnassignUser(params: {
    /**
     * Localization
     */
    'x-lang'?: string;

    /**
     * userId
     */
    userId: string;

    /**
     * spaceId
     */
    spaceId: string;
  }): Observable<CommonSuccessResDto> {
    return this.spacesUnassignUser$Response(params).pipe(
      map((r: StrictHttpResponse<CommonSuccessResDto>) => r.body as CommonSuccessResDto),
    );
  }

  /**
   * Path part for operation spacesLeave
   */
  static readonly SpacesLeavePath = '/spaces/space-leave/{id}';

  /**
   * Leave space
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `spacesLeave()` instead.
   *
   * This method doesn't expect any request body.
   */
  spacesLeave$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    id: string;
  }): Observable<StrictHttpResponse<CommonSuccessResDto>> {
    const rb = new RequestBuilder(this.rootUrl, SpacesService.SpacesLeavePath, 'delete');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.path('id', params.id, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<CommonSuccessResDto>;
        }),
      );
  }

  /**
   * Leave space
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `spacesLeave$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  spacesLeave(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    id: string;
  }): Observable<CommonSuccessResDto> {
    return this.spacesLeave$Response(params).pipe(
      map((r: StrictHttpResponse<CommonSuccessResDto>) => r.body as CommonSuccessResDto),
    );
  }

  /**
   * Path part for operation spacesRevokeUserInvite
   */
  static readonly SpacesRevokeUserInvitePath = '/spaces/revoke-invite/user';

  /**
   * Revoke user invite from the space
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `spacesRevokeUserInvite()` instead.
   *
   * This method doesn't expect any request body.
   */
  spacesRevokeUserInvite$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;

    /**
     * Email
     */
    email?: string;

    /**
     * userId
     */
    userId?: string;

    /**
     * spaceId
     */
    spaceId: string;
  }): Observable<StrictHttpResponse<CommonSuccessResDto>> {
    const rb = new RequestBuilder(this.rootUrl, SpacesService.SpacesRevokeUserInvitePath, 'delete');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.query('email', params.email, {});
      rb.query('userId', params.userId, {});
      rb.query('spaceId', params.spaceId, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<CommonSuccessResDto>;
        }),
      );
  }

  /**
   * Revoke user invite from the space
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `spacesRevokeUserInvite$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  spacesRevokeUserInvite(params: {
    /**
     * Localization
     */
    'x-lang'?: string;

    /**
     * Email
     */
    email?: string;

    /**
     * userId
     */
    userId?: string;

    /**
     * spaceId
     */
    spaceId: string;
  }): Observable<CommonSuccessResDto> {
    return this.spacesRevokeUserInvite$Response(params).pipe(
      map((r: StrictHttpResponse<CommonSuccessResDto>) => r.body as CommonSuccessResDto),
    );
  }

  /**
   * Path part for operation spacesToggleArchiveStatus
   */
  static readonly SpacesToggleArchiveStatusPath = '/spaces/{id}/toggleArchiveStatus';

  /**
   * move the space with all his projects to archive and back
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `spacesToggleArchiveStatus()` instead.
   *
   * This method doesn't expect any request body.
   */
  spacesToggleArchiveStatus$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    id: string;
  }): Observable<StrictHttpResponse<CommonSuccessResDto>> {
    const rb = new RequestBuilder(this.rootUrl, SpacesService.SpacesToggleArchiveStatusPath, 'patch');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.path('id', params.id, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<CommonSuccessResDto>;
        }),
      );
  }

  /**
   * move the space with all his projects to archive and back
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `spacesToggleArchiveStatus$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  spacesToggleArchiveStatus(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    id: string;
  }): Observable<CommonSuccessResDto> {
    return this.spacesToggleArchiveStatus$Response(params).pipe(
      map((r: StrictHttpResponse<CommonSuccessResDto>) => r.body as CommonSuccessResDto),
    );
  }

  /**
   * Path part for operation spacesRestore
   */
  static readonly SpacesRestorePath = '/spaces/{id}/restore';

  /**
   * restore the space
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `spacesRestore()` instead.
   *
   * This method doesn't expect any request body.
   */
  spacesRestore$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    id: string;
  }): Observable<StrictHttpResponse<SpacesDbDto>> {
    const rb = new RequestBuilder(this.rootUrl, SpacesService.SpacesRestorePath, 'patch');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.path('id', params.id, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<SpacesDbDto>;
        }),
      );
  }

  /**
   * restore the space
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `spacesRestore$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  spacesRestore(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    id: string;
  }): Observable<SpacesDbDto> {
    return this.spacesRestore$Response(params).pipe(map((r: StrictHttpResponse<SpacesDbDto>) => r.body as SpacesDbDto));
  }
}
