/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { NotificationFiltersCreateReqDto } from '../models/notification-filters-create-req-dto';
import { NotificationFiltersDbDto } from '../models/notification-filters-db-dto';

@Injectable({
  providedIn: 'root',
})
export class PushNotificationsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation notificationFiltersGet
   */
  static readonly NotificationFiltersGetPath = '/push-notifications/filters';

  /**
   * get notification filters
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `notificationFiltersGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  notificationFiltersGet$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    object: string;
    objectId?: string;
  }): Observable<StrictHttpResponse<NotificationFiltersDbDto>> {
    const rb = new RequestBuilder(this.rootUrl, PushNotificationsService.NotificationFiltersGetPath, 'get');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.query('object', params.object, {});
      rb.query('objectId', params.objectId, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<NotificationFiltersDbDto>;
        }),
      );
  }

  /**
   * get notification filters
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `notificationFiltersGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  notificationFiltersGet(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    object: string;
    objectId?: string;
  }): Observable<NotificationFiltersDbDto> {
    return this.notificationFiltersGet$Response(params).pipe(
      map((r: StrictHttpResponse<NotificationFiltersDbDto>) => r.body as NotificationFiltersDbDto),
    );
  }

  /**
   * Path part for operation notificationFiltersCreate
   */
  static readonly NotificationFiltersCreatePath = '/push-notifications/filters';

  /**
   * create notification filters
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `notificationFiltersCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  notificationFiltersCreate$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    body: NotificationFiltersCreateReqDto;
  }): Observable<StrictHttpResponse<NotificationFiltersDbDto>> {
    const rb = new RequestBuilder(this.rootUrl, PushNotificationsService.NotificationFiltersCreatePath, 'post');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<NotificationFiltersDbDto>;
        }),
      );
  }

  /**
   * create notification filters
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `notificationFiltersCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  notificationFiltersCreate(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    body: NotificationFiltersCreateReqDto;
  }): Observable<NotificationFiltersDbDto> {
    return this.notificationFiltersCreate$Response(params).pipe(
      map((r: StrictHttpResponse<NotificationFiltersDbDto>) => r.body as NotificationFiltersDbDto),
    );
  }

  /**
   * Path part for operation notificationFiltersGetList
   */
  static readonly NotificationFiltersGetListPath = '/push-notifications/filters-list';

  /**
   * get notification filters list
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `notificationFiltersGetList()` instead.
   *
   * This method doesn't expect any request body.
   */
  notificationFiltersGetList$Response(params?: {
    /**
     * Localization
     */
    'x-lang'?: string;
  }): Observable<StrictHttpResponse<Array<NotificationFiltersDbDto>>> {
    const rb = new RequestBuilder(this.rootUrl, PushNotificationsService.NotificationFiltersGetListPath, 'get');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<NotificationFiltersDbDto>>;
        }),
      );
  }

  /**
   * get notification filters list
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `notificationFiltersGetList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  notificationFiltersGetList(params?: {
    /**
     * Localization
     */
    'x-lang'?: string;
  }): Observable<Array<NotificationFiltersDbDto>> {
    return this.notificationFiltersGetList$Response(params).pipe(
      map((r: StrictHttpResponse<Array<NotificationFiltersDbDto>>) => r.body as Array<NotificationFiltersDbDto>),
    );
  }
}
