import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { CommonGetListByObjectIdResDto } from '../../api/models/common-get-list-by-object-id-res-dto';
import { TicketsLabelsDbDto } from '../../api/models/tickets-labels-db-dto';
import { ColumnsDbDto } from '../../api/models/columns-db-dto';
import { TicketsFieldsGetListResDto } from '../../api/models/tickets-fields-get-list-res-dto';
import { TicketsDbDto } from '../../api/models/tickets-db-dto';
import { PinnedMessagesDbDto } from '../../api/models/pinned-messages-db-dto';
import { ChatsUpdateSocketPin } from '../store/actions/chats.action';

@Injectable({
  providedIn: 'root',
})
export class TicketService {
  private users$$ = new BehaviorSubject<CommonGetListByObjectIdResDto[]>([]);
  public users$ = this.users$$.asObservable();

  private labels$$ = new BehaviorSubject<TicketsLabelsDbDto[]>([]);
  public labels$ = this.labels$$.asObservable();

  private columns$$ = new BehaviorSubject<ColumnsDbDto[]>([]);
  public columns$ = this.columns$$.asObservable();

  private ticketsForRecount$$ = new BehaviorSubject<TicketsDbDto[]>([]);
  public ticketsForRecount$ = this.ticketsForRecount$$.asObservable();

  private columnsForRecount$$ = new BehaviorSubject<ColumnsDbDto[]>([]);

  private fields$$ = new BehaviorSubject<Partial<TicketsFieldsGetListResDto>>({});
  public fields$ = this.fields$$.asObservable();

  private pinnedMessages$$ = new BehaviorSubject<PinnedMessagesDbDto[]>([]);
  public pinnedMessages$ = this.pinnedMessages$$.asObservable();

  public chatId: string;

  public setTicketUsers(users: CommonGetListByObjectIdResDto[]) {
    this.users$$.next(users);
  }

  public setTicketLabels(labels: TicketsLabelsDbDto[]) {
    this.labels$$.next(labels);
  }

  public setTicketColumns(columns: ColumnsDbDto[]) {
    this.columns$$.next(columns);
  }

  public setTickets(tickets: TicketsDbDto[]) {
    this.ticketsForRecount$$.next(tickets);
  }

  public setColumnsForRecount(columns: ColumnsDbDto[]) {
    this.columnsForRecount$$.next(columns);
  }

  public setTicketFields(fields: TicketsFieldsGetListResDto) {
    this.fields$$.next(fields);
  }

  public getUsers() {
    return this.users$$.getValue();
  }

  public getTickets() {
    return this.ticketsForRecount$$.getValue();
  }

  public getColumns() {
    return this.columnsForRecount$$.getValue();
  }

  public setPinnedMessages(pinnedMessages: PinnedMessagesDbDto[]): void {
    this.pinnedMessages$$.next(pinnedMessages);
  }

  public updatePinnedMessage({ payload }: ChatsUpdateSocketPin): void {
    if (!this.chatId) {
      return;
    }

    const { type, message: updatedMessage } = payload;
    const currentState = this.pinnedMessages$$.getValue();

    let updatedUpdated: PinnedMessagesDbDto[];
    if (type === 'update') {
      updatedUpdated = [...currentState, updatedMessage];
    } else if (type === 'delete') {
      updatedUpdated = currentState.filter((message) => message._id !== updatedMessage._id);
    }

    this.pinnedMessages$$.next(updatedUpdated);
  }
}
