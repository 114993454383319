import { Injectable } from '@angular/core';
import moment from 'moment-timezone';

@Injectable({
  providedIn: 'root',
})
export class DateTimeHelper {
  convertDateForTz(currTz, date, addDiff = null) {
    const newDate = addDiff ? moment(date).add(addDiff.amount, addDiff.unit) : moment(date);

    return new Date(newDate.tz(currTz).format('YYYY-MM-DDTHH:mm:ss.000'));
  }

  getTzsDiffInHours(tz) {
    return +(this.getTzsDiff(tz) / 60).toFixed(2);
  }

  getTzsDiff(tz) {
    const offset = moment.tz(tz).format('Z');
    return moment().diff(moment().utcOffset(offset, true), 'minutes');
  }

  getHoursForTz(tz) {
    const diff = this.getTzsDiff(tz);
    return moment().add(diff, 'minutes').format('HH:mm') + moment.tz(tz).format(' z (Z)');
  }

  getTimezoneName(tz) {
    return '(UTC' + moment.tz(tz).format('Z') + ') ' + tz.replace('_', ' ');
  }

  getFormattedTimezones() {
    return moment.tz
      .names()
      .map((tz) => ({
        value: tz,
        label: this.getTimezoneName(tz),
        utc: moment.tz(tz).format('Z'),
      }))
      .sort((a, b) => a.utc.localeCompare(b.utc));
  }
}
