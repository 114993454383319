/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { BoardsObjectsReqDto } from '../models/boards-objects-req-dto';
import { LaneBoardCardsCreateReqDto } from '../models/lane-board-cards-create-req-dto';
import { LaneBoardCardsDbDto } from '../models/lane-board-cards-db-dto';
import { LaneBoardCardsUpdateReqDto } from '../models/lane-board-cards-update-req-dto';
import { LaneBoardColumnsCreateReqDto } from '../models/lane-board-columns-create-req-dto';
import { LaneBoardColumnsDbDto } from '../models/lane-board-columns-db-dto';
import { LaneBoardColumnsUpdateReqDto } from '../models/lane-board-columns-update-req-dto';
import { LaneBoardsDbDto } from '../models/lane-boards-db-dto';
import { LaneBoardsResDto } from '../models/lane-boards-res-dto';
import { LaneBoardsUpdateReqDto } from '../models/lane-boards-update-req-dto';

@Injectable({
  providedIn: 'root',
})
export class LaneBoardsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation laneBoardsControllerCreateLaneBoardCard
   */
  static readonly LaneBoardsControllerCreateLaneBoardCardPath = '/lane-boards/{id}/cards';

  /**
   * create lane board card
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `laneBoardsControllerCreateLaneBoardCard()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  laneBoardsControllerCreateLaneBoardCard$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;

    /**
     * laneBoardId parameter
     */
    id: string;
    body: LaneBoardCardsCreateReqDto;
  }): Observable<StrictHttpResponse<LaneBoardCardsDbDto>> {
    const rb = new RequestBuilder(this.rootUrl, LaneBoardsService.LaneBoardsControllerCreateLaneBoardCardPath, 'post');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<LaneBoardCardsDbDto>;
        }),
      );
  }

  /**
   * create lane board card
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `laneBoardsControllerCreateLaneBoardCard$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  laneBoardsControllerCreateLaneBoardCard(params: {
    /**
     * Localization
     */
    'x-lang'?: string;

    /**
     * laneBoardId parameter
     */
    id: string;
    body: LaneBoardCardsCreateReqDto;
  }): Observable<LaneBoardCardsDbDto> {
    return this.laneBoardsControllerCreateLaneBoardCard$Response(params).pipe(
      map((r: StrictHttpResponse<LaneBoardCardsDbDto>) => r.body as LaneBoardCardsDbDto),
    );
  }

  /**
   * Path part for operation laneBoardsControllerDeleteLaneBoardCard
   */
  static readonly LaneBoardsControllerDeleteLaneBoardCardPath = '/lane-boards/{id}/cards/{cardId}';

  /**
   * soft delete lane board cards
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `laneBoardsControllerDeleteLaneBoardCard()` instead.
   *
   * This method doesn't expect any request body.
   */
  laneBoardsControllerDeleteLaneBoardCard$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;

    /**
     * card id parameter
     */
    cardId: string;

    /**
     * lane board column id parameter
     */
    columnId: string;

    /**
     * laneBoardId parameter
     */
    id: any;
  }): Observable<StrictHttpResponse<LaneBoardCardsDbDto>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      LaneBoardsService.LaneBoardsControllerDeleteLaneBoardCardPath,
      'delete',
    );
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.path('cardId', params.cardId, {});
      rb.query('columnId', params.columnId, {});
      rb.path('id', params.id, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<LaneBoardCardsDbDto>;
        }),
      );
  }

  /**
   * soft delete lane board cards
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `laneBoardsControllerDeleteLaneBoardCard$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  laneBoardsControllerDeleteLaneBoardCard(params: {
    /**
     * Localization
     */
    'x-lang'?: string;

    /**
     * card id parameter
     */
    cardId: string;

    /**
     * lane board column id parameter
     */
    columnId: string;

    /**
     * laneBoardId parameter
     */
    id: any;
  }): Observable<LaneBoardCardsDbDto> {
    return this.laneBoardsControllerDeleteLaneBoardCard$Response(params).pipe(
      map((r: StrictHttpResponse<LaneBoardCardsDbDto>) => r.body as LaneBoardCardsDbDto),
    );
  }

  /**
   * Path part for operation laneBoardsControllerUpdateLaneBoardCard
   */
  static readonly LaneBoardsControllerUpdateLaneBoardCardPath = '/lane-boards/{id}/cards/{cardId}';

  /**
   * update lane board card
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `laneBoardsControllerUpdateLaneBoardCard()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  laneBoardsControllerUpdateLaneBoardCard$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;

    /**
     * card id parameter
     */
    cardId: string;

    /**
     * laneBoardId parameter
     */
    id: any;
    body: LaneBoardCardsUpdateReqDto;
  }): Observable<StrictHttpResponse<LaneBoardCardsDbDto>> {
    const rb = new RequestBuilder(this.rootUrl, LaneBoardsService.LaneBoardsControllerUpdateLaneBoardCardPath, 'patch');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.path('cardId', params.cardId, {});
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<LaneBoardCardsDbDto>;
        }),
      );
  }

  /**
   * update lane board card
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `laneBoardsControllerUpdateLaneBoardCard$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  laneBoardsControllerUpdateLaneBoardCard(params: {
    /**
     * Localization
     */
    'x-lang'?: string;

    /**
     * card id parameter
     */
    cardId: string;

    /**
     * laneBoardId parameter
     */
    id: any;
    body: LaneBoardCardsUpdateReqDto;
  }): Observable<LaneBoardCardsDbDto> {
    return this.laneBoardsControllerUpdateLaneBoardCard$Response(params).pipe(
      map((r: StrictHttpResponse<LaneBoardCardsDbDto>) => r.body as LaneBoardCardsDbDto),
    );
  }

  /**
   * Path part for operation laneBoardsControllerToggleLaneBoardCardVote
   */
  static readonly LaneBoardsControllerToggleLaneBoardCardVotePath = '/lane-boards/{id}/cards/{cardId}/vote';

  /**
   * toggle vote for the lane board card
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `laneBoardsControllerToggleLaneBoardCardVote()` instead.
   *
   * This method doesn't expect any request body.
   */
  laneBoardsControllerToggleLaneBoardCardVote$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;

    /**
     * card id parameter
     */
    cardId: string;

    /**
     * laneBoardId parameter
     */
    id: any;
  }): Observable<StrictHttpResponse<LaneBoardCardsDbDto>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      LaneBoardsService.LaneBoardsControllerToggleLaneBoardCardVotePath,
      'post',
    );
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.path('cardId', params.cardId, {});
      rb.path('id', params.id, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<LaneBoardCardsDbDto>;
        }),
      );
  }

  /**
   * toggle vote for the lane board card
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `laneBoardsControllerToggleLaneBoardCardVote$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  laneBoardsControllerToggleLaneBoardCardVote(params: {
    /**
     * Localization
     */
    'x-lang'?: string;

    /**
     * card id parameter
     */
    cardId: string;

    /**
     * laneBoardId parameter
     */
    id: any;
  }): Observable<LaneBoardCardsDbDto> {
    return this.laneBoardsControllerToggleLaneBoardCardVote$Response(params).pipe(
      map((r: StrictHttpResponse<LaneBoardCardsDbDto>) => r.body as LaneBoardCardsDbDto),
    );
  }

  /**
   * Path part for operation columnsGetList_1
   */
  static readonly ColumnsGetList_1Path = '/lane-boards/{id}/columns';

  /**
   * get columns list
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `columnsGetList_1()` instead.
   *
   * This method doesn't expect any request body.
   */
  columnsGetList_1$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;

    /**
     * laneBoardId parameter
     */
    id: string;
  }): Observable<StrictHttpResponse<Array<LaneBoardColumnsDbDto>>> {
    const rb = new RequestBuilder(this.rootUrl, LaneBoardsService.ColumnsGetList_1Path, 'get');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.path('id', params.id, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<LaneBoardColumnsDbDto>>;
        }),
      );
  }

  /**
   * get columns list
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `columnsGetList_1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  columnsGetList_1(params: {
    /**
     * Localization
     */
    'x-lang'?: string;

    /**
     * laneBoardId parameter
     */
    id: string;
  }): Observable<Array<LaneBoardColumnsDbDto>> {
    return this.columnsGetList_1$Response(params).pipe(
      map((r: StrictHttpResponse<Array<LaneBoardColumnsDbDto>>) => r.body as Array<LaneBoardColumnsDbDto>),
    );
  }

  /**
   * Path part for operation columnsCreate_1
   */
  static readonly ColumnsCreate_1Path = '/lane-boards/{id}/columns';

  /**
   * create lane board column
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `columnsCreate_1()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  columnsCreate_1$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;

    /**
     * laneBoardId parameter
     */
    id: string;
    body: LaneBoardColumnsCreateReqDto;
  }): Observable<StrictHttpResponse<LaneBoardColumnsDbDto>> {
    const rb = new RequestBuilder(this.rootUrl, LaneBoardsService.ColumnsCreate_1Path, 'post');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<LaneBoardColumnsDbDto>;
        }),
      );
  }

  /**
   * create lane board column
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `columnsCreate_1$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  columnsCreate_1(params: {
    /**
     * Localization
     */
    'x-lang'?: string;

    /**
     * laneBoardId parameter
     */
    id: string;
    body: LaneBoardColumnsCreateReqDto;
  }): Observable<LaneBoardColumnsDbDto> {
    return this.columnsCreate_1$Response(params).pipe(
      map((r: StrictHttpResponse<LaneBoardColumnsDbDto>) => r.body as LaneBoardColumnsDbDto),
    );
  }

  /**
   * Path part for operation columnsDelete_1
   */
  static readonly ColumnsDelete_1Path = '/lane-boards/{id}/columns/{columnId}';

  /**
   * soft delete lane board column
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `columnsDelete_1()` instead.
   *
   * This method doesn't expect any request body.
   */
  columnsDelete_1$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;

    /**
     * laneBoardId parameter
     */
    id: string;

    /**
     * column id parameter
     */
    columnId: string;
  }): Observable<StrictHttpResponse<LaneBoardColumnsDbDto>> {
    const rb = new RequestBuilder(this.rootUrl, LaneBoardsService.ColumnsDelete_1Path, 'delete');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.path('id', params.id, {});
      rb.path('columnId', params.columnId, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<LaneBoardColumnsDbDto>;
        }),
      );
  }

  /**
   * soft delete lane board column
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `columnsDelete_1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  columnsDelete_1(params: {
    /**
     * Localization
     */
    'x-lang'?: string;

    /**
     * laneBoardId parameter
     */
    id: string;

    /**
     * column id parameter
     */
    columnId: string;
  }): Observable<LaneBoardColumnsDbDto> {
    return this.columnsDelete_1$Response(params).pipe(
      map((r: StrictHttpResponse<LaneBoardColumnsDbDto>) => r.body as LaneBoardColumnsDbDto),
    );
  }

  /**
   * Path part for operation laneBoardsControllerUpdateLaneBoardColumn
   */
  static readonly LaneBoardsControllerUpdateLaneBoardColumnPath = '/lane-boards/{id}/columns/{columnId}';

  /**
   * update lane board column
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `laneBoardsControllerUpdateLaneBoardColumn()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  laneBoardsControllerUpdateLaneBoardColumn$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;

    /**
     * laneBoardId parameter
     */
    id: string;

    /**
     * column id parameter
     */
    columnId: string;
    body: LaneBoardColumnsUpdateReqDto;
  }): Observable<StrictHttpResponse<LaneBoardColumnsDbDto>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      LaneBoardsService.LaneBoardsControllerUpdateLaneBoardColumnPath,
      'patch',
    );
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.path('id', params.id, {});
      rb.path('columnId', params.columnId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<LaneBoardColumnsDbDto>;
        }),
      );
  }

  /**
   * update lane board column
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `laneBoardsControllerUpdateLaneBoardColumn$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  laneBoardsControllerUpdateLaneBoardColumn(params: {
    /**
     * Localization
     */
    'x-lang'?: string;

    /**
     * laneBoardId parameter
     */
    id: string;

    /**
     * column id parameter
     */
    columnId: string;
    body: LaneBoardColumnsUpdateReqDto;
  }): Observable<LaneBoardColumnsDbDto> {
    return this.laneBoardsControllerUpdateLaneBoardColumn$Response(params).pipe(
      map((r: StrictHttpResponse<LaneBoardColumnsDbDto>) => r.body as LaneBoardColumnsDbDto),
    );
  }

  /**
   * Path part for operation laneBoardsControllerLaneBoardsList
   */
  static readonly LaneBoardsControllerLaneBoardsListPath = '/lane-boards';

  /**
   * get lane board list
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `laneBoardsControllerLaneBoardsList()` instead.
   *
   * This method doesn't expect any request body.
   */
  laneBoardsControllerLaneBoardsList$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;

    /**
     * spaces | projects
     */
    object: string;

    /**
     * objectId
     */
    objectId: string;
  }): Observable<StrictHttpResponse<Array<LaneBoardsDbDto>>> {
    const rb = new RequestBuilder(this.rootUrl, LaneBoardsService.LaneBoardsControllerLaneBoardsListPath, 'get');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.query('object', params.object, {});
      rb.query('objectId', params.objectId, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<LaneBoardsDbDto>>;
        }),
      );
  }

  /**
   * get lane board list
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `laneBoardsControllerLaneBoardsList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  laneBoardsControllerLaneBoardsList(params: {
    /**
     * Localization
     */
    'x-lang'?: string;

    /**
     * spaces | projects
     */
    object: string;

    /**
     * objectId
     */
    objectId: string;
  }): Observable<Array<LaneBoardsDbDto>> {
    return this.laneBoardsControllerLaneBoardsList$Response(params).pipe(
      map((r: StrictHttpResponse<Array<LaneBoardsDbDto>>) => r.body as Array<LaneBoardsDbDto>),
    );
  }

  /**
   * Path part for operation laneBoardsControllerLaneBoardsCreate
   */
  static readonly LaneBoardsControllerLaneBoardsCreatePath = '/lane-boards';

  /**
   * create lane board
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `laneBoardsControllerLaneBoardsCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  laneBoardsControllerLaneBoardsCreate$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    body: BoardsObjectsReqDto;
  }): Observable<StrictHttpResponse<LaneBoardsDbDto>> {
    const rb = new RequestBuilder(this.rootUrl, LaneBoardsService.LaneBoardsControllerLaneBoardsCreatePath, 'post');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<LaneBoardsDbDto>;
        }),
      );
  }

  /**
   * create lane board
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `laneBoardsControllerLaneBoardsCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  laneBoardsControllerLaneBoardsCreate(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    body: BoardsObjectsReqDto;
  }): Observable<LaneBoardsDbDto> {
    return this.laneBoardsControllerLaneBoardsCreate$Response(params).pipe(
      map((r: StrictHttpResponse<LaneBoardsDbDto>) => r.body as LaneBoardsDbDto),
    );
  }

  /**
   * Path part for operation laneBoardsControllerLaneBoardsGetById
   */
  static readonly LaneBoardsControllerLaneBoardsGetByIdPath = '/lane-boards/{id}';

  /**
   * get lane board by id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `laneBoardsControllerLaneBoardsGetById()` instead.
   *
   * This method doesn't expect any request body.
   */
  laneBoardsControllerLaneBoardsGetById$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    id: string;
  }): Observable<StrictHttpResponse<LaneBoardsResDto>> {
    const rb = new RequestBuilder(this.rootUrl, LaneBoardsService.LaneBoardsControllerLaneBoardsGetByIdPath, 'get');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.path('id', params.id, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<LaneBoardsResDto>;
        }),
      );
  }

  /**
   * get lane board by id
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `laneBoardsControllerLaneBoardsGetById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  laneBoardsControllerLaneBoardsGetById(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    id: string;
  }): Observable<LaneBoardsResDto> {
    return this.laneBoardsControllerLaneBoardsGetById$Response(params).pipe(
      map((r: StrictHttpResponse<LaneBoardsResDto>) => r.body as LaneBoardsResDto),
    );
  }

  /**
   * Path part for operation laneBoardsControllerLaneBoardsDelete
   */
  static readonly LaneBoardsControllerLaneBoardsDeletePath = '/lane-boards/{id}';

  /**
   * delete lane board
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `laneBoardsControllerLaneBoardsDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  laneBoardsControllerLaneBoardsDelete$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    id: string;
  }): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, LaneBoardsService.LaneBoardsControllerLaneBoardsDeletePath, 'delete');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.path('id', params.id, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: '*/*',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
        }),
      );
  }

  /**
   * delete lane board
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `laneBoardsControllerLaneBoardsDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  laneBoardsControllerLaneBoardsDelete(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    id: string;
  }): Observable<void> {
    return this.laneBoardsControllerLaneBoardsDelete$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void),
    );
  }

  /**
   * Path part for operation laneBoardsControllerLaneBoardsUpdate
   */
  static readonly LaneBoardsControllerLaneBoardsUpdatePath = '/lane-boards/{id}';

  /**
   * update lane board
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `laneBoardsControllerLaneBoardsUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  laneBoardsControllerLaneBoardsUpdate$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    id: string;
    needToPublish?: boolean;
    body: LaneBoardsUpdateReqDto;
  }): Observable<StrictHttpResponse<LaneBoardsDbDto>> {
    const rb = new RequestBuilder(this.rootUrl, LaneBoardsService.LaneBoardsControllerLaneBoardsUpdatePath, 'patch');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.path('id', params.id, {});
      rb.query('needToPublish', params.needToPublish, {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<LaneBoardsDbDto>;
        }),
      );
  }

  /**
   * update lane board
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `laneBoardsControllerLaneBoardsUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  laneBoardsControllerLaneBoardsUpdate(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    id: string;
    needToPublish?: boolean;
    body: LaneBoardsUpdateReqDto;
  }): Observable<LaneBoardsDbDto> {
    return this.laneBoardsControllerLaneBoardsUpdate$Response(params).pipe(
      map((r: StrictHttpResponse<LaneBoardsDbDto>) => r.body as LaneBoardsDbDto),
    );
  }
}
