<ng-container *transloco="let t; read: 'modals.user-view'">
  <div class="modal-header">
    <h5 class="modal-title">{{ titleModal }}</h5>
    <button type="button" class="close" aria-label="Close" (click)="close()">
      <app-svg name="times" width="10" height="10" class="svg-icon" aria-hidden="true"></app-svg>
    </button>
  </div>
  <div class="modal-body">
    <section class="users-edit">
      <ng-scrollbar [autoHeightDisabled]="false" visibility="hover" thumbClass="white-scrollbars">
        <div class="row">
          <div class="col-12 users-edit-unset">
            <!-- Nav-tabs -->
            <ul ngbNav #nav="ngbNav" class="nav-tabs justify-content-left">
              <li [ngbNavItem]="1">
                <a ngbNavLink class="nav-link d-flex align-items-center">
                  <i class="ft-user mr-1"></i>
                  <span class="d-none d-sm-block">{{ t('account') }}</span>
                </a>
                <ng-template ngbNavContent>
                  <!-- Account content starts -->

                  <!--PERFECT-->
                  <div class="profile-responsive">
                    <div class="mt-3 account" id="account">
                      <form *ngIf="userForm" novalidate [formGroup]="userForm">
                        <div class="row wrapper-profile">
                          <div class="col-6 col-md-6">
                            <!-- Media object starts -->
                            <div class="media pt-0" [class.align-center]="isAssistant">
                              <app-avatar
                                [height]="85"
                                [width]="85"
                                [userId]="user?._id"
                                class="mr-2"
                                [notShowProfile]="true"
                              ></app-avatar>

                              <div *ngIf="isAvatarImageUploading" class="user-avatar-image-spinner">
                                <app-custom-spinner [smallSize]="true"></app-custom-spinner>
                              </div>

                              <div class="media-body" t-id="admin-user-edit-personal-info">
                                <div class="px-0 mb-sm-2">
                                  <div class="flex items-center">
                                    <ng-container *ngIf="user?._id | userNameAlone | async as user">
                                      <ng-container *ngIf="user.loading; else showUserName">
                                        <div role="status" class="max-w-sm animate-pulse">
                                          <div
                                            class="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-24"
                                          ></div>
                                        </div>
                                      </ng-container>
                                      <ng-template #showUserName>
                                        <span class="text-xl font-semibold mr-1">
                                          {{ user.userName }}</span
                                        >

                                        @if (user.state && user.state !== null) {
                                          @if (user.state_end) {
                                            <span
                                              [appTmpTooltip]="
                                                user.state_end | date: 'dd/MM/yyyy HH:MM'
                                              "
                                              class="cursor-pointer"
                                              title="{{
                                                user.state_end | date: 'dd/MM/yyyy HH:MM'
                                              }}"
                                            >
                                              @if (user.state_icon) {
                                                {{ user.state_icon }}
                                                {{ user?.state }}
                                              } @else {
                                                {{
                                                  'user.state.' + user.state + '.emoji' | transloco
                                                }}
                                                {{
                                                  'user.state.' + user.state + '.label' | transloco
                                                }}
                                              }
                                            </span>
                                          } @else {
                                            @if (user.state_icon) {
                                              {{ user.state_icon }}
                                              {{ user?.state }}
                                            } @else {
                                              {{
                                                'user.state.' + user.state + '.emoji' | transloco
                                              }}
                                              {{
                                                'user.state.' + user.state + '.label' | transloco
                                              }}
                                            }
                                          }
                                        }
                                      </ng-template>
                                    </ng-container>
                                  </div>

                                  <button
                                    *ngIf="!isAssistant && !isMyself"
                                    type="button"
                                    class="btn btn-sm btn-solid"
                                    (click)="viewDirectChat(user._id)"
                                    t-id="user-view-message"
                                  >
                                    <i class="ft-message-circle mr-1"></i>
                                    <span>{{ t('message') }}</span>
                                  </button>
                                  <a
                                    *ngIf="isMyself"
                                    class="btn btn-sm btn-solid"
                                    [routerLink]="['settings', 'profile'].join('/') | routerTenant"
                                    (click)="close()"
                                    t-id="user-view-edit-profile"
                                  >
                                    <i class="ft-edit mr-1"></i>
                                    <span>{{ t('edit-profile') }}</span>
                                  </a>
                                </div>
                              </div>
                            </div>
                            <!-- Media object ends -->
                          </div>
                        </div>

                        <!-- Account form starts -->
                        <div class="wrapper-edit" [class.wrapper-edit-line]="false">
                          <div class="row">
                            <div class="col-12 col-md-6">
                              <div class="form-group">
                                <div class="controls">
                                  <label for="users-edit-name" class="profile-input-title">{{
                                    t('name')
                                  }}</label>
                                  <input
                                    type="text"
                                    id="users-edit-name"
                                    class="form-control"
                                    placeholder="{{ t('name') }}"
                                    formControlName="name"
                                    aria-invalid="false"
                                    readonly
                                  />
                                </div>
                              </div>

                              <div class="form-group">
                                <div class="controls">
                                  <label for="users-edit-email" class="profile-input-title">{{
                                    t('e-mail')
                                  }}</label>
                                  <input
                                    type="email"
                                    id="users-edit-email"
                                    class="form-control"
                                    placeholder="{{ t('placeholder-email') }}"
                                    formControlName="email"
                                    aria-invalid="false"
                                    readonly
                                  />
                                  <i
                                    *ngIf="userForm.value.email"
                                    class="ft-copy copy-email"
                                    ngbTooltip="{{ t('tooltip-copy-email') }}"
                                    container="body"
                                    placement="top"
                                    (click)="copyEmail()"
                                  ></i>
                                </div>
                              </div>
                            </div>

                            <div class="col-12 col-md-6">
                              <div class="form-group">
                                <div class="controls">
                                  <label for="users-edit-username" class="profile-input-title">{{
                                    t('username')
                                  }}</label>
                                  <input
                                    type="text"
                                    id="users-edit-username"
                                    class="form-control"
                                    placeholder="{{ t('username') }}"
                                    formControlName="userName"
                                    aria-invalid="false"
                                    readonly
                                  />
                                </div>
                              </div>
                              <div class="form-group">
                                <div class="controls">
                                  <label for="timeZone" class="profile-input-title">{{
                                    t('timezone')
                                  }}</label>
                                </div>
                                <div class="mt-1">
                                  <span *ngIf="user.timezone">{{
                                    dtHelper.getTimezoneName(user.timezone)
                                  }}</span>
                                  <span *ngIf="!user.timezone">{{ t('timezone-not-set') }}</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div></div>

                          <hr class="my-4" />

                          <div class="row">
                            <div class="col-12 col-md-6">
                              <div class="form-group">
                                <div class="controls">
                                  <label for="users-edit-job" class="profile-input-title">{{
                                    t('job-title')
                                  }}</label>
                                  <input
                                    type="text"
                                    id="users-edit-job"
                                    class="form-control"
                                    formControlName="job"
                                    aria-invalid="false"
                                    maxlength="30"
                                    readonly
                                  />
                                </div>
                              </div>
                            </div>
                            <div class="col-12 col-md-6">
                              <div class="form-group">
                                <div class="controls">
                                  <label for="users-edit-department" class="profile-input-title">{{
                                    t('department-title')
                                  }}</label>
                                  <input
                                    type="text"
                                    id="users-edit-department"
                                    class="form-control"
                                    formControlName="department"
                                    aria-invalid="false"
                                    maxlength="30"
                                    readonly
                                  />
                                </div>
                              </div>
                            </div>
                          </div>

                          <hr *ngIf="user.timezone" class="my-4" />

                          <div *ngIf="user.timezone" class="mb-2">
                            <div class="user-time-label mb-1" [class.dark]="isDark">
                              {{ t('user-local-time') }}
                            </div>
                            <div class="user-time" [class.dark]="isDark">
                              {{ localTime }}
                            </div>
                            <div *ngIf="hoursDiff !== 0">
                              {{ t('difference') }} <b>{{ hoursDiff }}h</b>
                            </div>
                          </div>
                        </div>
                      </form>

                      <!-- Account form ends -->
                    </div>
                  </div>

                  <!-- Account content ends -->
                </ng-template>
              </li>

              <li [ngbNavItem]="2">
                <a ngbNavLink class="nav-link d-flex align-items-center">
                  <i class="ft-info mr-1"></i>
                  <span class="d-none d-sm-block">{{ t('memberships') }}</span>
                </a>
                <ng-template ngbNavContent>
                  <div class="table-responsive">
                    <ng-scrollbar
                      [autoHeightDisabled]="false"
                      visibility="hover"
                      thumbClass="white-scrollbars"
                      track="all"
                    >
                      <table class="table text-center m-0">
                        <thead>
                          <tr>
                            <th>{{ t('space') }}</th>
                            <th>{{ t('project') }}</th>
                            <th>{{ t('role') }}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <ng-container *ngFor="let space of spaces">
                            <tr *ngIf="space.roleName">
                              <td>
                                <div class="d-flex align-items-center">
                                  <app-space-avatar
                                    [containerHeight]="24"
                                    [containerWidth]="24"
                                    [space]="space"
                                    class="space-avatar mr-1"
                                  ></app-space-avatar>
                                  <div class="cell-line-height">
                                    <span class="line-height-1 mb-0">{{ space.spaceName }}</span>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div class="d-flex align-items-center">-</div>
                              </td>
                              <td>{{ space.roleName }}</td>
                            </tr>

                            <tr *ngFor="let project of space.projects">
                              <td>
                                <div class="d-flex align-items-center">
                                  <app-space-avatar
                                    [containerHeight]="24"
                                    [containerWidth]="24"
                                    [space]="project.space"
                                    class="space-avatar mr-1"
                                  ></app-space-avatar>
                                  <div class="cell-line-height table-text-left">
                                    <span class="line-height-1 mb-0">{{ space?.spaceName }}</span>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div class="d-flex align-items-center">
                                  <app-project-avatar
                                    style="width: 24px"
                                    [project]="project"
                                    class="project-avatar mr-1"
                                  ></app-project-avatar>
                                  <div class="cell-line-height table-text-left">
                                    <span class="line-height-1 mb-0">{{
                                      project.projectName
                                    }}</span>
                                  </div>
                                </div>
                              </td>
                              <td>{{ project.roleName }}</td>
                            </tr>
                          </ng-container>
                        </tbody>
                      </table>
                    </ng-scrollbar>
                  </div>
                </ng-template>
              </li>
            </ul>

            <div [ngbNavOutlet]="nav"></div>
          </div>
        </div>
      </ng-scrollbar>
    </section>
  </div>
</ng-container>
