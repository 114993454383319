export interface IPlan {
  title: string;
  attribute: string;
  price?: string;
  isCurrentPlan?: boolean;
  contactUs?: boolean;
  description?: string;
  priceSvg?: string;
  includes?: string[];
}

export enum AttributeEnum {
  Free = 'free',
  Basic = 'basic',
  Enterprise = 'enterprise',
}

export const plans: IPlan[] = [
  {
    title: 'Free',
    attribute: 'free',
    price: '0',
    description: 'Free plan',
    includes: [
      '2 spaces',
      '2 projects',
      '2 users',
      'File Storage (Limited to 2GB)',
      'All the features',
    ],
  },

  {
    title: 'Basic',
    attribute: 'basic',
    price: '10.50',
    description: 'For startups, small teams or freelancers',
    includes: [
      'Unlimited spaces',
      'Unlimited projects',
      'Unlimited users',
      'Unlimited Storage',
      'Unlimited features',
    ],
  },

  {
    title: 'Enterprise',
    attribute: 'enterprise',
    contactUs: true,
    description: 'For large teams, businesses organizations',
    priceSvg: 'assets/icons/payment/buildings.svg',
    includes: [
      'All Basic Plan features',
      'Team Onboarding Support',
      'White Labelling',
      '24/7 Support',
      'Priority Support',
      'Onboarding Training Workshop',
      'Custom Permissions',
    ],
  },
];

export const features = [
  {
    image: [
      'assets/icons/payment/space-avatar-3.svg',
      'assets/icons/payment/space-avatar-2.svg',
      'assets/icons/payment/space-avatar-1.svg',
    ],
    label: 'Unlimited spaces',
  },
  {
    image: [
      'assets/icons/payment/project-avatar-1.svg',
      'assets/icons/payment/project-avatar-2.svg',
      'assets/icons/payment/project-avatar-3.svg',
    ],
    label: 'Unlimited projects',
  },
  {
    image: [
      'assets/icons/payment/user-avatar-1.svg',
      'assets/icons/payment/user-avatar-2.svg',
      'assets/icons/payment/user-avatar-3.svg',
    ],
    label: 'Unlimited users',
  },
  {
    image: [
      'assets/icons/payment/storage-avatar-1.svg',
      'assets/icons/payment/storage-avatar-2.svg',
      'assets/icons/payment/storage-avatar-3.svg',
    ],
    label: 'Unlimited storage',
  },
];
