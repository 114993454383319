/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CommonDeleteResDto } from '../models/common-delete-res-dto';
import { TicketsFieldsBulkUpdateReqDto } from '../models/tickets-fields-bulk-update-req-dto';
import { TicketsFieldsCreateReqDto } from '../models/tickets-fields-create-req-dto';
import { TicketsFieldsDbDto } from '../models/tickets-fields-db-dto';
import { TicketsFieldsGetListResDto } from '../models/tickets-fields-get-list-res-dto';
import { TicketsFieldsUpdateReqDto } from '../models/tickets-fields-update-req-dto';

@Injectable({
  providedIn: 'root',
})
export class BoardsFieldsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation ticketsFieldsGetList
   */
  static readonly TicketsFieldsGetListPath = '/boards/fields';

  /**
   * get tickets list
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ticketsFieldsGetList()` instead.
   *
   * This method doesn't expect any request body.
   */
  ticketsFieldsGetList$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;

    /**
     * spaces | projects
     */
    object: string;

    /**
     * objectId
     */
    objectId: string;
  }): Observable<StrictHttpResponse<TicketsFieldsGetListResDto>> {
    const rb = new RequestBuilder(this.rootUrl, BoardsFieldsService.TicketsFieldsGetListPath, 'get');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.query('object', params.object, {});
      rb.query('objectId', params.objectId, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<TicketsFieldsGetListResDto>;
        }),
      );
  }

  /**
   * get tickets list
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `ticketsFieldsGetList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  ticketsFieldsGetList(params: {
    /**
     * Localization
     */
    'x-lang'?: string;

    /**
     * spaces | projects
     */
    object: string;

    /**
     * objectId
     */
    objectId: string;
  }): Observable<TicketsFieldsGetListResDto> {
    return this.ticketsFieldsGetList$Response(params).pipe(
      map((r: StrictHttpResponse<TicketsFieldsGetListResDto>) => r.body as TicketsFieldsGetListResDto),
    );
  }

  /**
   * Path part for operation ticketsFieldsCreate
   */
  static readonly TicketsFieldsCreatePath = '/boards/fields';

  /**
   * create ticket field
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ticketsFieldsCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ticketsFieldsCreate$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    body: TicketsFieldsCreateReqDto;
  }): Observable<StrictHttpResponse<TicketsFieldsDbDto>> {
    const rb = new RequestBuilder(this.rootUrl, BoardsFieldsService.TicketsFieldsCreatePath, 'post');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<TicketsFieldsDbDto>;
        }),
      );
  }

  /**
   * create ticket field
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `ticketsFieldsCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ticketsFieldsCreate(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    body: TicketsFieldsCreateReqDto;
  }): Observable<TicketsFieldsDbDto> {
    return this.ticketsFieldsCreate$Response(params).pipe(
      map((r: StrictHttpResponse<TicketsFieldsDbDto>) => r.body as TicketsFieldsDbDto),
    );
  }

  /**
   * Path part for operation ticketsFieldsBulkUpdate
   */
  static readonly TicketsFieldsBulkUpdatePath = '/boards/fields';

  /**
   * bulk update ticket field
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ticketsFieldsBulkUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ticketsFieldsBulkUpdate$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    body: TicketsFieldsBulkUpdateReqDto;
  }): Observable<StrictHttpResponse<Array<TicketsFieldsDbDto>>> {
    const rb = new RequestBuilder(this.rootUrl, BoardsFieldsService.TicketsFieldsBulkUpdatePath, 'patch');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<TicketsFieldsDbDto>>;
        }),
      );
  }

  /**
   * bulk update ticket field
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `ticketsFieldsBulkUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ticketsFieldsBulkUpdate(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    body: TicketsFieldsBulkUpdateReqDto;
  }): Observable<Array<TicketsFieldsDbDto>> {
    return this.ticketsFieldsBulkUpdate$Response(params).pipe(
      map((r: StrictHttpResponse<Array<TicketsFieldsDbDto>>) => r.body as Array<TicketsFieldsDbDto>),
    );
  }

  /**
   * Path part for operation ticketsFieldsDelete
   */
  static readonly TicketsFieldsDeletePath = '/boards/fields/{id}';

  /**
   * delete ticket field
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ticketsFieldsDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  ticketsFieldsDelete$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    id: string;
  }): Observable<StrictHttpResponse<CommonDeleteResDto>> {
    const rb = new RequestBuilder(this.rootUrl, BoardsFieldsService.TicketsFieldsDeletePath, 'delete');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.path('id', params.id, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<CommonDeleteResDto>;
        }),
      );
  }

  /**
   * delete ticket field
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `ticketsFieldsDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  ticketsFieldsDelete(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    id: string;
  }): Observable<CommonDeleteResDto> {
    return this.ticketsFieldsDelete$Response(params).pipe(
      map((r: StrictHttpResponse<CommonDeleteResDto>) => r.body as CommonDeleteResDto),
    );
  }

  /**
   * Path part for operation ticketsFieldsBulkUpdate_1
   */
  static readonly TicketsFieldsBulkUpdate_1Path = '/boards/fields/{id}';

  /**
   * update ticket field
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ticketsFieldsBulkUpdate_1()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ticketsFieldsBulkUpdate_1$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    id: string;
    body: TicketsFieldsUpdateReqDto;
  }): Observable<StrictHttpResponse<TicketsFieldsDbDto>> {
    const rb = new RequestBuilder(this.rootUrl, BoardsFieldsService.TicketsFieldsBulkUpdate_1Path, 'patch');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<TicketsFieldsDbDto>;
        }),
      );
  }

  /**
   * update ticket field
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `ticketsFieldsBulkUpdate_1$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ticketsFieldsBulkUpdate_1(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    id: string;
    body: TicketsFieldsUpdateReqDto;
  }): Observable<TicketsFieldsDbDto> {
    return this.ticketsFieldsBulkUpdate_1$Response(params).pipe(
      map((r: StrictHttpResponse<TicketsFieldsDbDto>) => r.body as TicketsFieldsDbDto),
    );
  }
}
