/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { BoardsObjectsReqDto } from '../models/boards-objects-req-dto';
import { ColumnsCreateReqDto } from '../models/columns-create-req-dto';
import { ColumnsDbDto } from '../models/columns-db-dto';
import { ColumnsOrderUpdateReqDto } from '../models/columns-order-update-req-dto';
import { ColumnsUpdateReqDto } from '../models/columns-update-req-dto';
import { CommonObjectObjectIdReqDto } from '../models/common-object-object-id-req-dto';
import { CommonSuccessResDto } from '../models/common-success-res-dto';
import { EstimationSessionsDbDto } from '../models/estimation-sessions-db-dto';
import { EstimationSessionsMembersCreateReqDto } from '../models/estimation-sessions-members-create-req-dto';
import { EstimationSessionsMembersDbDto } from '../models/estimation-sessions-members-db-dto';
import { EstimationSessionsMembersUpdateReqDto } from '../models/estimation-sessions-members-update-req-dto';
import { EstimationSessionsResDto } from '../models/estimation-sessions-res-dto';
import { EstimationSessionsUpdateReqDto } from '../models/estimation-sessions-update-req-dto';
import { TicketsFilesDbDto } from '../models/tickets-files-db-dto';
import { TicketsLabelsCreatedDto } from '../models/tickets-labels-created-dto';
import { TicketsLabelsDbDto } from '../models/tickets-labels-db-dto';
import { TicketsLabelsResDto } from '../models/tickets-labels-res-dto';
import { TicketsMembersCreateReqDto } from '../models/tickets-members-create-req-dto';
import { TicketsMembersDbDto } from '../models/tickets-members-db-dto';
import { TicketsMembersUpdateReqDto } from '../models/tickets-members-update-req-dto';
import { UsersTicketsGetListResDto } from '../models/users-tickets-get-list-res-dto';

@Injectable({
  providedIn: 'root',
})
export class BoardsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation usersTicketsGetList
   */
  static readonly UsersTicketsGetListPath = '/boards/users-tickets';

  /**
   * get users tickets list
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `usersTicketsGetList()` instead.
   *
   * This method doesn't expect any request body.
   */
  usersTicketsGetList$Response(params?: {
    /**
     * Localization
     */
    'x-lang'?: string;

    /**
     * Search string
     */
    search?: string;
  }): Observable<StrictHttpResponse<UsersTicketsGetListResDto>> {
    const rb = new RequestBuilder(this.rootUrl, BoardsService.UsersTicketsGetListPath, 'get');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.query('search', params.search, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<UsersTicketsGetListResDto>;
        }),
      );
  }

  /**
   * get users tickets list
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `usersTicketsGetList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  usersTicketsGetList(params?: {
    /**
     * Localization
     */
    'x-lang'?: string;

    /**
     * Search string
     */
    search?: string;
  }): Observable<UsersTicketsGetListResDto> {
    return this.usersTicketsGetList$Response(params).pipe(
      map((r: StrictHttpResponse<UsersTicketsGetListResDto>) => r.body as UsersTicketsGetListResDto),
    );
  }

  /**
   * Path part for operation boardsControllerGetEstimationSession
   */
  static readonly BoardsControllerGetEstimationSessionPath = '/boards/tickets/{id}/estimation-sessions';

  /**
   * get estimation sessions list
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `boardsControllerGetEstimationSession()` instead.
   *
   * This method doesn't expect any request body.
   */
  boardsControllerGetEstimationSession$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;

    /**
     * ticket id
     */
    id: string;

    /**
     * spaces | projects
     */
    object: string;

    /**
     * objectId
     */
    objectId: string;
  }): Observable<StrictHttpResponse<EstimationSessionsResDto>> {
    const rb = new RequestBuilder(this.rootUrl, BoardsService.BoardsControllerGetEstimationSessionPath, 'get');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.path('id', params.id, {});
      rb.query('object', params.object, {});
      rb.query('objectId', params.objectId, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<EstimationSessionsResDto>;
        }),
      );
  }

  /**
   * get estimation sessions list
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `boardsControllerGetEstimationSession$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  boardsControllerGetEstimationSession(params: {
    /**
     * Localization
     */
    'x-lang'?: string;

    /**
     * ticket id
     */
    id: string;

    /**
     * spaces | projects
     */
    object: string;

    /**
     * objectId
     */
    objectId: string;
  }): Observable<EstimationSessionsResDto> {
    return this.boardsControllerGetEstimationSession$Response(params).pipe(
      map((r: StrictHttpResponse<EstimationSessionsResDto>) => r.body as EstimationSessionsResDto),
    );
  }

  /**
   * Path part for operation boardsControllerCreateEstimationSession
   */
  static readonly BoardsControllerCreateEstimationSessionPath = '/boards/tickets/{id}/estimation-sessions';

  /**
   * create estimation session
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `boardsControllerCreateEstimationSession()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  boardsControllerCreateEstimationSession$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;

    /**
     * ticket id
     */
    id: string;
    body: BoardsObjectsReqDto;
  }): Observable<StrictHttpResponse<EstimationSessionsDbDto>> {
    const rb = new RequestBuilder(this.rootUrl, BoardsService.BoardsControllerCreateEstimationSessionPath, 'post');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<EstimationSessionsDbDto>;
        }),
      );
  }

  /**
   * create estimation session
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `boardsControllerCreateEstimationSession$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  boardsControllerCreateEstimationSession(params: {
    /**
     * Localization
     */
    'x-lang'?: string;

    /**
     * ticket id
     */
    id: string;
    body: BoardsObjectsReqDto;
  }): Observable<EstimationSessionsDbDto> {
    return this.boardsControllerCreateEstimationSession$Response(params).pipe(
      map((r: StrictHttpResponse<EstimationSessionsDbDto>) => r.body as EstimationSessionsDbDto),
    );
  }

  /**
   * Path part for operation boardsControllerDeleteEstimationSession
   */
  static readonly BoardsControllerDeleteEstimationSessionPath =
    '/boards/tickets/{id}/estimation-sessions/{estimationSessionId}';

  /**
   * delete estimation session
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `boardsControllerDeleteEstimationSession()` instead.
   *
   * This method doesn't expect any request body.
   */
  boardsControllerDeleteEstimationSession$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;

    /**
     * estimation session id
     */
    estimationSessionId: string;

    /**
     * spaces | projects
     */
    object: string;

    /**
     * objectId
     */
    objectId: string;

    /**
     * ticket id
     */
    id: string;
  }): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, BoardsService.BoardsControllerDeleteEstimationSessionPath, 'delete');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.path('estimationSessionId', params.estimationSessionId, {});
      rb.query('object', params.object, {});
      rb.query('objectId', params.objectId, {});
      rb.path('id', params.id, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: '*/*',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
        }),
      );
  }

  /**
   * delete estimation session
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `boardsControllerDeleteEstimationSession$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  boardsControllerDeleteEstimationSession(params: {
    /**
     * Localization
     */
    'x-lang'?: string;

    /**
     * estimation session id
     */
    estimationSessionId: string;

    /**
     * spaces | projects
     */
    object: string;

    /**
     * objectId
     */
    objectId: string;

    /**
     * ticket id
     */
    id: string;
  }): Observable<void> {
    return this.boardsControllerDeleteEstimationSession$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void),
    );
  }

  /**
   * Path part for operation boardsControllerUpdateEstimationSession
   */
  static readonly BoardsControllerUpdateEstimationSessionPath =
    '/boards/tickets/{id}/estimation-sessions/{estimationSessionId}';

  /**
   * update estimation session
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `boardsControllerUpdateEstimationSession()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  boardsControllerUpdateEstimationSession$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;

    /**
     * estimation session id
     */
    estimationSessionId: string;

    /**
     * ticket id
     */
    id: string;
    body: EstimationSessionsUpdateReqDto;
  }): Observable<StrictHttpResponse<EstimationSessionsDbDto>> {
    const rb = new RequestBuilder(this.rootUrl, BoardsService.BoardsControllerUpdateEstimationSessionPath, 'patch');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.path('estimationSessionId', params.estimationSessionId, {});
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<EstimationSessionsDbDto>;
        }),
      );
  }

  /**
   * update estimation session
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `boardsControllerUpdateEstimationSession$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  boardsControllerUpdateEstimationSession(params: {
    /**
     * Localization
     */
    'x-lang'?: string;

    /**
     * estimation session id
     */
    estimationSessionId: string;

    /**
     * ticket id
     */
    id: string;
    body: EstimationSessionsUpdateReqDto;
  }): Observable<EstimationSessionsDbDto> {
    return this.boardsControllerUpdateEstimationSession$Response(params).pipe(
      map((r: StrictHttpResponse<EstimationSessionsDbDto>) => r.body as EstimationSessionsDbDto),
    );
  }

  /**
   * Path part for operation boardsControllerCreateEstimationSessionMember
   */
  static readonly BoardsControllerCreateEstimationSessionMemberPath =
    '/boards/tickets/{id}/estimation-sessions/members';

  /**
   * create estimation session member
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `boardsControllerCreateEstimationSessionMember()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  boardsControllerCreateEstimationSessionMember$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;

    /**
     * ticket id
     */
    id: string;
    body: EstimationSessionsMembersCreateReqDto;
  }): Observable<StrictHttpResponse<EstimationSessionsMembersDbDto>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      BoardsService.BoardsControllerCreateEstimationSessionMemberPath,
      'post',
    );
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<EstimationSessionsMembersDbDto>;
        }),
      );
  }

  /**
   * create estimation session member
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `boardsControllerCreateEstimationSessionMember$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  boardsControllerCreateEstimationSessionMember(params: {
    /**
     * Localization
     */
    'x-lang'?: string;

    /**
     * ticket id
     */
    id: string;
    body: EstimationSessionsMembersCreateReqDto;
  }): Observable<EstimationSessionsMembersDbDto> {
    return this.boardsControllerCreateEstimationSessionMember$Response(params).pipe(
      map((r: StrictHttpResponse<EstimationSessionsMembersDbDto>) => r.body as EstimationSessionsMembersDbDto),
    );
  }

  /**
   * Path part for operation boardsControllerDeleteEstimationSessionMember
   */
  static readonly BoardsControllerDeleteEstimationSessionMemberPath =
    '/boards/tickets/{id}/estimation-sessions/members/{memberId}';

  /**
   * update estimation session member
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `boardsControllerDeleteEstimationSessionMember()` instead.
   *
   * This method doesn't expect any request body.
   */
  boardsControllerDeleteEstimationSessionMember$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;

    /**
     * ticket id
     */
    id: string;

    /**
     * estimation session member id
     */
    memberId: string;
  }): Observable<StrictHttpResponse<EstimationSessionsMembersDbDto>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      BoardsService.BoardsControllerDeleteEstimationSessionMemberPath,
      'delete',
    );
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.path('id', params.id, {});
      rb.path('memberId', params.memberId, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<EstimationSessionsMembersDbDto>;
        }),
      );
  }

  /**
   * update estimation session member
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `boardsControllerDeleteEstimationSessionMember$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  boardsControllerDeleteEstimationSessionMember(params: {
    /**
     * Localization
     */
    'x-lang'?: string;

    /**
     * ticket id
     */
    id: string;

    /**
     * estimation session member id
     */
    memberId: string;
  }): Observable<EstimationSessionsMembersDbDto> {
    return this.boardsControllerDeleteEstimationSessionMember$Response(params).pipe(
      map((r: StrictHttpResponse<EstimationSessionsMembersDbDto>) => r.body as EstimationSessionsMembersDbDto),
    );
  }

  /**
   * Path part for operation boardsControllerUpdateEstimationSessionMember
   */
  static readonly BoardsControllerUpdateEstimationSessionMemberPath =
    '/boards/tickets/{id}/estimation-sessions/members/{memberId}';

  /**
   * update estimation session member
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `boardsControllerUpdateEstimationSessionMember()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  boardsControllerUpdateEstimationSessionMember$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;

    /**
     * ticket id
     */
    id: string;

    /**
     * estimation session member id
     */
    memberId: string;
    body: EstimationSessionsMembersUpdateReqDto;
  }): Observable<StrictHttpResponse<EstimationSessionsMembersDbDto>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      BoardsService.BoardsControllerUpdateEstimationSessionMemberPath,
      'patch',
    );
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.path('id', params.id, {});
      rb.path('memberId', params.memberId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<EstimationSessionsMembersDbDto>;
        }),
      );
  }

  /**
   * update estimation session member
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `boardsControllerUpdateEstimationSessionMember$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  boardsControllerUpdateEstimationSessionMember(params: {
    /**
     * Localization
     */
    'x-lang'?: string;

    /**
     * ticket id
     */
    id: string;

    /**
     * estimation session member id
     */
    memberId: string;
    body: EstimationSessionsMembersUpdateReqDto;
  }): Observable<EstimationSessionsMembersDbDto> {
    return this.boardsControllerUpdateEstimationSessionMember$Response(params).pipe(
      map((r: StrictHttpResponse<EstimationSessionsMembersDbDto>) => r.body as EstimationSessionsMembersDbDto),
    );
  }

  /**
   * Path part for operation columnsGetList
   */
  static readonly ColumnsGetListPath = '/boards/columns';

  /**
   * get columns list
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `columnsGetList()` instead.
   *
   * This method doesn't expect any request body.
   */
  columnsGetList$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;

    /**
     * spaces | projects
     */
    object: string;

    /**
     * objectId
     */
    objectId: string;
  }): Observable<StrictHttpResponse<Array<ColumnsDbDto>>> {
    const rb = new RequestBuilder(this.rootUrl, BoardsService.ColumnsGetListPath, 'get');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.query('object', params.object, {});
      rb.query('objectId', params.objectId, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<ColumnsDbDto>>;
        }),
      );
  }

  /**
   * get columns list
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `columnsGetList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  columnsGetList(params: {
    /**
     * Localization
     */
    'x-lang'?: string;

    /**
     * spaces | projects
     */
    object: string;

    /**
     * objectId
     */
    objectId: string;
  }): Observable<Array<ColumnsDbDto>> {
    return this.columnsGetList$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ColumnsDbDto>>) => r.body as Array<ColumnsDbDto>),
    );
  }

  /**
   * Path part for operation columnsCreate
   */
  static readonly ColumnsCreatePath = '/boards/columns';

  /**
   * create columns
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `columnsCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  columnsCreate$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    body: ColumnsCreateReqDto;
  }): Observable<StrictHttpResponse<ColumnsDbDto>> {
    const rb = new RequestBuilder(this.rootUrl, BoardsService.ColumnsCreatePath, 'post');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<ColumnsDbDto>;
        }),
      );
  }

  /**
   * create columns
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `columnsCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  columnsCreate(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    body: ColumnsCreateReqDto;
  }): Observable<ColumnsDbDto> {
    return this.columnsCreate$Response(params).pipe(
      map((r: StrictHttpResponse<ColumnsDbDto>) => r.body as ColumnsDbDto),
    );
  }

  /**
   * Path part for operation boardsControllerColumnsOrderUpdate
   */
  static readonly BoardsControllerColumnsOrderUpdatePath = '/boards/columns/order';

  /**
   * update columns order
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `boardsControllerColumnsOrderUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  boardsControllerColumnsOrderUpdate$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    body: ColumnsOrderUpdateReqDto;
  }): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, BoardsService.BoardsControllerColumnsOrderUpdatePath, 'patch');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: '*/*',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
        }),
      );
  }

  /**
   * update columns order
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `boardsControllerColumnsOrderUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  boardsControllerColumnsOrderUpdate(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    body: ColumnsOrderUpdateReqDto;
  }): Observable<void> {
    return this.boardsControllerColumnsOrderUpdate$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void),
    );
  }

  /**
   * Path part for operation columnsDelete
   */
  static readonly ColumnsDeletePath = '/boards/columns/{id}';

  /**
   * soft delete columns
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `columnsDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  columnsDelete$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    id: string;
  }): Observable<StrictHttpResponse<ColumnsDbDto>> {
    const rb = new RequestBuilder(this.rootUrl, BoardsService.ColumnsDeletePath, 'delete');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.path('id', params.id, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<ColumnsDbDto>;
        }),
      );
  }

  /**
   * soft delete columns
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `columnsDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  columnsDelete(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    id: string;
  }): Observable<ColumnsDbDto> {
    return this.columnsDelete$Response(params).pipe(
      map((r: StrictHttpResponse<ColumnsDbDto>) => r.body as ColumnsDbDto),
    );
  }

  /**
   * Path part for operation columnsUpdate
   */
  static readonly ColumnsUpdatePath = '/boards/columns/{id}';

  /**
   * update columns
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `columnsUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  columnsUpdate$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    id: string;
    body: ColumnsUpdateReqDto;
  }): Observable<StrictHttpResponse<ColumnsDbDto>> {
    const rb = new RequestBuilder(this.rootUrl, BoardsService.ColumnsUpdatePath, 'patch');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<ColumnsDbDto>;
        }),
      );
  }

  /**
   * update columns
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `columnsUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  columnsUpdate(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    id: string;
    body: ColumnsUpdateReqDto;
  }): Observable<ColumnsDbDto> {
    return this.columnsUpdate$Response(params).pipe(
      map((r: StrictHttpResponse<ColumnsDbDto>) => r.body as ColumnsDbDto),
    );
  }

  /**
   * Path part for operation ticketsToArchive
   */
  static readonly TicketsToArchivePath = '/boards/tickets-to-archive';

  /**
   * update tickets
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ticketsToArchive()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ticketsToArchive$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    body: CommonObjectObjectIdReqDto;
  }): Observable<StrictHttpResponse<CommonSuccessResDto>> {
    const rb = new RequestBuilder(this.rootUrl, BoardsService.TicketsToArchivePath, 'patch');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<CommonSuccessResDto>;
        }),
      );
  }

  /**
   * update tickets
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `ticketsToArchive$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ticketsToArchive(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    body: CommonObjectObjectIdReqDto;
  }): Observable<CommonSuccessResDto> {
    return this.ticketsToArchive$Response(params).pipe(
      map((r: StrictHttpResponse<CommonSuccessResDto>) => r.body as CommonSuccessResDto),
    );
  }

  /**
   * Path part for operation ticketsMembersCreate
   */
  static readonly TicketsMembersCreatePath = '/boards/tickets-members';

  /**
   * create ticket-member
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ticketsMembersCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ticketsMembersCreate$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    body: TicketsMembersCreateReqDto;
  }): Observable<StrictHttpResponse<Array<TicketsMembersDbDto>>> {
    const rb = new RequestBuilder(this.rootUrl, BoardsService.TicketsMembersCreatePath, 'post');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<TicketsMembersDbDto>>;
        }),
      );
  }

  /**
   * create ticket-member
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `ticketsMembersCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ticketsMembersCreate(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    body: TicketsMembersCreateReqDto;
  }): Observable<Array<TicketsMembersDbDto>> {
    return this.ticketsMembersCreate$Response(params).pipe(
      map((r: StrictHttpResponse<Array<TicketsMembersDbDto>>) => r.body as Array<TicketsMembersDbDto>),
    );
  }

  /**
   * Path part for operation ticketsMembersGetList
   */
  static readonly TicketsMembersGetListPath = '/boards/tickets-members/{id}';

  /**
   * get ticket members list
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ticketsMembersGetList()` instead.
   *
   * This method doesn't expect any request body.
   */
  ticketsMembersGetList$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    id: string;
  }): Observable<StrictHttpResponse<Array<TicketsMembersDbDto>>> {
    const rb = new RequestBuilder(this.rootUrl, BoardsService.TicketsMembersGetListPath, 'get');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.path('id', params.id, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<TicketsMembersDbDto>>;
        }),
      );
  }

  /**
   * get ticket members list
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `ticketsMembersGetList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  ticketsMembersGetList(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    id: string;
  }): Observable<Array<TicketsMembersDbDto>> {
    return this.ticketsMembersGetList$Response(params).pipe(
      map((r: StrictHttpResponse<Array<TicketsMembersDbDto>>) => r.body as Array<TicketsMembersDbDto>),
    );
  }

  /**
   * Path part for operation ticketsMembersUpdate
   */
  static readonly TicketsMembersUpdatePath = '/boards/tickets-members/{id}';

  /**
   * update ticket-member
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ticketsMembersUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ticketsMembersUpdate$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    id: string;
    body: TicketsMembersUpdateReqDto;
  }): Observable<StrictHttpResponse<Array<TicketsMembersDbDto>>> {
    const rb = new RequestBuilder(this.rootUrl, BoardsService.TicketsMembersUpdatePath, 'patch');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<TicketsMembersDbDto>>;
        }),
      );
  }

  /**
   * update ticket-member
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `ticketsMembersUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ticketsMembersUpdate(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    id: string;
    body: TicketsMembersUpdateReqDto;
  }): Observable<Array<TicketsMembersDbDto>> {
    return this.ticketsMembersUpdate$Response(params).pipe(
      map((r: StrictHttpResponse<Array<TicketsMembersDbDto>>) => r.body as Array<TicketsMembersDbDto>),
    );
  }

  /**
   * Path part for operation ticketsFilesUpload
   */
  static readonly TicketsFilesUploadPath = '/boards/tickets-files';

  /**
   * uploads files to ticket
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ticketsFilesUpload()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  ticketsFilesUpload$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    body: {
      file?: Blob;
    };
  }): Observable<StrictHttpResponse<TicketsFilesDbDto>> {
    const rb = new RequestBuilder(this.rootUrl, BoardsService.TicketsFilesUploadPath, 'post');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<TicketsFilesDbDto>;
        }),
      );
  }

  /**
   * uploads files to ticket
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `ticketsFilesUpload$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  ticketsFilesUpload(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    body: {
      file?: Blob;
    };
  }): Observable<TicketsFilesDbDto> {
    return this.ticketsFilesUpload$Response(params).pipe(
      map((r: StrictHttpResponse<TicketsFilesDbDto>) => r.body as TicketsFilesDbDto),
    );
  }

  /**
   * Path part for operation ticketsFilesDelete
   */
  static readonly TicketsFilesDeletePath = '/boards/tickets-files/{id}';

  /**
   * delete ticket-file
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ticketsFilesDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  ticketsFilesDelete$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    id: string;
  }): Observable<StrictHttpResponse<CommonSuccessResDto>> {
    const rb = new RequestBuilder(this.rootUrl, BoardsService.TicketsFilesDeletePath, 'delete');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.path('id', params.id, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<CommonSuccessResDto>;
        }),
      );
  }

  /**
   * delete ticket-file
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `ticketsFilesDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  ticketsFilesDelete(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    id: string;
  }): Observable<CommonSuccessResDto> {
    return this.ticketsFilesDelete$Response(params).pipe(
      map((r: StrictHttpResponse<CommonSuccessResDto>) => r.body as CommonSuccessResDto),
    );
  }

  /**
   * Path part for operation ticketsLabelsGetList
   */
  static readonly TicketsLabelsGetListPath = '/boards/tickets-label';

  /**
   * get all tickets labels
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ticketsLabelsGetList()` instead.
   *
   * This method doesn't expect any request body.
   */
  ticketsLabelsGetList$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;

    /**
     * spaces | projects
     */
    object: string;

    /**
     * objectId
     */
    objectId: string;
  }): Observable<StrictHttpResponse<Array<TicketsLabelsResDto>>> {
    const rb = new RequestBuilder(this.rootUrl, BoardsService.TicketsLabelsGetListPath, 'get');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.query('object', params.object, {});
      rb.query('objectId', params.objectId, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<TicketsLabelsResDto>>;
        }),
      );
  }

  /**
   * get all tickets labels
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `ticketsLabelsGetList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  ticketsLabelsGetList(params: {
    /**
     * Localization
     */
    'x-lang'?: string;

    /**
     * spaces | projects
     */
    object: string;

    /**
     * objectId
     */
    objectId: string;
  }): Observable<Array<TicketsLabelsResDto>> {
    return this.ticketsLabelsGetList$Response(params).pipe(
      map((r: StrictHttpResponse<Array<TicketsLabelsResDto>>) => r.body as Array<TicketsLabelsResDto>),
    );
  }

  /**
   * Path part for operation ticketsLabelsCreate
   */
  static readonly TicketsLabelsCreatePath = '/boards/tickets-label';

  /**
   * creates a tickets label
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ticketsLabelsCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ticketsLabelsCreate$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    body: TicketsLabelsCreatedDto;
  }): Observable<StrictHttpResponse<TicketsLabelsDbDto>> {
    const rb = new RequestBuilder(this.rootUrl, BoardsService.TicketsLabelsCreatePath, 'post');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<TicketsLabelsDbDto>;
        }),
      );
  }

  /**
   * creates a tickets label
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `ticketsLabelsCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ticketsLabelsCreate(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    body: TicketsLabelsCreatedDto;
  }): Observable<TicketsLabelsDbDto> {
    return this.ticketsLabelsCreate$Response(params).pipe(
      map((r: StrictHttpResponse<TicketsLabelsDbDto>) => r.body as TicketsLabelsDbDto),
    );
  }

  /**
   * Path part for operation ticketsLabelsDelete
   */
  static readonly TicketsLabelsDeletePath = '/boards/tickets-label/{id}';

  /**
   * deletes a tickets label
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ticketsLabelsDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  ticketsLabelsDelete$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    id: string;
  }): Observable<StrictHttpResponse<CommonSuccessResDto>> {
    const rb = new RequestBuilder(this.rootUrl, BoardsService.TicketsLabelsDeletePath, 'delete');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.path('id', params.id, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<CommonSuccessResDto>;
        }),
      );
  }

  /**
   * deletes a tickets label
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `ticketsLabelsDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  ticketsLabelsDelete(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    id: string;
  }): Observable<CommonSuccessResDto> {
    return this.ticketsLabelsDelete$Response(params).pipe(
      map((r: StrictHttpResponse<CommonSuccessResDto>) => r.body as CommonSuccessResDto),
    );
  }

  /**
   * Path part for operation ticketsLabelsUpdate
   */
  static readonly TicketsLabelsUpdatePath = '/boards/tickets-label/{id}';

  /**
   * updates a tickets label
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ticketsLabelsUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ticketsLabelsUpdate$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    id: string;
    body: TicketsLabelsCreatedDto;
  }): Observable<StrictHttpResponse<TicketsLabelsDbDto>> {
    const rb = new RequestBuilder(this.rootUrl, BoardsService.TicketsLabelsUpdatePath, 'patch');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<TicketsLabelsDbDto>;
        }),
      );
  }

  /**
   * updates a tickets label
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `ticketsLabelsUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ticketsLabelsUpdate(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    id: string;
    body: TicketsLabelsCreatedDto;
  }): Observable<TicketsLabelsDbDto> {
    return this.ticketsLabelsUpdate$Response(params).pipe(
      map((r: StrictHttpResponse<TicketsLabelsDbDto>) => r.body as TicketsLabelsDbDto),
    );
  }
}
