/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { SubscriptionsResDto } from '../models/subscriptions-res-dto';
import { SubscriptionsTypesResDto } from '../models/subscriptions-types-res-dto';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation subscriptionGet
   */
  static readonly SubscriptionGetPath = '/subscriptions';

  /**
   * get subscription
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `subscriptionGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  subscriptionGet$Response(params?: {}): Observable<StrictHttpResponse<SubscriptionsResDto>> {
    const rb = new RequestBuilder(this.rootUrl, SubscriptionsService.SubscriptionGetPath, 'get');
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<SubscriptionsResDto>;
        }),
      );
  }

  /**
   * get subscription
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `subscriptionGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  subscriptionGet(params?: {}): Observable<SubscriptionsResDto> {
    return this.subscriptionGet$Response(params).pipe(
      map((r: StrictHttpResponse<SubscriptionsResDto>) => r.body as SubscriptionsResDto),
    );
  }

  /**
   * Path part for operation subscriptionDelete
   */
  static readonly SubscriptionDeletePath = '/subscriptions/{id}';

  /**
   * delete subscription
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `subscriptionDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  subscriptionDelete$Response(params: { id: string }): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, SubscriptionsService.SubscriptionDeletePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: '*/*',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
        }),
      );
  }

  /**
   * delete subscription
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `subscriptionDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  subscriptionDelete(params: { id: string }): Observable<void> {
    return this.subscriptionDelete$Response(params).pipe(map((r: StrictHttpResponse<void>) => r.body as void));
  }

  /**
   * Path part for operation subscriptionCancel
   */
  static readonly SubscriptionCancelPath = '/subscriptions/cancel';

  /**
   * cancel subscription
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `subscriptionCancel()` instead.
   *
   * This method doesn't expect any request body.
   */
  subscriptionCancel$Response(params?: {}): Observable<StrictHttpResponse<SubscriptionsResDto>> {
    const rb = new RequestBuilder(this.rootUrl, SubscriptionsService.SubscriptionCancelPath, 'post');
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<SubscriptionsResDto>;
        }),
      );
  }

  /**
   * cancel subscription
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `subscriptionCancel$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  subscriptionCancel(params?: {}): Observable<SubscriptionsResDto> {
    return this.subscriptionCancel$Response(params).pipe(
      map((r: StrictHttpResponse<SubscriptionsResDto>) => r.body as SubscriptionsResDto),
    );
  }

  /**
   * Path part for operation subscriptionGetTypes
   */
  static readonly SubscriptionGetTypesPath = '/subscriptions/types';

  /**
   * get subscription types
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `subscriptionGetTypes()` instead.
   *
   * This method doesn't expect any request body.
   */
  subscriptionGetTypes$Response(params?: {}): Observable<StrictHttpResponse<Array<SubscriptionsTypesResDto>>> {
    const rb = new RequestBuilder(this.rootUrl, SubscriptionsService.SubscriptionGetTypesPath, 'get');
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<SubscriptionsTypesResDto>>;
        }),
      );
  }

  /**
   * get subscription types
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `subscriptionGetTypes$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  subscriptionGetTypes(params?: {}): Observable<Array<SubscriptionsTypesResDto>> {
    return this.subscriptionGetTypes$Response(params).pipe(
      map((r: StrictHttpResponse<Array<SubscriptionsTypesResDto>>) => r.body as Array<SubscriptionsTypesResDto>),
    );
  }
}
