import { Component, Input } from '@angular/core';
import { ConfigService, ITemplateConfig } from '../../../../shared/services/config.service';
import { NgFor, NgOptimizedImage } from '@angular/common';
import { TranslocoDirective } from '@ngneat/transloco';

@Component({
  selector: 'app-payment-feature-card',
  templateUrl: './payment-feature-card.component.html',
  styleUrls: ['./payment-feature-card.component.scss'],
  standalone: true,
  imports: [TranslocoDirective, NgFor, NgOptimizedImage],
})
export class PaymentFeatureCardComponent {
  @Input() feature: { image: string[]; label: string };
  public readonly infinitySvg = 'assets/icons/payment/infinity.svg';
  private config: ITemplateConfig;

  constructor(private readonly configService: ConfigService) {
    this.config = this.configService.templateConf;
  }

  // --------- Getters --------
  public get isDark(): boolean {
    return this.config?.layout.variant === 'Dark';
  }
}
