import { ChangeDetectorRef, Component, OnDestroy, OnInit, forwardRef } from '@angular/core';
import {
  FormControl,
  FormGroup,
  Validators,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { Store } from '@ngxs/store';
import {
  NgbActiveModal,
  NgbNav,
  NgbNavItem,
  NgbNavItemRole,
  NgbNavLink,
  NgbNavLinkBase,
  NgbNavContent,
  NgbTooltip,
  NgbNavOutlet,
} from '@ng-bootstrap/ng-bootstrap';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { map, takeUntil } from 'rxjs/operators';
import { forkJoin, Subject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import moment from 'moment-timezone';
import { TranslocoService, TranslocoDirective, TranslocoPipe } from '@ngneat/transloco';

import { HtmlHelper } from '../../shared/utils/html-helper';
import { DateTimeHelper } from '../../shared/utils/date-time-helper';
import { SpacesService, ProjectsService } from '../../api/services';
import { UsersState } from '../../shared/store/states/users.state';
import { SocketsService } from '../../shared/services/sockets.service';
import { AuthState } from '../../shared/store/states/auth.state';
import { ConfigService } from '../../shared/services/config.service';
import { LocalStorageService } from 'ngx-localstorage';
import { LangCodes } from '../../types/lang-codes.enum';
import { LocalStorageKeys } from '../../types/local-storage-keys.enum';
import { EnvironmentService } from '../../shared/services/environment.service';
import { UserNameAlonePipe } from '../../standalone/pipes/user-name.pipe';
import { UserPlanComponent } from './components/user-plan/user-plan.component';
import { ProjectAvatarComponent } from '../../shared/components/space-projects/project-avatar/project-avatar.component';
import { SpaceAvatarComponent } from '../../shared/components/space-projects/space-avatar/space-avatar.component';
import { SvgIconComponent } from 'angular-svg-icon';
import { DateTimePickerComponent } from '../../shared/components/date-time-picker/date-time-picker.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { CustomSpinnerComponent } from '../../shared/components/custom-spinner/custom-spinner.component';
import { AvatarComponent } from '../../standalone/components/avatar/avatar.component';
import { NgIf, NgClass, NgFor, AsyncPipe, DatePipe } from '@angular/common';
import { NgScrollbar } from 'ngx-scrollbar';
import { ImageCropperComponent } from '../../shared/components/image-cropper/image-cropper.component';
import { SvgComponent } from '../../shared/svgs/svg/svg.component';
import { RouterLink } from '@angular/router';
import { RouterTenantPipe } from '../../shared/pipes/router-tenant.pipe';
import { TmpTooltipDirective } from '../../standalone/components/tmp-tooltip/tmp-tooltip.derective';

@Component({
  selector: 'app-user-view-modal',
  templateUrl: './user-view.component.html',
  styleUrls: ['./user-view.component.scss'],
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: {
        wheelPropagation: false,
      },
    },
  ],
  standalone: true,
  imports: [
    TranslocoDirective,
    SvgComponent,
    ImageCropperComponent,
    NgScrollbar,
    NgbNav,
    NgbNavItem,
    NgbNavItemRole,
    NgbNavLink,
    NgbNavLinkBase,
    NgbNavContent,
    NgIf,
    FormsModule,
    ReactiveFormsModule,
    forwardRef(() => AvatarComponent),
    NgClass,
    CustomSpinnerComponent,
    NgSelectModule,
    DateTimePickerComponent,
    NgbTooltip,
    SvgIconComponent,
    NgFor,
    SpaceAvatarComponent,
    ProjectAvatarComponent,
    UserPlanComponent,
    NgbNavOutlet,
    AsyncPipe,
    DatePipe,
    TranslocoPipe,
    UserNameAlonePipe,
    RouterLink,
    RouterTenantPipe,
    TmpTooltipDirective,
  ],
})
export class UserViewComponent implements OnInit, OnDestroy {
  user: any;
  _id: string;
  spaces: any[];
  localTime = null;
  hoursDiff = null;
  timeInterval: any;

  destroy$: Subject<void> = new Subject<void>();
  userForm: FormGroup;
  isAvatarImageUploading: boolean;
  isMyself = false;
  platform: string;
  isDark = false;

  constructor(
    public dtHelper: DateTimeHelper,
    private htmlHelper: HtmlHelper,
    private cdr: ChangeDetectorRef,
    private activeModal: NgbActiveModal,
    private store: Store,
    private socketsService: SocketsService,
    private projectsService: ProjectsService,
    private spacesService: SpacesService,
    private toastrService: ToastrService,
    private translocoService: TranslocoService,
    private configService: ConfigService,
    private localStorage: LocalStorageService,
    private environmentService: EnvironmentService,
  ) {}
  /**
   * Subscribe to display modal action
   */
  ngOnInit() {
    // Load languages
    this.translocoService.load('de').pipe(takeUntil(this.destroy$)).subscribe();
    this.translocoService.load('en').pipe(takeUntil(this.destroy$)).subscribe();
    this.configService.templateConf$.pipe(takeUntil(this.destroy$)).subscribe((templateConf) => {
      if (templateConf) {
        this.isDark = templateConf.layout.variant !== 'Light';
        this.cdr.detectChanges();
      }
    });

    this.initForm();
    this.setUser();
    this.getPlatform();
  }

  ngOnDestroy() {
    clearInterval(this.timeInterval);
    this.destroy$.next();
    this.destroy$.complete();
  }

  get titleModal(): string {
    return this.user?.isAssistant
      ? this.translocoService.translate('modals.user-view.bot-profile')
      : this.translocoService.translate('modals.user-view.user-profile');
  }

  getPlatform(): void {
    this.platform = this.store.selectSnapshot(AuthState.getPlatform);
  }

  get isAssistant() {
    return !!this.user?.isAssistant;
  }

  setActiveLanguage(language: string) {
    const conf = this.configService.templateConf;
    this.configService.applyTemplateConfigChange({
      layout: conf.layout,
      language,
    });
    if (language) {
      this.translocoService.setActiveLang(language);
    }
  }

  displayTime() {
    const tz = this.user.timezone;
    this.localTime = this.dtHelper.getHoursForTz(tz);
    this.hoursDiff = this.dtHelper.getTzsDiffInHours(tz);
    this.cdr.markForCheck();
  }

  getSpaceRoleName(space: any): string {
    return space.role?.roleName
      ? space.role?.roleName
      : space.ownerId === this.user._id
        ? this.translocoService.translate('modals.user-view.owner')
        : null;
  }

  getProjectRoleName(space: any, project: any): string {
    return (
      project.role?.roleName ||
      space.role?.roleName ||
      this.translocoService.translate('modals.user-view.owner')
    );
  }

  /**
   * Close edit modal handler
   */
  close() {
    this.activeModal.close();
  }

  get isProduction(): boolean {
    return this.environmentService.isProduction;
  }
  get isPaymentActive(): boolean {
    return this.environmentService.isPayment;
  }

  copyEmail() {
    this.htmlHelper.copyValueToBuffer(this.userForm.value.email);
    this.toastrService.success(
      this.translocoService.translate('toastr.email-address-copied'),
      this.translocoService.translate('toastr.title-success'),
    );
  }

  viewDirectChat(userId): void {
    this.socketsService.get().emit('chats:getDirectChatIdByUserId', { userId });
    this.activeModal.close();
  }

  initForm(): void {
    this.userForm = new FormGroup({
      userName: new FormControl('', [Validators.required]),
      job: new FormControl('', [Validators.maxLength(30)]),
      department: new FormControl('', [Validators.maxLength(30)]),
      name: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required, Validators.email]),
    });
  }

  setUser(): void {
    this.store
      .select(UsersState.getUser)
      .pipe(
        takeUntil(this.destroy$),
        map((filterFn) => filterFn(this._id)),
      )
      .subscribe((user) => {
        if (user && user._id) {
          this.user = user;

          if (user.timezone) {
            this.localTime = this.dtHelper.getHoursForTz(user.timezone);
            this.hoursDiff = this.dtHelper.getTzsDiffInHours(user.timezone);
            this.timeInterval = setInterval(() => this.displayTime(), 10000);
          }

          this.userForm.patchValue({
            userName: user.userName,
            name: user?.name,
            email: user.email,
            timezone: user.timezone,
            job: user.job,
            department: user.department,
            state: user.state,
            state_end: user.state_end,
          });

          this.store
            .select(UsersState.getUserAvatarImageUploadLoading)
            .pipe(
              takeUntil(this.destroy$),
              map((filterFn) => filterFn(this.user?._id)),
            )
            .subscribe((res) => (this.isAvatarImageUploading = res));

          this.getProjectsAndSpaces();
        }
      });
  }

  getProjectsAndSpaces(): void {
    const requests = [
      this.projectsService.projectGetProjectsListByUserId({
        userId: this.user._id,
      }),
      this.spacesService.spacesGetSpacesListByUserId({ userId: this.user._id }),
    ];

    forkJoin(requests)
      .pipe(takeUntil(this.destroy$))
      .subscribe(([projectsData, spacesData]) => {
        this.spaces = spacesData['results'].map((space) => ({
          ...space,
          roleName: this.getSpaceRoleName(space),
          projects: projectsData['results']
            .filter((p) => p.spaceId === space._id)
            .map((p) => ({
              ...p,
              roleName: this.getProjectRoleName(space, p),
            })),
        }));
      });
  }
}
