/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CommonSuccessResDto } from '../models/common-success-res-dto';
import { WebhookChatMessageReqDto } from '../models/webhook-chat-message-req-dto';
import { WebhookCreateUpdateReqDto } from '../models/webhook-create-update-req-dto';
import { WebhooksDbDto } from '../models/webhooks-db-dto';

@Injectable({
  providedIn: 'root',
})
export class WebhooksService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation webhookByIdGet
   */
  static readonly WebhookByIdGetPath = '/webhooks/webhook/{id}';

  /**
   * get webhook by id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `webhookByIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  webhookByIdGet$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    id: string;
  }): Observable<StrictHttpResponse<WebhooksDbDto>> {
    const rb = new RequestBuilder(this.rootUrl, WebhooksService.WebhookByIdGetPath, 'get');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.path('id', params.id, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<WebhooksDbDto>;
        }),
      );
  }

  /**
   * get webhook by id
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `webhookByIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  webhookByIdGet(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    id: string;
  }): Observable<WebhooksDbDto> {
    return this.webhookByIdGet$Response(params).pipe(
      map((r: StrictHttpResponse<WebhooksDbDto>) => r.body as WebhooksDbDto),
    );
  }

  /**
   * Path part for operation webhookDelete
   */
  static readonly WebhookDeletePath = '/webhooks/webhook/{id}';

  /**
   * delete webhook
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `webhookDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  webhookDelete$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    id: string;
  }): Observable<StrictHttpResponse<CommonSuccessResDto>> {
    const rb = new RequestBuilder(this.rootUrl, WebhooksService.WebhookDeletePath, 'delete');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.path('id', params.id, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<CommonSuccessResDto>;
        }),
      );
  }

  /**
   * delete webhook
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `webhookDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  webhookDelete(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    id: string;
  }): Observable<CommonSuccessResDto> {
    return this.webhookDelete$Response(params).pipe(
      map((r: StrictHttpResponse<CommonSuccessResDto>) => r.body as CommonSuccessResDto),
    );
  }

  /**
   * Path part for operation webhookUpdate
   */
  static readonly WebhookUpdatePath = '/webhooks/webhook/{id}';

  /**
   * update the webhook of the chat
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `webhookUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  webhookUpdate$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    id: string;
    body: WebhookCreateUpdateReqDto;
  }): Observable<StrictHttpResponse<WebhooksDbDto>> {
    const rb = new RequestBuilder(this.rootUrl, WebhooksService.WebhookUpdatePath, 'patch');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<WebhooksDbDto>;
        }),
      );
  }

  /**
   * update the webhook of the chat
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `webhookUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  webhookUpdate(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    id: string;
    body: WebhookCreateUpdateReqDto;
  }): Observable<WebhooksDbDto> {
    return this.webhookUpdate$Response(params).pipe(
      map((r: StrictHttpResponse<WebhooksDbDto>) => r.body as WebhooksDbDto),
    );
  }

  /**
   * Path part for operation webhookChatByIdGet
   */
  static readonly WebhookChatByIdGetPath = '/webhooks/for-chat/{id}';

  /**
   * get the webhooks by the chat id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `webhookChatByIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  webhookChatByIdGet$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    id: string;
  }): Observable<StrictHttpResponse<Array<WebhooksDbDto>>> {
    const rb = new RequestBuilder(this.rootUrl, WebhooksService.WebhookChatByIdGetPath, 'get');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.path('id', params.id, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<WebhooksDbDto>>;
        }),
      );
  }

  /**
   * get the webhooks by the chat id
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `webhookChatByIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  webhookChatByIdGet(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    id: string;
  }): Observable<Array<WebhooksDbDto>> {
    return this.webhookChatByIdGet$Response(params).pipe(
      map((r: StrictHttpResponse<Array<WebhooksDbDto>>) => r.body as Array<WebhooksDbDto>),
    );
  }

  /**
   * Path part for operation webhookCreate
   */
  static readonly WebhookCreatePath = '/webhooks/webhook';

  /**
   * create a webhook for a chat
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `webhookCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  webhookCreate$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    body: WebhookCreateUpdateReqDto;
  }): Observable<StrictHttpResponse<WebhooksDbDto>> {
    const rb = new RequestBuilder(this.rootUrl, WebhooksService.WebhookCreatePath, 'post');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<WebhooksDbDto>;
        }),
      );
  }

  /**
   * create a webhook for a chat
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `webhookCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  webhookCreate(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    body: WebhookCreateUpdateReqDto;
  }): Observable<WebhooksDbDto> {
    return this.webhookCreate$Response(params).pipe(
      map((r: StrictHttpResponse<WebhooksDbDto>) => r.body as WebhooksDbDto),
    );
  }

  /**
   * Path part for operation webhookSendMessage
   */
  static readonly WebhookSendMessagePath = '/webhooks/messages/{hookId}/{secretKey}';

  /**
   * Webhook send message
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `webhookSendMessage()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  webhookSendMessage$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    body: WebhookChatMessageReqDto;
  }): Observable<StrictHttpResponse<CommonSuccessResDto>> {
    const rb = new RequestBuilder(this.rootUrl, WebhooksService.WebhookSendMessagePath, 'post');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<CommonSuccessResDto>;
        }),
      );
  }

  /**
   * Webhook send message
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `webhookSendMessage$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  webhookSendMessage(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    body: WebhookChatMessageReqDto;
  }): Observable<CommonSuccessResDto> {
    return this.webhookSendMessage$Response(params).pipe(
      map((r: StrictHttpResponse<CommonSuccessResDto>) => r.body as CommonSuccessResDto),
    );
  }

  /**
   * Path part for operation webhookSendMessage_1
   */
  static readonly WebhookSendMessage_1Path = '/webhooks/messages/{hookId}/{secretKey}/{serviceType}';

  /**
   * Webhook send message
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `webhookSendMessage_1()` instead.
   *
   * This method doesn't expect any request body.
   */
  webhookSendMessage_1$Response(params?: {
    /**
     * Localization
     */
    'x-lang'?: string;
  }): Observable<StrictHttpResponse<CommonSuccessResDto>> {
    const rb = new RequestBuilder(this.rootUrl, WebhooksService.WebhookSendMessage_1Path, 'post');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<CommonSuccessResDto>;
        }),
      );
  }

  /**
   * Webhook send message
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `webhookSendMessage_1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  webhookSendMessage_1(params?: {
    /**
     * Localization
     */
    'x-lang'?: string;
  }): Observable<CommonSuccessResDto> {
    return this.webhookSendMessage_1$Response(params).pipe(
      map((r: StrictHttpResponse<CommonSuccessResDto>) => r.body as CommonSuccessResDto),
    );
  }
}
