/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { IntersectionTicketsCreateReqDtoCommonOptionalFilesArrReqDto } from '../models/intersection-tickets-create-req-dto-common-optional-files-arr-req-dto';
import { IntersectionTicketsUpdateReqDtoCommonOptionalFilesArrReqDto } from '../models/intersection-tickets-update-req-dto-common-optional-files-arr-req-dto';
import { TicketsDraftsResDto } from '../models/tickets-drafts-res-dto';

@Injectable({
  providedIn: 'root',
})
export class BoardsDraftsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation ticketsDraftsGetList
   */
  static readonly TicketsDraftsGetListPath = '/boards/drafts';

  /**
   * tickets drafts get list
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ticketsDraftsGetList()` instead.
   *
   * This method doesn't expect any request body.
   */
  ticketsDraftsGetList$Response(params?: {
    /**
     * Localization
     */
    'x-lang'?: string;
  }): Observable<StrictHttpResponse<Array<TicketsDraftsResDto>>> {
    const rb = new RequestBuilder(this.rootUrl, BoardsDraftsService.TicketsDraftsGetListPath, 'get');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<TicketsDraftsResDto>>;
        }),
      );
  }

  /**
   * tickets drafts get list
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `ticketsDraftsGetList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  ticketsDraftsGetList(params?: {
    /**
     * Localization
     */
    'x-lang'?: string;
  }): Observable<Array<TicketsDraftsResDto>> {
    return this.ticketsDraftsGetList$Response(params).pipe(
      map((r: StrictHttpResponse<Array<TicketsDraftsResDto>>) => r.body as Array<TicketsDraftsResDto>),
    );
  }

  /**
   * Path part for operation ticketsDraftsCreate
   */
  static readonly TicketsDraftsCreatePath = '/boards/drafts';

  /**
   * tickets drafts create
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ticketsDraftsCreate()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  ticketsDraftsCreate$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;

    /**
     * ticketId
     */
    ticketId?: string;
    body: IntersectionTicketsCreateReqDtoCommonOptionalFilesArrReqDto;
  }): Observable<StrictHttpResponse<TicketsDraftsResDto>> {
    const rb = new RequestBuilder(this.rootUrl, BoardsDraftsService.TicketsDraftsCreatePath, 'post');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.query('ticketId', params.ticketId, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<TicketsDraftsResDto>;
        }),
      );
  }

  /**
   * tickets drafts create
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `ticketsDraftsCreate$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  ticketsDraftsCreate(params: {
    /**
     * Localization
     */
    'x-lang'?: string;

    /**
     * ticketId
     */
    ticketId?: string;
    body: IntersectionTicketsCreateReqDtoCommonOptionalFilesArrReqDto;
  }): Observable<TicketsDraftsResDto> {
    return this.ticketsDraftsCreate$Response(params).pipe(
      map((r: StrictHttpResponse<TicketsDraftsResDto>) => r.body as TicketsDraftsResDto),
    );
  }

  /**
   * Path part for operation ticketsDraftsGetOne
   */
  static readonly TicketsDraftsGetOnePath = '/boards/drafts/{id}';

  /**
   * tickets drafts get one
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ticketsDraftsGetOne()` instead.
   *
   * This method doesn't expect any request body.
   */
  ticketsDraftsGetOne$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    id: string;
  }): Observable<StrictHttpResponse<TicketsDraftsResDto>> {
    const rb = new RequestBuilder(this.rootUrl, BoardsDraftsService.TicketsDraftsGetOnePath, 'get');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.path('id', params.id, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<TicketsDraftsResDto>;
        }),
      );
  }

  /**
   * tickets drafts get one
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `ticketsDraftsGetOne$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  ticketsDraftsGetOne(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    id: string;
  }): Observable<TicketsDraftsResDto> {
    return this.ticketsDraftsGetOne$Response(params).pipe(
      map((r: StrictHttpResponse<TicketsDraftsResDto>) => r.body as TicketsDraftsResDto),
    );
  }

  /**
   * Path part for operation ticketsDraftsDelete
   */
  static readonly TicketsDraftsDeletePath = '/boards/drafts/{id}';

  /**
   * tickets drafts delete
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ticketsDraftsDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  ticketsDraftsDelete$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    id: string;
  }): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, BoardsDraftsService.TicketsDraftsDeletePath, 'delete');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.path('id', params.id, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: '*/*',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
        }),
      );
  }

  /**
   * tickets drafts delete
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `ticketsDraftsDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  ticketsDraftsDelete(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    id: string;
  }): Observable<void> {
    return this.ticketsDraftsDelete$Response(params).pipe(map((r: StrictHttpResponse<void>) => r.body as void));
  }

  /**
   * Path part for operation ticketsDraftsUpdate
   */
  static readonly TicketsDraftsUpdatePath = '/boards/drafts/{id}';

  /**
   * tickets drafts update
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ticketsDraftsUpdate()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  ticketsDraftsUpdate$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    id: string;
    isNeedToCreateNew?: boolean;
    isNeedToSaveChanges?: boolean;
    body: IntersectionTicketsUpdateReqDtoCommonOptionalFilesArrReqDto;
  }): Observable<StrictHttpResponse<TicketsDraftsResDto>> {
    const rb = new RequestBuilder(this.rootUrl, BoardsDraftsService.TicketsDraftsUpdatePath, 'patch');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.path('id', params.id, {});
      rb.query('isNeedToCreateNew', params.isNeedToCreateNew, {});
      rb.query('isNeedToSaveChanges', params.isNeedToSaveChanges, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<TicketsDraftsResDto>;
        }),
      );
  }

  /**
   * tickets drafts update
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `ticketsDraftsUpdate$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  ticketsDraftsUpdate(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    id: string;
    isNeedToCreateNew?: boolean;
    isNeedToSaveChanges?: boolean;
    body: IntersectionTicketsUpdateReqDtoCommonOptionalFilesArrReqDto;
  }): Observable<TicketsDraftsResDto> {
    return this.ticketsDraftsUpdate$Response(params).pipe(
      map((r: StrictHttpResponse<TicketsDraftsResDto>) => r.body as TicketsDraftsResDto),
    );
  }
}
