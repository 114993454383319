export class UserAvatarGet {
  static readonly type = '[Avatars] UserGet';
  constructor(public payload) {}
}

export class SpaceAvatarGet {
  static readonly type = '[Avatars] SpaceGet';
  constructor(public payload) {}
}

export class ProjectAvatarGet {
  static readonly type = '[Avatars] ProjectGet';
  constructor(public payload) {}
}

export class TenantAvatarGet {
  static readonly type = '[Avatars] TenantGet';
  constructor(public payload) {}
}

export class UserAvatarSet {
  static readonly type = '[Avatars] UserSet';
  constructor(public payload) {}
}

export class SpaceAvatarSet {
  static readonly type = '[Avatars] SpaceSet';
  constructor(public payload) {}
}

export class ProjectAvatarSet {
  static readonly type = '[Avatars] ProjectSet';
  constructor(public payload) {}
}

export class TenantAvatarSet {
  static readonly type = '[Avatars] TenantSet';
  constructor(public payload) {}
}

export class AvatarDelete {
  static readonly type = '[Avatars] AvatarDelete';
  constructor(public payload) {}
}

export class WebhookAvatarSet {
  static readonly type = '[Avatars] WebhookSet';
  constructor(public payload) {}
}

export class WebhookAvatarGet {
  static readonly type = '[Avatars] WebhookGet';
  constructor(public payload) {}
}
