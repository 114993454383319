/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CommonSuccessResDto } from '../models/common-success-res-dto';
import { NotesCreateReqDto } from '../models/notes-create-req-dto';
import { NotesDbDto } from '../models/notes-db-dto';
import { NotesLabelsCreateReqDto } from '../models/notes-labels-create-req-dto';
import { NotesLabelsDbDto } from '../models/notes-labels-db-dto';
import { NotesUpdateReqDto } from '../models/notes-update-req-dto';

@Injectable({
  providedIn: 'root',
})
export class NotesService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation notesGet
   */
  static readonly NotesGetPath = '/notes';

  /**
   * get all notes
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `notesGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  notesGet$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;

    /**
     * last note _id
     */
    _id?: string;

    /**
     * objectId
     */
    objectId?: string;

    /**
     * tenants | spaces | projects
     */
    object: string;

    /**
     * tickets | calendar-events
     */
    linkObject?: string;

    /**
     * linkObjectId
     */
    linkObjectId?: string;
  }): Observable<StrictHttpResponse<Array<NotesDbDto>>> {
    const rb = new RequestBuilder(this.rootUrl, NotesService.NotesGetPath, 'get');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.query('_id', params['_id'], {});
      rb.query('objectId', params.objectId, {});
      rb.query('object', params.object, {});
      rb.query('linkObject', params.linkObject, {});
      rb.query('linkObjectId', params.linkObjectId, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<NotesDbDto>>;
        }),
      );
  }

  /**
   * get all notes
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `notesGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  notesGet(params: {
    /**
     * Localization
     */
    'x-lang'?: string;

    /**
     * last note _id
     */
    _id?: string;

    /**
     * objectId
     */
    objectId?: string;

    /**
     * tenants | spaces | projects
     */
    object: string;

    /**
     * tickets | calendar-events
     */
    linkObject?: string;

    /**
     * linkObjectId
     */
    linkObjectId?: string;
  }): Observable<Array<NotesDbDto>> {
    return this.notesGet$Response(params).pipe(
      map((r: StrictHttpResponse<Array<NotesDbDto>>) => r.body as Array<NotesDbDto>),
    );
  }

  /**
   * Path part for operation notesPost
   */
  static readonly NotesPostPath = '/notes';

  /**
   * creates a note
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `notesPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  notesPost$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    body: NotesCreateReqDto;
  }): Observable<StrictHttpResponse<NotesDbDto>> {
    const rb = new RequestBuilder(this.rootUrl, NotesService.NotesPostPath, 'post');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<NotesDbDto>;
        }),
      );
  }

  /**
   * creates a note
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `notesPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  notesPost(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    body: NotesCreateReqDto;
  }): Observable<NotesDbDto> {
    return this.notesPost$Response(params).pipe(map((r: StrictHttpResponse<NotesDbDto>) => r.body as NotesDbDto));
  }

  /**
   * Path part for operation notesDelete
   */
  static readonly NotesDeletePath = '/notes/{id}';

  /**
   * deletes a note
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `notesDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  notesDelete$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    id: string;
  }): Observable<StrictHttpResponse<CommonSuccessResDto>> {
    const rb = new RequestBuilder(this.rootUrl, NotesService.NotesDeletePath, 'delete');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.path('id', params.id, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<CommonSuccessResDto>;
        }),
      );
  }

  /**
   * deletes a note
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `notesDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  notesDelete(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    id: string;
  }): Observable<CommonSuccessResDto> {
    return this.notesDelete$Response(params).pipe(
      map((r: StrictHttpResponse<CommonSuccessResDto>) => r.body as CommonSuccessResDto),
    );
  }

  /**
   * Path part for operation notesUpdate
   */
  static readonly NotesUpdatePath = '/notes/{id}';

  /**
   * updates a note
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `notesUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  notesUpdate$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    id: string;
    body: NotesUpdateReqDto;
  }): Observable<StrictHttpResponse<NotesDbDto>> {
    const rb = new RequestBuilder(this.rootUrl, NotesService.NotesUpdatePath, 'patch');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<NotesDbDto>;
        }),
      );
  }

  /**
   * updates a note
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `notesUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  notesUpdate(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    id: string;
    body: NotesUpdateReqDto;
  }): Observable<NotesDbDto> {
    return this.notesUpdate$Response(params).pipe(map((r: StrictHttpResponse<NotesDbDto>) => r.body as NotesDbDto));
  }

  /**
   * Path part for operation notesLabelsGet
   */
  static readonly NotesLabelsGetPath = '/notes/label';

  /**
   * get all notes labels
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `notesLabelsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  notesLabelsGet$Response(params?: {
    /**
     * Localization
     */
    'x-lang'?: string;
  }): Observable<StrictHttpResponse<Array<NotesLabelsDbDto>>> {
    const rb = new RequestBuilder(this.rootUrl, NotesService.NotesLabelsGetPath, 'get');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<NotesLabelsDbDto>>;
        }),
      );
  }

  /**
   * get all notes labels
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `notesLabelsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  notesLabelsGet(params?: {
    /**
     * Localization
     */
    'x-lang'?: string;
  }): Observable<Array<NotesLabelsDbDto>> {
    return this.notesLabelsGet$Response(params).pipe(
      map((r: StrictHttpResponse<Array<NotesLabelsDbDto>>) => r.body as Array<NotesLabelsDbDto>),
    );
  }

  /**
   * Path part for operation notesLabelsCreate
   */
  static readonly NotesLabelsCreatePath = '/notes/label';

  /**
   * creates a note label
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `notesLabelsCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  notesLabelsCreate$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    body: NotesLabelsCreateReqDto;
  }): Observable<StrictHttpResponse<NotesLabelsDbDto>> {
    const rb = new RequestBuilder(this.rootUrl, NotesService.NotesLabelsCreatePath, 'post');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<NotesLabelsDbDto>;
        }),
      );
  }

  /**
   * creates a note label
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `notesLabelsCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  notesLabelsCreate(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    body: NotesLabelsCreateReqDto;
  }): Observable<NotesLabelsDbDto> {
    return this.notesLabelsCreate$Response(params).pipe(
      map((r: StrictHttpResponse<NotesLabelsDbDto>) => r.body as NotesLabelsDbDto),
    );
  }

  /**
   * Path part for operation notesLabelsDelete
   */
  static readonly NotesLabelsDeletePath = '/notes/label/{id}';

  /**
   * deletes a notes label
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `notesLabelsDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  notesLabelsDelete$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    id: string;
  }): Observable<StrictHttpResponse<CommonSuccessResDto>> {
    const rb = new RequestBuilder(this.rootUrl, NotesService.NotesLabelsDeletePath, 'delete');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.path('id', params.id, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<CommonSuccessResDto>;
        }),
      );
  }

  /**
   * deletes a notes label
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `notesLabelsDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  notesLabelsDelete(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    id: string;
  }): Observable<CommonSuccessResDto> {
    return this.notesLabelsDelete$Response(params).pipe(
      map((r: StrictHttpResponse<CommonSuccessResDto>) => r.body as CommonSuccessResDto),
    );
  }

  /**
   * Path part for operation notesLabelsUpdate
   */
  static readonly NotesLabelsUpdatePath = '/notes/label/{id}';

  /**
   * updates a notes label
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `notesLabelsUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  notesLabelsUpdate$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    id: string;
    body: NotesLabelsCreateReqDto;
  }): Observable<StrictHttpResponse<NotesLabelsDbDto>> {
    const rb = new RequestBuilder(this.rootUrl, NotesService.NotesLabelsUpdatePath, 'patch');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<NotesLabelsDbDto>;
        }),
      );
  }

  /**
   * updates a notes label
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `notesLabelsUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  notesLabelsUpdate(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    id: string;
    body: NotesLabelsCreateReqDto;
  }): Observable<NotesLabelsDbDto> {
    return this.notesLabelsUpdate$Response(params).pipe(
      map((r: StrictHttpResponse<NotesLabelsDbDto>) => r.body as NotesLabelsDbDto),
    );
  }
}
