/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { TenantsSettingsReqDto } from '../models/tenants-settings-req-dto';
import { TenantsSettingsResDto } from '../models/tenants-settings-res-dto';

@Injectable({
  providedIn: 'root',
})
export class TenantsSettingsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation tenantsSettingsGet
   */
  static readonly TenantsSettingsGetPath = '/tenants/settings';

  /**
   * tenants settings get
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `tenantsSettingsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  tenantsSettingsGet$Response(params?: {
    /**
     * Localization
     */
    'x-lang'?: string;
  }): Observable<StrictHttpResponse<TenantsSettingsResDto>> {
    const rb = new RequestBuilder(this.rootUrl, TenantsSettingsService.TenantsSettingsGetPath, 'get');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<TenantsSettingsResDto>;
        }),
      );
  }

  /**
   * tenants settings get
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `tenantsSettingsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  tenantsSettingsGet(params?: {
    /**
     * Localization
     */
    'x-lang'?: string;
  }): Observable<TenantsSettingsResDto> {
    return this.tenantsSettingsGet$Response(params).pipe(
      map((r: StrictHttpResponse<TenantsSettingsResDto>) => r.body as TenantsSettingsResDto),
    );
  }

  /**
   * Path part for operation tenantsSettingsCreate
   */
  static readonly TenantsSettingsCreatePath = '/tenants/settings';

  /**
   * tenants settings create
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `tenantsSettingsCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  tenantsSettingsCreate$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    body: TenantsSettingsReqDto;
  }): Observable<StrictHttpResponse<TenantsSettingsResDto>> {
    const rb = new RequestBuilder(this.rootUrl, TenantsSettingsService.TenantsSettingsCreatePath, 'post');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<TenantsSettingsResDto>;
        }),
      );
  }

  /**
   * tenants settings create
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `tenantsSettingsCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  tenantsSettingsCreate(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    body: TenantsSettingsReqDto;
  }): Observable<TenantsSettingsResDto> {
    return this.tenantsSettingsCreate$Response(params).pipe(
      map((r: StrictHttpResponse<TenantsSettingsResDto>) => r.body as TenantsSettingsResDto),
    );
  }

  /**
   * Path part for operation tenantsSettingsDelete
   */
  static readonly TenantsSettingsDeletePath = '/tenants/settings';

  /**
   * tenants settings delete
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `tenantsSettingsDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  tenantsSettingsDelete$Response(params?: {
    /**
     * Localization
     */
    'x-lang'?: string;
  }): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, TenantsSettingsService.TenantsSettingsDeletePath, 'delete');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: '*/*',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
        }),
      );
  }

  /**
   * tenants settings delete
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `tenantsSettingsDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  tenantsSettingsDelete(params?: {
    /**
     * Localization
     */
    'x-lang'?: string;
  }): Observable<void> {
    return this.tenantsSettingsDelete$Response(params).pipe(map((r: StrictHttpResponse<void>) => r.body as void));
  }

  /**
   * Path part for operation tenantsSettingsUpdate
   */
  static readonly TenantsSettingsUpdatePath = '/tenants/settings';

  /**
   * tenants settings update
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `tenantsSettingsUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  tenantsSettingsUpdate$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    body: TenantsSettingsReqDto;
  }): Observable<StrictHttpResponse<TenantsSettingsResDto>> {
    const rb = new RequestBuilder(this.rootUrl, TenantsSettingsService.TenantsSettingsUpdatePath, 'patch');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<TenantsSettingsResDto>;
        }),
      );
  }

  /**
   * tenants settings update
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `tenantsSettingsUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  tenantsSettingsUpdate(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    body: TenantsSettingsReqDto;
  }): Observable<TenantsSettingsResDto> {
    return this.tenantsSettingsUpdate$Response(params).pipe(
      map((r: StrictHttpResponse<TenantsSettingsResDto>) => r.body as TenantsSettingsResDto),
    );
  }
}
