import { Component, Input, OnInit } from '@angular/core';
import { AttributeEnum, IPlan, plans } from '../../payment.const';
import {
  TVariant,
  VariantEnum,
  ButtonComponent,
} from '../../../../shared/components/ui-elements/button/button.component';
import { PaymentService, StepEnum } from '../../payment.service';
import { ConfigService, ITemplateConfig } from '../../../../shared/services/config.service';
import { TranslocoService, TranslocoDirective } from '@ngneat/transloco';
import { combineLatest } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { finalize } from 'rxjs/operators';
import { Store } from '@ngxs/store';
import {
  BillingDetailsWithCountries,
  PaymentAmount,
} from '../../../../shared/store/actions/payment.action';
import { TDate } from '../payment-choice-step/payment-choice-step.component';
import { SvgIconComponent } from 'angular-svg-icon';
import { NgClass, NgIf, NgFor } from '@angular/common';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-payment-card',
  templateUrl: './payment-card.component.html',
  styleUrls: ['./payment-card.component.scss'],
  standalone: true,
  imports: [TranslocoDirective, NgClass, NgIf, SvgIconComponent, NgFor, ButtonComponent],
})
export class PaymentCardComponent implements OnInit {
  @Input() plan: IPlan;
  @Input() isCurrentPlan: string;
  @Input() isLoading: boolean;
  @Input() isAccept = false;
  @Input() paymentType: TDate;

  public titleButton: string = 'Select';
  public isLoadingButton = false;
  public variantButton: TVariant = VariantEnum.Primary;
  private config: ITemplateConfig;

  constructor(
    private readonly paymentService: PaymentService,
    private readonly configService: ConfigService,
    private readonly i18n: TranslocoService,
    private readonly store: Store,
  ) {
    this.config = this.configService.templateConf;
  }

  ngOnInit(): void {
    this.titleButton = this.isContactUsTitle();
    this.variantButton = this.variantForButton();
  }

  // --------- Getters --------

  public getClass(name) {
    return `card__${name}`;
  }

  public get isDark(): boolean {
    return this.config?.layout.variant === 'Dark';
  }

  public get descriptionPlan(): string {
    return this.i18n.translate(`modals.payment.plan-description-${this.plan.attribute}`);
  }

  public get isEnterpriseActive(): boolean {
    return (
      this.isCurrentPlan === AttributeEnum.Enterprise && this.plan.attribute === AttributeEnum.Basic
    );
  }

  public get isBasicPlan(): boolean {
    return this.plan.attribute === AttributeEnum.Basic;
  }

  // --------- Methods --------

  // Set variant for button
  public variantForButton(): TVariant {
    if (this.plan.attribute === AttributeEnum.Free || this.isEnterpriseActive) {
      return VariantEnum.Secondary;
    }
    return VariantEnum.Primary;
  }

  // Set title for button
  public isContactUsTitle(): string {
    const isMigrate = this.i18n.translate('modals.payment.downgrade', {
      value: this.plan.title,
    });
    if (this.plan.attribute === AttributeEnum.Free) {
      if (this.plan.isCurrentPlan) {
        return this.i18n.translate('modals.payment.have-free-plan');
      }
      return isMigrate;
    } else if (this.plan.attribute === AttributeEnum.Basic) {
      if (this.isEnterpriseActive) {
        return isMigrate;
      }

      if (this.plan.isCurrentPlan) {
        return this.i18n.translate('modals.payment.have-free-plan');
      }

      return this.i18n.translate('modals.payment.select');
    } else {
      return this.i18n.translate('modals.payment.contact-us');
    }
  }

  // Handle click
  public handleClick(): void {
    if (this.plan.attribute === AttributeEnum.Enterprise) {
      this.paymentService.contactUs();
    } else if (this.plan.attribute === AttributeEnum.Free || this.isEnterpriseActive) {
      // Migrate
      const plan = plans.find((plan) => plan.isCurrentPlan);
      this.paymentService.handleSelectPlan({
        step: StepEnum.Accept,
        plan: this.plan,
      });
      this.paymentService.setPreviousPlan(plan);
    } else {
      // Select
      this.isLoadingButton = true;
      combineLatest([
        this.store.dispatch(new PaymentAmount(this.paymentType)),
        this.store.dispatch(new BillingDetailsWithCountries()),
      ])
        .pipe(
          untilDestroyed(this),
          finalize(() => (this.isLoadingButton = false)),
        )
        .subscribe(() => {
          this.paymentService.handleSelectPlan({
            step: StepEnum.DetailForm,
            plan: this.plan,
          });
        });
    }
  }
}
