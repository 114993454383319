import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class EnvironmentService {
  private environment = environment;

  public get isProduction(): boolean {
    return this.environment.name === 'prod';
  }

  public get isPayment(): boolean {
    return this.environment.payment;
  }

  public get isLimited(): boolean {
    return this.environment.payment_limitation;
  }

  public set environmentSet(environment) {
    this.environment = environment;
  }

  public get isSubdomains(): boolean {
    return this.environment.subdomains;
  }
}
