/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { NotepadsCreateReqDto } from '../models/notepads-create-req-dto';
import { NotepadsDbDto } from '../models/notepads-db-dto';

@Injectable({
  providedIn: 'root',
})
export class NotepadsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation notepadsGet
   */
  static readonly NotepadsGetPath = '/notepads';

  /**
   * get all notes
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `notepadsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  notepadsGet$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    object: string;
    objectId?: string;
  }): Observable<StrictHttpResponse<Array<NotepadsDbDto>>> {
    const rb = new RequestBuilder(this.rootUrl, NotepadsService.NotepadsGetPath, 'get');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.query('object', params.object, {});
      rb.query('objectId', params.objectId, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<NotepadsDbDto>>;
        }),
      );
  }

  /**
   * get all notes
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `notepadsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  notepadsGet(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    object: string;
    objectId?: string;
  }): Observable<Array<NotepadsDbDto>> {
    return this.notepadsGet$Response(params).pipe(
      map((r: StrictHttpResponse<Array<NotepadsDbDto>>) => r.body as Array<NotepadsDbDto>),
    );
  }

  /**
   * Path part for operation notepadsSet
   */
  static readonly NotepadsSetPath = '/notepads';

  /**
   * creates a notepad
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `notepadsSet()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  notepadsSet$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    body: NotepadsCreateReqDto;
  }): Observable<StrictHttpResponse<NotepadsDbDto>> {
    const rb = new RequestBuilder(this.rootUrl, NotepadsService.NotepadsSetPath, 'post');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<NotepadsDbDto>;
        }),
      );
  }

  /**
   * creates a notepad
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `notepadsSet$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  notepadsSet(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    body: NotepadsCreateReqDto;
  }): Observable<NotepadsDbDto> {
    return this.notepadsSet$Response(params).pipe(
      map((r: StrictHttpResponse<NotepadsDbDto>) => r.body as NotepadsDbDto),
    );
  }
}
