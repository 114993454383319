<section *transloco="let t; read: 'modals.webhook-create-update'">
  <div class="image-cropper-wrapper" [class.image-cropper-wrapper-mobile]="platform !== 'web'">
    <image-cropper
      class="image-cropper"
      [imageChangedEvent]="imageChangedEvent"
      [imageURL]="imageURL"
      [maintainAspectRatio]="true"
      [containWithinAspectRatio]="containWithinAspectRatio"
      [aspectRatio]="aspectRatio"
      [resizeToWidth]="resizeToWidth"
      [cropperMinWidth]="128"
      [onlyScaleDown]="true"
      [roundCropper]="false"
      [canvasRotation]="canvasRotation"
      [transform]="transform"
      [alignImage]="'center'"
      [style.display]="showCropper ? null : 'none'"
      format="png"
      (imageCropped)="imageCropped($event)"
      (imageLoaded)="imageLoaded()"
    ></image-cropper>
  </div>

  <div class="d-flex justify-content-center flex-wrap mt-3 mb-3">
    <span class="my-1">
      <button type="button" class="btn btn-outline mx-2" (click)="rotateLeft()">
        <span class="fa fa-undo font-size"></span>
      </button>
      <button type="button" class="btn btn-outline mx-2" (click)="rotateRight()">
        <span class="fa fa-repeat font-size"></span>
      </button>
      <button type="button" class="btn btn-outline mx-2" (click)="flipHorizontal()">
        <span class="fa fa-sign-out font-size"></span>
      </button>
      <button type="button" class="btn btn-outline mx-2" (click)="flipVertical()">
        <span class="fa fa-sign-out fa-rotate-90 font-size"></span>
      </button>
    </span>
    <span class="my-1">
      <button type="button" class="btn btn-outline mx-2" (click)="zoomOut()">
        <span class="fa fa-search-minus font-size"></span>
      </button>
      <button type="button" class="btn btn-outline mx-2" (click)="zoomIn()">
        <span class="fa fa-search-plus font-size"></span>
      </button>
      <button type="button" class="btn btn-outline mx-2" (click)="resetImage()">
        <span class="fa fa-refresh font-size"></span>
      </button>
    </span>
  </div>

  <div class="d-flex justify-content-center mb-3">
    <button
      type="button"
      class="btn btn-outline-secondary action-buttons mr-2"
      (click)="closeCropperModal.emit()"
    >
      {{ t('btn-cancel') }}
    </button>
    <button
      type="button"
      class="btn btn-solid action-buttons"
      t-id="image-cropper-save-photo-action"
      (click)="saveImage()"
    >
      {{ t('btn-save-photo') }}
    </button>
  </div>
</section>
