<div class="relative aspect-square bg-secondary-800 rounded-lg">
  <div
    *ngIf="!project.emoji || !showEmoji"
    class="w-full h-full"
    [ngClass]="{ 'mr-2': mr }"
    [ngClass]="{ 'avatar-breadcrumb': isBreadcrumb }"
  >
    <img
      *ngIf="project.avatarUrl || project.projectAvatarUrl"
      [src]="project.avatarUrl || project.projectAvatarUrl"
      [style.width.px]="containerWidth"
      [style.height.px]="containerHeight"
      class="rounded-md"
      alt="Project avatar image"
    />

    <div
      *ngIf="!project.avatarUrl && !project.projectAvatarUrl"
      class="bg-primary-400 rounded-md text-white flex justify-center items-center font-semibold text-xs h-full w-full"
    >
      {{
        (project?.projectName || project?.chatName || project?.spaceName || project?.prefix)
          ?.charAt(0)
          .toUpperCase()
      }}
    </div>

    <div
      *ngIf="overlay"
      class="img-overlay absolute top-0 flex items-center justify-center h-full w-full bg-black bg-opacity-30 text-white rounded-md opacity-0 invisible transition-opacity duration-500"
    >
      <i class="fas fa-edit fa-2x"></i>
    </div>
  </div>

  <div
    *ngIf="project.emoji && showEmoji"
    [style.width.px]="containerWidth"
    [style.height.px]="containerHeight"
    [ngClass]="{ 'mr-2': mr }"
    [ngClass]="{ 'avatar-breadcrumb': isBreadcrumb }"
  >
    <div
      class="bg-secondary-200 dark:bg-primary-500 rounded-md flex justify-center items-center font-semibold h-full w-full"
      [ngClass]="{
        'text-xl': containerWidth >= 30,
        'text-base': containerWidth === 24
      }"
    >
      {{ project.emoji }}
    </div>
  </div>
</div>
