import { Store, State, Action, Selector, StateContext } from '@ngxs/store';
import { map, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import slugify from 'slugify';

import { SpacesStateModel } from '../models/SpacesState';
import { AuthLoginResDto } from '../../../api/models/auth-login-res-dto';
import {
  SpaceCreate,
  SpaceDelete,
  SpaceGetUsersList,
  SpaceGetUsersListForCreateChatGroup,
  SpaceInvite,
  SpacesSet,
  SpacesGet,
  SpaceUpdate,
  SpaceInviteTokenCheck,
  SpaceInviteConfirmExist,
  SpaceInviteConfirmNew,
  SpaceUpdateAvatar,
  SpaceSetAvatarImageUploadLoading,
  SpaceUnassignMember,
  SpaceRevokeInvite,
  SpaceSetChatMenuVisibility,
  SpaceToggleChatMenuVisibility,
  SpaceOrderUpdate,
  SpacesUpdateAfterChange,
  SpacesUpdateAfterDelete,
  SpacesResetNumberOfActivities,
  SpacesGetUserListsBySpacesId,
  SpaceToggleArchiveStatus,
  SpaceLeave,
  SpaceMemberDelete,
  SpaceOrders,
  SpaceRestore,
} from '../actions/spaces.action';
import { SpacesService } from '../../../api/services/spaces.service';
import { ProceedLogin } from '../actions/auth.action';
import { SpaceAvatarSet } from '../actions/avatar.action';
import { LocalStorageService } from 'ngx-localstorage';
import { ProjectsDeleteBySpace, ProjectsSet } from '../actions/projects.action';
import { combineLatest } from 'rxjs';
import { TicketService } from '../../services/ticket.service';
import { AuthState } from './auth.state';
import { UrlHelper } from '../../utils/url-helper';
import { v4 as uuidv4 } from 'uuid';
import { compose, patch, updateItem } from '@ngxs/store/operators';
import { DeleteChat } from '../actions/chats.action';

@State<SpacesStateModel>({
  name: 'Spaces',
  defaults: {
    spacesLoaded: false,
    spaces: [],
    users: [],
    allUsers: [],
    usersForGroup: [],
    usersCandidates: [],
    pendingUsers: [],
    notActivatedUsers: [],
    lastCreatedId: '',
    inviteData: {},
    chatMenuSpacesVisibility: {},
    requestUuid: '',
    restoreSpace: null,
  },
})
@Injectable()
export class SpacesState {
  /**
   * get all available users
   * @param  {SpacesStateModel} state
   */
  @Selector()
  static getAllUsers(state: SpacesStateModel) {
    return [
      ...state.users.map((item) => ({ ...item, status: 'Active' })),
      ...state.pendingUsers.map((item) => ({ ...item, status: 'Pending' })),
      ...state.notActivatedUsers.map((item) => ({
        ...item,
        status: 'Newly invited',
      })),
    ];
  }

  /**
   * get users
   * @param  {SpacesStateModel} state
   */
  @Selector()
  static getUsers(state: SpacesStateModel) {
    return state.users;
  }

  /**
   * get usersForGroup
   * @param  {SpacesStateModel} state
   */
  @Selector()
  static getUsersForGroup(state: SpacesStateModel) {
    return state.usersForGroup;
  }

  @Selector()
  /**
   * get invite data
   * @param  {SpacesStateModel} state
   */
  static getInviteData(state: SpacesStateModel) {
    return state.inviteData;
  }

  /**
   * get users candidates for invite
   * @param  {SpacesStateModel} state
   */
  @Selector()
  static getUsersCandidates(state: SpacesStateModel) {
    return state.usersCandidates;
  }

  /**
   * get loaded spaces
   * @param  {SpacesStateModel} state
   */
  @Selector()
  static getLoadedSpaces(state: SpacesStateModel) {
    return state.spaces.filter((space) => !space.isArchive);
  }

  /**
   * get archive spaces
   * @param  {SpacesStateModel} state
   */
  @Selector()
  static getArchiveSpaces(state: SpacesStateModel) {
    return state.spaces.filter((space) => space.isArchive);
  }

  /**
   * get all loaded users
   * @param  {SpacesStateModel} state
   */
  @Selector()
  static getLoadedAllUsers(state: SpacesStateModel) {
    return state.allUsers;
  }

  /**
   * get id of last created item
   * @param  {SpacesStateModel} state
   */
  @Selector()
  static getLastCreatedId(state: SpacesStateModel) {
    return state.lastCreatedId;
  }

  /**
   * get spaces status (loaded | not)
   * @param  {SpacesStateModel} state
   */
  @Selector()
  static getLoadedSpacesStatus(state: SpacesStateModel) {
    return state.spacesLoaded;
  }

  /**
   * get single space
   * @param  {SpacesStateModel} state
   */
  @Selector()
  static getSpace(state: SpacesStateModel) {
    return (spaceId: string) => state.spaces.find((item) => item._id === spaceId) || null;
  }

  /**
   * get chat menu space visibility
   * @param  {SpacesStateModel} state
   */
  @Selector()
  static getChatMenuSpaceVisibility(state: SpacesStateModel) {
    return (spaceId: string) =>
      state.chatMenuSpacesVisibility?.[spaceId] === undefined
        ? true
        : state.chatMenuSpacesVisibility?.[spaceId];
  }

  /**
   * get single space avatar image upload loading
   * @param  {SpacesStateModel} state
   */
  @Selector()
  static getSpaceAvatarImageUploadLoading(state: SpacesStateModel) {
    return (spaceId: string) =>
      state.spaces.filter((item) => item._id === spaceId)[0]?.avatarImageUploadLoading || null;
  }

  constructor(
    private store: Store,
    private spacesService: SpacesService,
    private localStorage: LocalStorageService,
    private ticketService: TicketService,
    private urlHelper: UrlHelper,
  ) {}

  /**
   * Create space action handler
   * @param  {getState, setState}: StateContext<SpacesStateModel>
   * @param  {SpaceCreate} action
   */
  @Action(SpaceCreate)
  create_space(
    { getState, setState, patchState }: StateContext<SpacesStateModel>,
    {
      payload: {
        body,
        avatar,
        invitedUsers: { newUsers, existingUsers },
      },
    }: SpaceCreate,
  ) {
    patchState({ lastCreatedId: '' });
    return this.spacesService.spacesCreate({ body }).pipe(
      tap(
        (result) => {
          const { spaces, chatMenuSpacesVisibility, ...rest } = getState();
          const spacesVisibility = {
            ...chatMenuSpacesVisibility,
            [result._id]: true,
          };

          setState({
            ...rest,
            spacesLoaded: true,
            spaces: [...spaces, result],
            lastCreatedId: result._id,
            chatMenuSpacesVisibility: spacesVisibility,
          });
          this.localStorage.set('spaces', [...spaces, result]);
          this.localStorage.set('chatMenuSpacesVisibility', spacesVisibility);

          if (avatar) {
            this.store.dispatch(new SpaceAvatarSet({ id: result._id, file: avatar }));
          }

          if (newUsers.length > 0 || existingUsers.length > 0) {
            this.store.dispatch(new SpaceInvite({ spaceId: result._id, newUsers, existingUsers }));
          }

          return result;
        },
        (err) => {
          throw err.error;
        },
      ),
    );
  }

  /**
   * set chat menu spaces visibility
   * @param  {patchState}: StateContext<SpaceStateModel>
   */
  @Action(SpacesSet)
  setSpaces({ patchState }: StateContext<SpacesStateModel>) {
    return patchState({ spaces: this.localStorage.get('spaces') || [] });
  }

  /**
   * Get users by space id action handler
   * @param  {getState, patchState}: StateContext<SpacesStateModel>
   * @param  {SpacesGetUserListsBySpacesId} action
   */
  @Action(SpacesGetUserListsBySpacesId)
  getUsersBySpaces(
    { getState, patchState }: StateContext<SpacesStateModel>,
    { payload }: SpacesGetUserListsBySpacesId,
  ) {
    const spaces = payload.spaces;

    return combineLatest(
      spaces.map((space) => {
        return this.spacesService.spacesGetUserListBySpaceId({ id: space._id, exists: true }).pipe(
          tap(
            (result) => {
              const prevState = getState();
              if (!prevState.allUsers.find(({ spaceId }) => spaceId === space._id)) {
                return result;
              }
            },
            (err) => {
              throw err.error;
            },
          ),
          map((result) => {
            const prevState = getState();
            if (!prevState.allUsers.find(({ spaceId }) => spaceId === space._id)) {
              patchState({
                allUsers: [
                  ...prevState.allUsers,
                  {
                    spaceId: space._id,
                    users: result['activatedUsers'],
                  },
                ],
              });
            }
          }),
        );
      }),
    );
  }

  /**
   * Get spaces action handler
   * @param  {patchState}: StateContext<SpacesStateModel>
   * @param action
   */
  @Action(SpacesGet)
  get_spaces({ patchState }: StateContext<SpacesStateModel>, action: SpacesGet) {
    patchState({ spacesLoaded: false, spaces: [] });

    return this.spacesService.spacesGetList(action.payload).pipe(
      tap(
        (res) => {
          const allSpaces = res.results.map((item) => ({
            slug: slugify(item.spaceName),
            ...item,
          }));

          patchState({ spacesLoaded: true, spaces: allSpaces });
          this.localStorage.set('spaces', allSpaces);
        },
        (err) => {
          throw err.error;
        },
      ),
    );
  }

  /**
   * Update space action handler
   * @param  {getState, patchState}: StateContext<SpacesStateModel>
   * @param  {SpaceUpdate} action
   */
  @Action(SpaceUpdate)
  update_space({ getState, patchState }: StateContext<SpacesStateModel>, { payload }: SpaceUpdate) {
    const space = payload.space;
    const currentSpace = payload.currentSpace;

    return (
      currentSpace.isPersonal
        ? this.spacesService.spacesControllerPersonalSpacesUpdate({
            id: payload.id,
            body: { prefix: currentSpace.prefix, ...space },
          })
        : this.spacesService.spacesUpdate({ id: payload.id, body: space })
    ).pipe(
      tap(
        () => {
          const prevState = getState();
          const spaces = prevState.spaces.map((item) =>
            item._id === payload.id
              ? {
                  ...item,
                  ...space,
                  slug: slugify(space.spaceName),
                  avatarUrl: payload.avatarUrl,
                }
              : item,
          );

          patchState({ spacesLoaded: true, spaces });
          this.localStorage.set('spaces', spaces);
        },
        (err) => {
          throw err.error;
        },
      ),
    );
  }

  /**
   * Reset space numberOfUnreadActivityLogs
   * @param  {getState, patchState}: StateContext<SpacesStateModel>
   * @param  {SpacesResetNumberOfActivities} action
   */
  @Action(SpacesResetNumberOfActivities)
  resetNumberOfActivities(
    { getState, patchState }: StateContext<SpacesStateModel>,
    action: SpacesResetNumberOfActivities,
  ) {
    const { spaces } = getState();
    const allSpaces = spaces.map((item) =>
      item._id === action.payload ? { ...item, numberOfUnreadActivityLogs: 0 } : item,
    );

    patchState({ spacesLoaded: true, spaces: allSpaces });
    this.localStorage.set('spaces', allSpaces);
  }

  /**
   * Update spaces after change space action handler
   * @param  {getState, patchState}: StateContext<SpacesStateModel>
   * @param  {SpacesUpdateAfterChange} action
   */
  @Action(SpacesUpdateAfterChange)
  update_after_change_space(
    { getState, patchState }: StateContext<SpacesStateModel>,
    action: SpacesUpdateAfterChange,
  ) {
    const space = action.payload;
    const { spaces } = getState();
    const allSpaces = spaces.map((item) =>
      item._id === space._id
        ? {
            ...item,
            ...space,
            order: item.order,
            slug: space.spaceName ? slugify(space.spaceName) : item.slug,
          }
        : item,
    );

    patchState({ spaces: allSpaces });
    this.localStorage.set('spaces', allSpaces);
  }

  /**
   * Update space action handler
   * @param  {getState, patchState}: StateContext<SpacesStateModel>
   * @param  {SpaceOrderUpdate} action
   */
  @Action(SpaceOrderUpdate)
  update_space_order(
    { getState, patchState }: StateContext<SpacesStateModel>,
    action: SpaceOrderUpdate,
  ) {
    const space = action.payload.space;
    return this.spacesService.spacesMembersUpdateOrder({ id: action.payload.id, body: space }).pipe(
      tap(
        (result) => {
          const prevState = getState();
          const allSpaces = prevState.spaces.map((item) => {
            if ('order' in space && 'oldOrder' in space) {
              if (item._id === result._id) {
                result = { ...item, ...result };
              } else {
                if (
                  space.order < space.oldOrder &&
                  (space.order === item.order ||
                    (item.order < prevState.spaces.length - 1 &&
                      space.order < item.order &&
                      space.oldOrder > item.order))
                ) {
                  item = { ...item, order: item.order + 1 };
                } else if (
                  space.order > space.oldOrder &&
                  (space.order === item.order ||
                    (item.order > 0 && space.order > item.order && space.oldOrder < item.order))
                ) {
                  item = { ...item, order: item.order - 1 };
                }
              }
            }
            return item._id === result._id ? result : item;
          });

          patchState({ spacesLoaded: true, spaces: allSpaces });
          this.localStorage.set('spaces', allSpaces);
        },
        (err) => {
          throw err.error;
        },
      ),
    );
  }

  @Action(SpaceRestore)
  project_restore({ patchState }: StateContext<SpacesStateModel>, action: SpaceRestore) {
    return this.spacesService.spacesRestore({ id: action.payload }).pipe(
      tap((res) => {
        patchState({ restoreSpace: res });
      }),
    );
  }

  @Action(SpaceOrders)
  update_order_spaces({ getState, setState }: StateContext<SpacesStateModel>, action: SpaceOrders) {
    const { requestUuid, spaces } = getState();
    let uniqueRequestId = '';
    if (requestUuid) {
      uniqueRequestId = requestUuid;
    } else {
      uniqueRequestId = uuidv4();
    }

    const updatedSpaces = action.payload.spaces
      .filter((space) => spaces.some((s) => s._id === space._id))
      .map((space) => {
        return updateItem(
          (s: any) => s._id === space._id,
          patch({
            order: space.order,
          }),
        );
      });

    setState(
      patch({
        spaces: compose(...updatedSpaces),
        requestUuid: uniqueRequestId,
      }),
    );

    // Name spacesService."spacesGetList_1" is generated by api
    // Its api for update orders for spaces
    return this.spacesService.spacesOrderUpdate({
      body: { ...action.payload, requestId: uniqueRequestId },
    });
  }

  /**
   * Delete space action handler
   * @param  {getState, patchState}: StateContext<SpacesStateModel>
   * @param action
   */
  @Action(SpaceDelete)
  delete_space({ getState, patchState }: StateContext<SpacesStateModel>, action: SpaceDelete) {
    return this.spacesService.spacesDelete(action.payload).pipe(
      tap(
        () => {
          const { spaces, chatMenuSpacesVisibility } = getState();
          const spacesVisibility = { ...chatMenuSpacesVisibility };
          const allSpaces = spaces.filter((i) => i._id !== action.payload.id);

          delete spacesVisibility?.[action.payload.id];

          patchState({
            spacesLoaded: true,
            chatMenuSpacesVisibility: spacesVisibility,
            spaces: allSpaces,
          });
          const projects: any = this.localStorage.get('projects');
          this.localStorage.set(
            'projects',
            projects.filter((project) => project?.spaceId !== action.payload.id),
          );
          this.store.dispatch(new ProjectsSet());

          this.localStorage.set('chatMenuSpacesVisibility', spacesVisibility);
          this.localStorage.set('spaces', allSpaces);
        },
        (err) => {
          throw err.error;
        },
      ),
    );
  }

  @Action(SpaceToggleArchiveStatus)
  archive_space(
    { getState, patchState }: StateContext<SpacesStateModel>,
    action: SpaceToggleArchiveStatus,
  ) {
    return this.spacesService.spacesToggleArchiveStatus(action.payload).pipe(
      tap(
        () => {
          // const { spaces } = getState();
          // const space = spaces.find(s => s._id === action.payload.id)
          //
          // patchState({
          //   spaces: [...spaces, {...space, isArchive: !space.isArchive}],
          // });
          //
          // this.localStorage.set('spaces', [...spaces, space]);
        },
        (err) => {
          throw err.error;
        },
      ),
    );
  }

  /**
   * Update spaces after delete space action handler
   * @param  {getState, patchState}: StateContext<SpacesStateModel>
   * @param action
   */
  @Action(SpacesUpdateAfterDelete)
  update_after_delete_space(
    { getState, patchState }: StateContext<SpacesStateModel>,
    action: SpacesUpdateAfterDelete,
  ) {
    const { spaces, chatMenuSpacesVisibility } = getState();
    const spacesVisibility = { ...chatMenuSpacesVisibility };
    const allSpaces = spaces.filter((i) => i._id !== action.payload.spaceId);

    delete spacesVisibility?.[action.payload.spaceId];

    patchState({
      chatMenuSpacesVisibility: spacesVisibility,
      spaces: allSpaces,
    });

    this.store.dispatch([new ProjectsDeleteBySpace(action.payload.spaceId)]);
    this.localStorage.set('chatMenuSpacesVisibility', spacesVisibility);
    this.localStorage.set('spaces', allSpaces);
  }

  /**
   * Get users list action handler
   * @param  {getState, setState}: StateContext<SpacesStateModel>
   * @param action
   */
  @Action(SpaceGetUsersList)
  get_users_list(
    { getState, setState }: StateContext<SpacesStateModel>,
    action: SpaceGetUsersList,
  ) {
    const { isInternalState, ...rest } = action.payload;
    return this.spacesService.spacesGetUserListBySpaceId(rest).pipe(
      tap(
        (result) => {
          if (isInternalState) {
            // set active users into service state without changing in global
            this.ticketService.setTicketUsers(result['activatedUsers']);
          } else {
            const prevState = getState();
            const key = action.payload.exists ? 'users' : 'usersCandidates';
            setState({
              ...prevState,
              [key]: result['activatedUsers'],
              pendingUsers: result['pendingUsers'],
              notActivatedUsers: result['notActivatedUsers'],
            });
          }
        },
        (err) => {
          throw err.error;
        },
      ),
    );
  }

  /**
   * Get users list action handler
   * @param  {getState, setState}: StateContext<SpacesStateModel>
   * @param action
   */
  @Action(SpaceGetUsersListForCreateChatGroup)
  get_users_list_for_create_chat_group(
    { getState, setState }: StateContext<SpacesStateModel>,
    action: SpaceGetUsersListForCreateChatGroup,
  ) {
    return this.spacesService.spacesGetUserListForCreateChatGroup(action.payload).pipe(
      tap(
        (result) => {
          const prevState = getState();

          setState({
            ...prevState,
            usersForGroup: result,
          });
        },
        (err) => {
          throw err.error;
        },
      ),
    );
  }

  /**
   * Invite users action handler
   * @param  {}: StateContext<SpacesStateModel>
   * @param action
   */
  @Action(SpaceInvite)
  invite({ getState, patchState }: StateContext<SpacesStateModel>, action: SpaceInvite) {
    return this.spacesService.spacesInvite({ body: action.payload }).pipe(
      tap(
        (result) => {
          const prevState = getState();
          patchState({
            ...prevState,
            pendingUsers: [
              ...prevState.pendingUsers,
              ...result.existingUsers.map((item) => ({
                ...item,
                status: 'Pending',
              })),
            ],
            notActivatedUsers: [
              ...prevState.notActivatedUsers,
              ...(result.newUsers as any).map((item) => ({
                ...item,
                status: 'Newly invited',
                isActive: false,
              })),
            ],
          });
        },
        (err) => {
          throw err.error;
        },
      ),
    );
  }

  /**
   * Invite token validation action handler
   * @param  {getState, setState}: StateContext<SpacesStateModel>
   * @param action
   */
  @Action(SpaceInviteTokenCheck)
  space_invite_token_check(
    { getState, patchState }: StateContext<SpacesStateModel>,
    action: SpaceInviteTokenCheck,
  ) {
    return this.spacesService.spacesInviteTokenCheck({ token: action.payload.token }).pipe(
      tap(
        (result) => {
          patchState({
            inviteData: result,
          });
        },
        (err) => {
          const platform = this.store.selectSnapshot(AuthState.getPlatform);
          if (platform === 'web' && err.status === 404) {
            // In case when invite already accepted we'll try redirect to dashboard
            window.location.href = `${this.urlHelper.getCorrectOrigin()}dash`;
          } else {
            throw err.error;
          }
        },
      ),
    );
  }

  /**
   * confirm invitation for existing user action handler
   * @param  {getState, setState}: StateContext<SpacesStateModel>
   * @param action
   */
  @Action(SpaceInviteConfirmExist)
  space_invite_confirm_exist(
    { getState, setState }: StateContext<SpacesStateModel>,
    action: SpaceInviteConfirmExist,
  ) {
    return this.spacesService.spacesConfirmExist({ token: action.payload.token }).pipe(
      tap(
        (result: AuthLoginResDto) => {
          this.store.dispatch(new ProceedLogin(result));
        },
        (err) => {
          throw err.error;
        },
      ),
    );
  }

  /**
   * Register by invitation link
   * @param {getState, setState}: StateContext<SpacesStateModel>
   * @param  {SpaceInviteConfirmNew} action
   */
  @Action(SpaceInviteConfirmNew)
  space_invite_confirm_new(
    { getState, patchState }: StateContext<SpacesStateModel>,
    action: SpaceInviteConfirmNew,
  ) {
    return this.spacesService.spacesConfirmNew({ body: action.payload }).pipe(
      tap(
        (result: AuthLoginResDto) => {
          this.localStorage.set('x-session-uuid', result.session);
          this.store.dispatch(new ProceedLogin(result));
        },
        (err) => {
          throw err.error;
        },
      ),
    );
  }

  /**
   * Update avatar of a space
   * @param  {patchState}: StateContext<SpaceStateModel>
   * @param action
   */
  @Action(SpaceUpdateAvatar)
  spaceUpdateAvatar(
    { getState, patchState }: StateContext<SpacesStateModel>,
    action: SpaceUpdateAvatar,
  ) {
    const spaces = this.deepClone(getState().spaces);
    const filteredSpace = (item) => item._id === action.payload.spaceId;
    spaces.find(filteredSpace).avatarUrl = action.payload.avatarUrl;
    return patchState({ spaces });
  }

  /**
   * Update avatar image upload loading of a space
   * @param  {patchState}: StateContext<SpaceStateModel>
   * @param action
   */
  @Action(SpaceSetAvatarImageUploadLoading)
  setSpaceAvatarImageUploadLoading(
    { getState, patchState }: StateContext<SpacesStateModel>,
    action: SpaceSetAvatarImageUploadLoading,
  ) {
    const spaces = this.deepClone(getState().spaces);
    const filteredSpace = (item) => item._id === action.payload.id;
    spaces.find(filteredSpace).avatarImageUploadLoading = action.payload.status;
    return patchState({ spaces });
  }

  private deepClone(object) {
    return JSON.parse(JSON.stringify(object));
  }

  /**
   * unassign spaces members action handler
   * @param  {getState, setState}: StateContext<SpacesStateModel>
   * @param action
   */
  @Action(SpaceUnassignMember)
  unassign_spaces_members(
    { patchState, getState }: StateContext<SpacesStateModel>,
    action: SpaceUnassignMember,
  ) {
    return this.spacesService.spacesUnassignUser(action.payload).pipe(
      tap(
        () => {
          const prevState = getState();
          patchState({
            ...prevState,
            users: prevState.users.filter((item) => item['_id'] !== action.payload.userId),
          });
        },
        (err) => {
          throw err.error;
        },
      ),
    );
  }

  /**
   * spaces revoke invite action handler
   * @param  {getState, setState}: StateContext<SpacesStateModel>
   * @param action
   */
  @Action(SpaceRevokeInvite)
  spaces_revoke_invite(
    { patchState, getState }: StateContext<SpacesStateModel>,
    action: SpaceRevokeInvite,
  ) {
    return this.spacesService.spacesRevokeUserInvite(action.payload).pipe(
      tap(
        () => {
          const prevState = getState();
          switch (action.payload.status) {
            case 'Pending':
              patchState({
                ...prevState,
                pendingUsers: prevState.pendingUsers.filter(
                  (item) => item['_id'] !== action.payload.userId,
                ),
              });

              break;
            case 'Newly invited':
              patchState({
                ...prevState,
                notActivatedUsers: prevState.notActivatedUsers.filter(
                  (item) => item['email'] !== action.payload.email,
                ),
              });

              break;
            default:
              return;
          }
        },
        (err) => {
          throw err.error;
        },
      ),
    );
  }

  /**
   * set chat menu spaces visibility
   * @param  {patchState}: StateContext<SpaceStateModel>
   */
  @Action(SpaceSetChatMenuVisibility)
  setChatMenuSpacesVisibility({ patchState }: StateContext<SpacesStateModel>) {
    const chatMenuSpacesVisibility: Record<string, boolean> =
      this.localStorage.get('chatMenuSpacesVisibility') || {};

    return patchState({ chatMenuSpacesVisibility });
  }

  /**
   * Toggle chat menu space visibility
   * @param  {patchState}: StateContext<SpaceStateModel>
   * @param action
   */
  @Action(SpaceToggleChatMenuVisibility)
  toggleChatMenuSpaceVisibility(
    { getState, patchState }: StateContext<SpacesStateModel>,
    action: SpaceToggleChatMenuVisibility,
  ) {
    const chatMenuSpacesVisibility = this.deepClone(getState().chatMenuSpacesVisibility);
    chatMenuSpacesVisibility[action.payload._id] =
      chatMenuSpacesVisibility?.[action.payload._id] === undefined
        ? false
        : !chatMenuSpacesVisibility?.[action.payload._id];
    this.localStorage.set('chatMenuSpacesVisibility', chatMenuSpacesVisibility);

    return patchState({ chatMenuSpacesVisibility });
  }

  /**
   * Update project action handler
   * @param  {getState, patchState}: StateContext<ProjectsStateModel>
   * @param  {SpaceLeave} action
   */
  @Action(SpaceLeave)
  space_leave({ getState, patchState }: StateContext<SpacesStateModel>, action: SpaceLeave) {
    const { id } = action.payload;
    return this.spacesService.spacesLeave({ id }).pipe(
      tap(() => {
        const prevState = getState();
        const currentsSpaces = prevState.spaces.filter((space) => space._id !== action.payload.id);
        localStorage.setItem('spaces', JSON.stringify(currentsSpaces));
        patchState({
          ...prevState,
          spaces: currentsSpaces,
        });
      }),
    );
  }

  /**
   * Space member delete action handler
   * @param  {getState, patchState}: StateContext<SpacesStateModel>
   * @param  {SpaceMemberDelete} action
   */
  @Action(SpaceMemberDelete)
  space_member_delete(
    { getState, patchState, dispatch }: StateContext<SpacesStateModel>,
    action: SpaceMemberDelete,
  ) {
    const { spaceId, userId, isCurrentUserDeleted } = action.payload;

    const prevState = getState();

    if (isCurrentUserDeleted) {
      const currentSpaces = prevState.spaces.filter((space) => space._id !== spaceId);
      localStorage.setItem('spaces', JSON.stringify(currentSpaces));
      patchState({
        ...prevState,
        spaces: currentSpaces,
      });
    } else {
      patchState({
        ...prevState,
        users: prevState.users.filter((user) => user._id !== userId),
      });
    }

    return dispatch(new DeleteChat({ objectId: spaceId }));
  }
}
