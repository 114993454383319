import { Injectable } from '@angular/core';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { RequestBuilder } from '../request-builder';
import { filter, map } from 'rxjs';
import { StrictHttpResponse } from '../strict-http-response';
import { JwtInvitation } from '../../shared/store/models/JwtStateModel';

@Injectable()
export class JwtInviteService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  getInvitations(payload?: { search?: string }) {
    const rb = new RequestBuilder(this.rootUrl, '/jwt/invitations', 'get');

    if (payload?.search) {
      rb.query('search', payload.search);
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<JwtInvitation[]>;
        }),
      );
  }

  resendInvitation(invitationId: string) {
    const rb = new RequestBuilder(this.rootUrl, `/jwt/invitations/${invitationId}/resend`, 'post');

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<void>;
        }),
      );
  }
  revokeInvitation(invitationId: string) {
    const rb = new RequestBuilder(this.rootUrl, `/jwt/invitations/${invitationId}/revoke`, 'post');

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<void>;
        }),
      );
  }
}
