import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ConfigService, ITemplateConfig } from '../../../services/config.service';
import { SvgIconComponent } from 'angular-svg-icon';
import { NgClass, NgIf } from '@angular/common';

export type TButton = 'button' | 'submit';
export type TVariant = 'secondary' | 'primary' | 'outline' | 'danger' | 'none';

export enum VariantEnum {
  Secondary = 'secondary',
  Primary = 'primary',
  Outline = 'outline',
  Danger = 'danger',
  None = 'none',
}

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  standalone: true,
  imports: [NgClass, NgIf, SvgIconComponent],
})
export class ButtonComponent {
  @Input() typeButton: TButton = 'button';
  @Input() variantButton: TVariant = 'primary';
  @Input() label: string;
  @Input() disabled: boolean = false;
  @Input() customClasses: string = '';
  @Input() widthFull = false;
  @Input() isLoading = false;

  @Input() svgPath: string;
  @Input() svgClass: string;

  @Output() handleClick: EventEmitter<void> = new EventEmitter<void>();

  private config: ITemplateConfig;

  constructor(private readonly configService: ConfigService) {
    this.config = this.configService.templateConf;
  }

  public get isDark(): boolean {
    return this.config?.layout.variant === 'Dark';
  }

  public onClick(): void {
    this.handleClick.emit();
  }
}
