/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CommonSuccessResDto } from '../models/common-success-res-dto';
import { EmojisCreateReqDto } from '../models/emojis-create-req-dto';
import { EmojisDto } from '../models/emojis-dto';

@Injectable({
  providedIn: 'root',
})
export class EmojisService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation emojisCreate
   */
  static readonly EmojisCreatePath = '/emojis';

  /**
   * creates an emoji
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `emojisCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  emojisCreate$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    body: EmojisCreateReqDto;
  }): Observable<StrictHttpResponse<EmojisDto>> {
    const rb = new RequestBuilder(this.rootUrl, EmojisService.EmojisCreatePath, 'post');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<EmojisDto>;
        }),
      );
  }

  /**
   * creates an emoji
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `emojisCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  emojisCreate(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    body: EmojisCreateReqDto;
  }): Observable<EmojisDto> {
    return this.emojisCreate$Response(params).pipe(map((r: StrictHttpResponse<EmojisDto>) => r.body as EmojisDto));
  }

  /**
   * Path part for operation emojisDelete
   */
  static readonly EmojisDeletePath = '/emojis/{id}';

  /**
   * deletes an emoji
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `emojisDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  emojisDelete$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    id: string;
  }): Observable<StrictHttpResponse<CommonSuccessResDto>> {
    const rb = new RequestBuilder(this.rootUrl, EmojisService.EmojisDeletePath, 'delete');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.path('id', params.id, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<CommonSuccessResDto>;
        }),
      );
  }

  /**
   * deletes an emoji
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `emojisDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  emojisDelete(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    id: string;
  }): Observable<CommonSuccessResDto> {
    return this.emojisDelete$Response(params).pipe(
      map((r: StrictHttpResponse<CommonSuccessResDto>) => r.body as CommonSuccessResDto),
    );
  }
}
