/** Get user roles event **/
export class RolesGet {
  static readonly type = '[Roles] Get';
  constructor() {}
}

/** Update role members **/
export class RoleMembersUpdate {
  static readonly type = '[Roles] MembersUpdate';
  constructor(public payload) {}
}
